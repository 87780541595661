import dayjs from "dayjs"
import { isProxy, toRaw } from "vue"

import { db, IChild, IGrowthAssessment } from "@/db"
import { Child } from "@/models/Child"
import { GrowthAssessment } from "@/models/GrowthAssessment"
import * as api from "@/services/Api"
import { getChildById } from "@/services/Child"
import { handlePendingUploads, queuePendingUpload } from "@/services/Upload"
import { getAccountInfo } from "@/utils/GlobalState"

/**
 * Return the growth assessment based on internal assessment id, or null.
 * Wrapped if child is supplied.
 * If no id is provided, then child must be supplied; an incomplete assessment is assumed.
 */
export async function getGrowthAssessmentForChild(id?: number, child?: Child) {
  let obj: IGrowthAssessment
  if (id) {
    obj = await db.growthAssessments.get(id)
  }
  else {
    obj = await db.growthAssessments
      .where({ childId: child.id })
      .and(item => !item.isComplete)
      .last()
  }
  if (!obj) return
  return child ? new GrowthAssessment(child, obj) : obj
}

// Return *wrapped* instance of GrowthAssessment. Expects *wrapped* instance
// of child as arg.
export async function getLastSavedGrowthAssessmentForChild(child: Child) {
  const ga = await db.growthAssessments
    .where({ childId: child.id })
    .filter(a => a.isComplete)
    .sortBy("dateOfAssessment")
  if (ga.length) {
    return new GrowthAssessment(child, ga[ga.length - 1])
  }
}

/**
 * Return an array of the growth assessments for this child (based on their internal id).
 * Assessments are sorted in chrono order.
 * @args {Number}   childId – this is the internal IndexedDB id, not from server
 * @return [Object]
 */
export async function getAllSavedGrowthAssessmentsForChild(childId: number) {
  return await db.growthAssessments.where({ childId }).sortBy("dateOfAssessment")
}

export async function getMostRecentGrowthAssessmentForEachChild(siteId: number) {
  const allAssessments = await db.growthAssessments
    .where({ siteId })
    .reverse()
    .sortBy("dateOfAssessment")
  const childrenAlreadySeen = new Set()
  const results = []
  // Only extract the first assessment we find for each child.
  allAssessments.forEach((a: IGrowthAssessment) => {
    if (!childrenAlreadySeen.has(a.childId)) {
      results.push(a)
      childrenAlreadySeen.add(a.childId)
    }
  })
  return results
}

/**
 * - Create or replace an instance of a growthAssessment in IndexedDB and return its id.
 */
export async function createOrReplaceGrowthAssessment(child: IChild, growthAssessment: IGrowthAssessment) {
  // Prep object for persistence
  if (isProxy(growthAssessment)) {
    growthAssessment = toRaw(growthAssessment)
  }
  const saveableGrowthAssessment = {} as IGrowthAssessment
  // Ensure we're only persisting legit fields (others get larded in somehow)
  const fields = [
    "id",
    "cmiId",
    "isComplete",
    "childId",
    "siteId",
    "dueDate",
    "dateOfAssessment",
    "dateCreated",
    "canChildStand",
    "canStraightenLegs",
    "typeOfLength",
    "lengthInCm",
    "weightInKilograms",
    "muacInCm",
    "headCircumferenceInCm",
    "hasReferrals",
    "notes",
  ]
  fields.forEach((fieldName) => {
    if (fieldName in growthAssessment) {
      saveableGrowthAssessment[fieldName] = growthAssessment[fieldName]
    }
  })

  saveableGrowthAssessment.siteId = child.siteId

  // Save locally
  const growthAssessmentId = await db.growthAssessments.put(saveableGrowthAssessment)
  return growthAssessmentId
}

export async function finalizeGrowthAssessment(child: IChild, assessment: IGrowthAssessment) {
  if (isProxy(child)) {
    child = toRaw(child)
  }
  const childAsModel = new Child(child)
  const growthAsModel = new GrowthAssessment(childAsModel, assessment)
  await growthAsModel.processAssessment(true) // Generate follow up interval
  const { name } = getAccountInfo()
  assessment.hasReferrals = Boolean(growthAsModel.referrals?.length)
  assessment.creatorName = name

  // Update the previous assessment bc of dirty data?
  let prevNeedsUpdate = false
  const prev = await growthAsModel.getPreviousAssessment()
  if (prev) {
    if (prev.lengthInCm > growthAsModel.lengthInCm) {
      prev.lengthIsDirty = true
      prevNeedsUpdate = true
    }
    if (prev.headCircumferenceInCm > growthAsModel.headCircumferenceInCm) {
      prev.headSizeIsDirty = true
      prevNeedsUpdate = true
    }
  }

  // Update child's next due date?
  const newDueDate = childAsModel.getNextGrowthAssessmentDate(growthAsModel.dateOfAssessment, growthAsModel.followupAssessmentInterval)

  await db.transaction("rw", db.children, db.growthAssessments, db.pendingUploads, async () => {
    const promises = [
      db.growthAssessments.update(assessment.id, { hasReferrals: assessment.hasReferrals, creatorName: assessment.creatorName }),
      queuePendingUpload({ type: "growthAssessment", localItemId: assessment.id })
    ]
    if (prevNeedsUpdate) {
      promises.push(
        db.growthAssessments.put(prev),
        queuePendingUpload({ type: "growthAssessment", localItemId: prev.id })
      )
    }
    if (!assessment.dueDate) {
      assessment.dueDate = child.nextGrowthAssessmentDate || new Date()
      promises.push(
        db.growthAssessments.update(assessment.id, { dueDate: assessment.dueDate })
      )
    }

    if (child.nextGrowthAssessmentDate !== newDueDate) {
      child.nextGrowthAssessmentDate = newDueDate
      promises.push(
        db.children.update(child.id, { nextGrowthAssessmentDate: newDueDate }),
        queuePendingUpload({ type: "child", localItemId: child.id })
      )
    }
    return Promise.all(promises)
  })
}

/*
 * Given a growthAssessment id, look up the object in the pendingUploads queue and attempt
 * the upload. If successful, remove the entry from the queue and update
 * cached object's CMI id. If successful, return API's version of the object.
 * Allow all exceptions to trickle up.
 * @args {Number} growthAssessmentId - internal id for pulling from queue
 * @return {Object} growthAssessment object from API
 */
export async function uploadGrowthAssessment(growthAssessmentId: number) {
  const growthAssessment = await getGrowthAssessmentForChild(growthAssessmentId)
  // Look up child's CMI id and swap it in (replacing internal id) just for the upload.
  const child = await getChildById(growthAssessment.childId)
  if (!child.cmiId) {
    throw new Error("Can't upload a growth assessment for a child lacking a CMI id.")
  }
  growthAssessment.childId = child.cmiId
  delete growthAssessment.isComplete
  return api.uploadGrowthAssessment(growthAssessment).then(async (data: IGrowthAssessment) => {
    // If a successful POST, update the internal CMI id
    if (!growthAssessment.cmiId) {
      await db.growthAssessments.update(growthAssessment.id, { cmiId: data.id, creatorName: data.creatorName })
    }
    return data
  })
}

/**
 * Replace all growth assessments for a child with what's on the server. After handling
 * pending updates, of course! Doesn't return anything.
 * @args [Number] childId - this is the *internal* id!
 */
export async function updateGrowthAssessmentsFromServer(childId: number) {
  try {
    handlePendingUploads()
  } catch (error) {
    console.log(error)
    return
  }
  // Determine child's CMI id
  const child = await getChildById(childId)
  if (!child.cmiId) {
    throw new Error("Can't poll for a child lacking cmi id!")
  }
  // Fetch from server
  const growthAssessments = await api.getGrowthAssessmentsForChild(child.cmiId)

  // Remap the child ids to the internal one.
  growthAssessments.forEach((ga) => {
    ga.childId = childId
    ga.isComplete = true
    ga.siteId = child.siteId
  })

  // Purge previous records
  await db.growthAssessments.where({ childId }).delete()

  // Update with the new
  await db.growthAssessments.bulkAdd(growthAssessments)
}

/**
 * Was child older than 2 at date of assessment?
 * @args [dob]               Date; child's date of birth
 * @args [dateOfAssessment     Date; date assessment took place
 * @return [boolean]
 */
export function isChildOlderThan2(dob: Date, dateOfAssessment: Date) {
  return dayjs(dob).isBefore(dayjs(dateOfAssessment).subtract(2, "years"))
}

/**
 * Return an array of strings representing the types of measurements appropriate
   for this assessment based on child's age and abilities. Options include:
   length, height, weight, muac, head
 * @args {child}                Object
 * @args {growthAssessment}     Object
 * @return {array}              Array (of strings)
 */
export function getRelevantGrowthMeasurements(child: IChild, growthAssessment: IGrowthAssessment) {
  const dob = dayjs(child.dob)
  const dateOfAssessment = dayjs(growthAssessment.dateOfAssessment)
  const results = ["weight"]

  if (isChildOlderThan2(child.dob, growthAssessment.dateOfAssessment) && growthAssessment.canChildStand) {
    results.push("height")
  }
  else if (growthAssessment.canStraightenLegs) {
    results.push("length")
  }

  // Head circumference up to 5 years.
  if (dateOfAssessment.isBefore(dob.add(5, "years"))) {
    results.push("head")
  }
  // MUAC: in btw 91 days and 19 years
  if (
    dateOfAssessment.isAfter(dob.add(91, "days")) &&
    dateOfAssessment.isBefore(dob.add(19, "years"))
  ) {
    results.push("muac")
  }

  return results
}

export function getFoldInSiteGrowthAssessmentsFromServer(siteId: number) {
  async function foldInSiteGrowthAssessmentsFromServer(assessments: Array<object>) {
    // Create a mapping of CMI ids to internal ids for both
    // - all currently cached children for this site
    // - all currently cached assessments for this site
    const childInternalIds = []
    const childCmiIdToInternalIds = {}
    const growthCmiIdToInternalIds = {}
    await db.children
      .where({ siteId })
      .toArray()
      .then((results: Array<object>) => {
        results.forEach((child: IChild) => {
          childCmiIdToInternalIds[child.cmiId] = child.id
          childInternalIds.push(child.id)
        })
      })

    await db.growthAssessments
      .where("childId")
      .anyOf(childInternalIds)
      .toArray()
      .then((results: Array<object>) => results.forEach((a: { cmiId: number, id: number }) => (growthCmiIdToInternalIds[a.cmiId] = a.id)))

    // Handle update/insert of the API results
    // Letting all the async calls run in parallel. Should happen fast enough that
    // it just works.
    assessments.forEach((assessment: IGrowthAssessment) => {
      // Swap backend child ids for internal ones, for consistency
      assessment["childId"] = childCmiIdToInternalIds[assessment["childId"]]
      assessment.isComplete = true
      assessment.siteId = siteId
      if (assessment.cmiId in growthCmiIdToInternalIds) {
        db.growthAssessments.update(growthCmiIdToInternalIds[assessment.cmiId], assessment)
      }
      else {
        db.growthAssessments.add(assessment)
      }
    })
  }
  return foldInSiteGrowthAssessmentsFromServer
}


/*
 * Given a local growthAssessment id, look up the object in the pendingUploads queue
 * and retrieve the growthAssessment from the database. If the assessment lacks a
 * cmi ID, abort the operation–the note will get uploaded as part of the rest of the
 * assessment. Otherwise, upload the entire notes object from the assessment.
 * If successful, remove the entry from the queue and return API's version of the object.
 * Allow all exceptions to trickle up.
 * @args {Number} growthAssessmentId - internal id for pulling from queue
 * @return {Object} growthAssessment object from API
 */
export async function uploadGrowthAssessmentNote(growthAssessmentId: number) {
  const growthAssessment = await getGrowthAssessmentForChild(growthAssessmentId)
  if (growthAssessment.cmiId) {
    // Look up child's CMI id and swap it in (replacing internal id) just for the upload.
    const child = await getChildById(growthAssessment.childId)
    if (!child.cmiId) {
      throw new Error("Can't upload a growth assessment for a child lacking a CMI id.")
    }
    growthAssessment.childId = child.cmiId
    return await api.uploadGrowthAssessment(growthAssessment, true)
  }
}
