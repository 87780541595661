<script lang="ts" setup>
  import { ref } from "vue"
  import { useRoute } from "vue-router"

  import { finishPasswordReset } from "@/services/Api"
  import { getResetPasswordSchema, togglePasswordVisibility } from "@/schemas/Account"

  const route = useRoute()
  const token = route.params.token

  const contentToShow = ref("form")

  function submitForm(data: { token: string, password: string }) {
    finishPasswordReset(data)
      .then(() => contentToShow.value = "success")
      .catch((error) => {
        if (error.name === "CONNECTIVITY_REQUIRED" || error.name === "SERVER_DOWN") {
          contentToShow.value = "failure-connectivity"
        }
        else {
          contentToShow.value = "failure-other"
        }
      })
  }
</script>
<template>
  <article>
    <h1 class="title">
      {{ $gettext("Reset Password") }}
    </h1>
    <template v-if="contentToShow =='form'">
      <p>
        {{ $gettext("You are changing the new password for your account. Please enter a new password below.") }}
      </p>
      <FormKit
        type="form"
        :submit-label="$gettext('Set password')"
        :submit-attrs="{ inputClass: 'button is-primary' }"
        @submit="submitForm"
      >
        <FormKitSchema
          :schema="getResetPasswordSchema()"
          :data="{ token, togglePasswordVisibility }"
        />
      </FormKit>
    </template>
    <transition name="fade">
      <div v-if="contentToShow == 'success'">
        <p>
          {{ $gettext("Your password has been changed! Please log in now using your email address and your new password.") }}
        </p>
        <router-link
          :to="{ name: 'Login' }"
          class="button is-primary"
        >
          {{ $gettext("Log in") }}
        </router-link>
      </div>
    </transition>
    <transition name="fade">
      <p v-if="contentToShow == 'failure-connectivity'">
        {{ $gettext("You do not seem to be online, or there were problems connecting to the server. Please try again later.") }}
      </p>
    </transition>
    <transition name="fade">
      <div v-if="contentToShow == 'failure-other'">
        <p>
          {{ $gettext("The link you used might have expired or is not valid. Please start the process over again.") }}
        </p>
        <router-link
          :to="{ name: 'ForgotPasswordForm' }"
          class="button is-primary"
        >
          {{ $gettext("Try again") }}
        </router-link>
      </div>
    </transition>
  </article>
</template>
