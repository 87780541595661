<script lang="ts" setup>
  import { ref } from "vue"

  import { sendResetPasswordRequest } from "@/services/Api"
  const contentToShow = ref("form")

  function submitForm({ email }: { email: string}) {
    sendResetPasswordRequest(email)
      .then(() => contentToShow.value = "success")
      .catch((error) => {
        if (error.name === "CONNECTIVITY_REQUIRED" || error.name === "SERVER_DOWN") {
          contentToShow.value = "failure-connectivity"
        }
        else {
          contentToShow.value = "failure-other"
        }
      })
  }
</script>
<template>
  <article>
    <h1 class="title">
      {{ $gettext("Reset Password") }}
    </h1>
    <template v-if="contentToShow =='form'">
      <p>
        {{ $gettext("If you have forgotten your password, enter your email address below. You will receive an email with instructions for setting a new one.") }}
      </p>
      <FormKit
        type="form"
        :submit-label="$gettext('Reset password')"
        :submit-attrs="{ inputClass: 'button is-primary' }"
        @submit="submitForm"
      >
        <FormKit
          id="email"
          type="email"
          label="Email address"
          name="email"
          validation="required|email"
        />
      </FormKit>
    </template>
    <transition name="fade">
      <p v-if="contentToShow == 'success'">
        {{ $gettext("Please check your email. If an account exists for the email address you just submitted, you will receive a message shortly with instructions on how to reset your password.") }}
      </p>
    </transition>
    <transition name="fade">
      <p v-if="contentToShow == 'failure-connectivity'">
        {{ $gettext("You do not seem to be online, or there were problems connecting to the server. Please try again later.") }}
      </p>
    </transition>
    <transition name="fade">
      <p v-if="contentToShow == 'failure-other'">
        {{ $gettext("Something went wrong unexpectedly when trying to reset your password. Please try again later.") }}
      </p>
    </transition>
  </article>
</template>
