import { gettext } from "@/utils/Translation.js"

const { $gettext } = gettext

// =================================================
// = Developmental Milestones (for all age groups) =
// =================================================
// DM_PREAMBLE0: $gettext("Most children at this age will be able to do some (but not all) of the developmental tasks listed below."),
// DM_PREAMBLE1: $gettext("Please tell us how much your child is doing each of these things."),
export function getDevelopmentalMilestonesQuestions() {
  return {
    Swyc2Months: {
      dmQ1: $gettext("Makes sounds that let you know he or she is happy or upset"),
      dmQ2: $gettext("Seems happy to see you"),
      dmQ3: $gettext("Follows a moving toy with his or her eyes"),
      dmQ4: $gettext("Turns head to find the person who is talking"),
      dmQ5: $gettext("Holds head steady when being pulled up to a sitting position"),
      dmQ6: $gettext("Brings hands together"),
      dmQ7: $gettext("Laughs"),
      dmQ8: $gettext("Keeps head steady when held in a sitting position"),
      dmQ9: $gettext('Makes sounds like "ga," "ma," or "ba"'),
      dmQ10: $gettext("Looks when you call his or her name"),
    },
    Swyc4Months: {
      dmQ1: $gettext("Holds head steady when being pulled up to a sitting position"),
      dmQ2: $gettext("Brings hands together"),
      dmQ3: $gettext("Laughs"),
      dmQ4: $gettext("Keeps head steady when held in a sitting position"),
      dmQ5: $gettext('Makes sounds like "ga," "ma," or "ba"'),
      dmQ6: $gettext("Looks when you call his or her name"),
      dmQ7: $gettext("Rolls over"),
      dmQ8: $gettext("Passes a toy from one hand to the other"),
      dmQ9: $gettext("Looks for you or another caregiver when upset"),
      dmQ10: $gettext("Holds two objects and bangs them together"),
    },
    Swyc6Months: {
      dmQ1: $gettext('Makes sounds like "ga," "ma," or "ba"'),
      dmQ2: $gettext("Looks when you call his or her name"),
      dmQ3: $gettext("Rolls over"),
      dmQ4: $gettext("Passes a toy from one hand to the other"),
      dmQ5: $gettext("Looks for you or another caregiver when upset"),
      dmQ6: $gettext("Holds two objects and bangs them together"),
      dmQ7: $gettext("Holds up arms to be picked up"),
      dmQ8: $gettext("Gets into a sitting position by him or herself"),
      dmQ9: $gettext("Picks up food and eats it"),
      dmQ10: $gettext("Pulls up to standing"),
    },
    Swyc9Months: {
      dmQ1: $gettext("Holds up arms to be picked up"),
      dmQ2: $gettext("Gets into a sitting position by him or herself"),
      dmQ3: $gettext("Picks up food and eats it"),
      dmQ4: $gettext("Pulls up to standing"),
      dmQ5: $gettext('Plays games like "peek a boo" or "pat a cake"'),
      dmQ6: $gettext('Calls you "mama" or "dada" or similar name'),
      dmQ7: $gettext('Looks around when you say things like "Where\'s your bottle?" or "Where\'s your blanket?"'),
      dmQ8: $gettext("Copies sounds that you make"),
      dmQ9: $gettext("Walks across a room without help"),
      dmQ10: $gettext('Follows directions, like "Come here" or "Give me the ball"'),
    },
    Swyc12Months: {
      dmQ1: $gettext("Picks up food and eats it"),
      dmQ2: $gettext("Pulls up to standing"),
      dmQ3: $gettext('Plays games like "peek a boo" or "pat a cake"'),
      dmQ4: $gettext('Calls you "mama" or "dada" or similar name'),
      dmQ5: $gettext('Looks around when you say things like "Where\'s your bottle?" or "Where\'s your blanket?"'),
      dmQ6: $gettext("Copies sounds that you make"),
      dmQ7: $gettext("Walks across a room without help"),
      dmQ8: $gettext('Follows directions, like "Come here" or "Give me the ball"'),
      dmQ9: $gettext("Runs"),
      dmQ10: $gettext("Walks up stairs with help"),
    },
    Swyc15Months: {
      dmQ1: $gettext('Calls you "mama" or "dada" or similar name'),
      dmQ2: $gettext('Looks around when you say things like "Where\'s your bottle?" or "Where\'s your blanket?"'),
      dmQ3: $gettext('Copies sounds that you make'),
      dmQ4: $gettext('Walks across a room without help'),
      dmQ5: $gettext('Follows directions, like "Come here" or "Give me the ball"'),
      dmQ6: $gettext('Runs'),
      dmQ7: $gettext('Walks up stairs with help'),
      dmQ8: $gettext('Kicks a ball'),
      dmQ9: $gettext('Names at least 5 familiar objects, like "ball" or "milk"'),
      dmQ10: $gettext('Names at least 5 body parts, like nose, hand, or tummy'),
    },
    Swyc18Months: {
      dmQ1: $gettext("Runs"),
      dmQ2: $gettext("Walks up stairs with help"),
      dmQ3: $gettext("Kicks a ball"),
      dmQ4: $gettext('Names at least 5 familiar objects, like "ball" or "milk"'),
      dmQ5: $gettext("Names at least 5 body parts, like nose, hand, or tummy"),
      dmQ6: $gettext("Climbs up a ladder at a playground"),
      dmQ7: $gettext('Uses words like "me" or "mine"'),
      dmQ8: $gettext("Jumps off the ground with two feet"),
      dmQ9: $gettext('Puts 2 or more words together, like "more water" or "go outside"'),
      dmQ10: $gettext("Uses words to ask for help"),
    },
    Swyc24Months: {
      dmQ1: $gettext("Names at least 5 body parts, like nose, hand, or tummy"),
      dmQ2: $gettext("Climbs up a ladder at a playground"),
      dmQ3: $gettext('Uses words like "me" or "mine"'),
      dmQ4: $gettext("Jumps off the ground with two feet"),
      dmQ5: $gettext('Puts 2 or more words together, like "more water" or "go outside"'),
      dmQ6: $gettext("Uses words to ask for help"),
      dmQ7: $gettext("Names at least one color"),
      dmQ8: $gettext('Tries to get you to watch by saying "Look at me"'),
      dmQ9: $gettext("Says his or her first name when asked"),
      dmQ10: $gettext("Draws lines"),
    },
  }
}

// ====================================
// = Baby Pediatric Symptom Checklist =
// ====================================
// PEDIATRIC_SYMPTOM_CHECKLIST_PREAMBLE0: $gettext("These questions are about your child's behavior."),
// PEDIATRIC_SYMPTOM_CHECKLIST_PREAMBLE1: $gettext("Think about what you would expect of other children the same age, and tell us how much each statement applies to your child."),

export function getSwycOptions() {
  return {
    behavior: {
      0: $gettext("Not at all"),
      1: $gettext("Somewhat"),
      2: $gettext("Very Much"),
    },
    developmental: {
      0: $gettext("Not Yet"),
      1: $gettext("Somewhat"),
      2: $gettext("Very Much"),
    },
    // Family questions
    // yes/no for fqQ1-4
    yesNo: {
      1: $gettext("Yes"),
      0: $gettext("No"),
    },
    familyQ5: {
      1: $gettext("Never true"),
      2: $gettext("Sometimes true"),
      3: $gettext("Often true"),
    },
    // fqQ6 & fqQ7
    familyDepression: {
      0: $gettext("Not at all"),
      1: $gettext("Several days"),
      2: $gettext("More than half the days"),
      3: $gettext("Nearly every day"),
    },
    familyQ8: {
      1: $gettext("No tension"),
      2: $gettext("Some tension"),
      3: $gettext("A lot of tension"),
      0: $gettext("Not applicable"),
    },
    familyQ9: {
      1: $gettext("No difficulty"),
      2: $gettext("Some difficulty"),
      3: $gettext("Great difficulty"),
      0: $gettext("Not applicable"),
    },
    familyQ10: { 0: "0", 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7 },
    ecwanbQ1: {
      0: $gettext("As much as I always could"),
      1: $gettext("Not quite so much now"),
      2: $gettext("Definitely not so much now"),
      3: $gettext("Not at all"),
    },
    ecwanbQ2: {
      0: $gettext("As much as I ever did"),
      1: $gettext("Rather less than I used to"),
      2: $gettext("Definitely less than I used to"),
      3: $gettext("Hardly at all"),
    },
    ecwanbQ3: {
      0: $gettext("No, never"),
      1: $gettext("Not very often"),
      2: $gettext("Yes, some of the time"),
      3: $gettext("Yes, most of the time"),
    },
    ecwanbQ4: {
      0: $gettext("No, not at all"),
      1: $gettext("Hardly ever"),
      2: $gettext("Yes, sometimes"),
      3: $gettext("Yes, very often"),
    },
    ecwanbQ5: {
      0: $gettext("No, not at all"),
      1: $gettext("No, not much"),
      2: $gettext("Yes, sometimes"),
      3: $gettext("Yes, quite a lot"),
    },
    ecwanbQ6: {
      0: $gettext("No, I have been coping as well as ever"),
      1: $gettext("No, most of the time I have coped quite well"),
      2: $gettext("Yes, sometimes I haven't been coping as well as usual"),
      3: $gettext("Yes, most of the time I haven't been able to cope at all"),
    },
    ecwanbQ7: {
      0: $gettext("No, not at all"),
      1: $gettext("Not very often"),
      2: $gettext("Yes, sometimes"),
      3: $gettext("Yes, most of the time"),
    },
    ecwanbQ8: {
      0: $gettext("No, not at all"),
      1: $gettext("Not very often"),
      2: $gettext("Yes, quite often"),
      3: $gettext("Yes, most of the time"),
    },
    ecwanbQ9: {
      0: $gettext("No, never"),
      1: $gettext("Only occasionally"),
      2: $gettext("Yes, quite often"),
      3: $gettext("Yes, most of the time"),
    },
    ecwanbQ10: {
      0: $gettext("Never"),
      1: $gettext("Hardly ever"),
      2: $gettext("Sometimes"),
      3: $gettext("Yes, quite often"),
    },
  }
}

// Combination of BPSC and PPSC questions
export function getBehaviorQuestions() {
  return {
    bpscQ1: $gettext("Does your child have a hard time being with new people?"),
    bpscQ2: $gettext("Does your child have a hard time in new places?"),
    bpscQ3: $gettext("Does your child have a hard time with change?"),
    bpscQ4: $gettext("Does your child mind being held by other people?"),
    bpscQ5: $gettext("Does your child cry a lot?"),
    bpscQ6: $gettext("Does your child have a hard time calming down?"),
    bpscQ7: $gettext("Is your child fussy or irritable?"),
    bpscQ8: $gettext("Is it hard to comfort your child?"),
    bpscQ9: $gettext("Is it hard to keep your child on a schedule or routine?"),
    bpscQ10: $gettext("Is it hard to put your child to sleep?"),
    bpscQ11: $gettext("Is it hard to get enough sleep because of your child?"),
    bpscQ12: $gettext("Does your child have trouble staying asleep?"),
    ppscQ1: $gettext("Does your child seem nervous or afraid?"),
    ppscQ2: $gettext("Does your child seem sad or unhappy?"),
    ppscQ3: $gettext("Does your child get upset if things are not done in a certain way?"),
    ppscQ4: $gettext("Does your child have a hard time with change?"),
    ppscQ5: $gettext("Does your child have trouble playing with other children?"),
    ppscQ6: $gettext("Does your child break things on purpose?"),
    ppscQ7: $gettext("Does your child fight with other children?"),
    ppscQ8: $gettext("Does your child have trouble paying attention?"),
    ppscQ9: $gettext("Does your child have a hard time calming down?"),
    ppscQ10: $gettext("Does your child have trouble staying with one activity?"),
    ppscQ11: $gettext("Is your child aggressive?"),
    ppscQ12: $gettext("Is your child fidgety or unable to sit still?"),
    ppscQ13: $gettext("Is your child angry?"),
    ppscQ14: $gettext("Is it hard to take your child out in public?"),
    ppscQ15: $gettext("Is it hard to comfort your child?"),
    ppscQ16: $gettext("Is it hard to know what your child needs?"),
    ppscQ17: $gettext("Is it hard to keep your child on a schedule or routine?"),
    ppscQ18: $gettext("Is it hard to get your child to obey you?"),
  }
}

// ====================
// = Family Questions =
// ====================
// FQ_PREAMBLE: $gettext("Because family members can have a big impact on your child's development, I will now ask you a few questions about your family."),
// FQ_DEPRESSION_PREAMBLE: $gettext("Over the past two weeks, how often have you been bothered by any of the following problems?"),
export function getFamilyQuestions() {
  return {
    // Parent's Concerns
    pcQ1: $gettext("Do you have any concerns about your child's learning or development?"),
    pcQ2: $gettext("Do you have any concerns about your child's behavior?"),
    // FQ_SUBSTANCE_QUESTIONS:
    fqQ1: $gettext("Does anyone who lives with your child smoke tobacco?"),
    fqQ2: $gettext("In the last year, have you ever drunk alcohol or used drugs more than you meant to?"),
    fqQ3: $gettext("Have you felt you wanted or needed to cut down on your drinking or drug use in the last year?"),
    fqQ4: $gettext("Has a family member's drinking or drug use ever had a bad effect on your child?"),

    fqQ5: $gettext("Within the past 12 months, you worried going to bed hungry."),
    // The next two are not present where the Emotional Changes with a New Baby form is being used.
    fqQ6: $gettext("Over the past two weeks, how often have you been having little interest or pleasure in doing things?"),
    fqQ7: $gettext("Over the past two weeks, how often have you been feeling down, depressed, or hopeless?"),
    fqQ8: $gettext("In general, how would you describe your relationship with your spouse/partner?"),
    fqQ9: $gettext("How difficult is it for you and your partner to work out arguments?"),
    fqQ10: $gettext("During the past week, how many days did you or other family members read or tell stories to your child?"),
  }
}

// =====================================
// = Emotional Changes With a new Baby =
// =====================================
// ECWANB_PREAMBLE0: $gettext("Since you have a new baby in your family, we would like to know how you are feeling now."),
// ECWANB_PREAMBLE1: $gettext("Please tell us what comes closest to how you have felt in the past 7 days, not just how you feel today."),
// ECWANB_PREAMBLE2: $gettext("In the past seven days..."),
export function getEmotionalChangesQuestions() {
  return {
    ecwanbQ1: $gettext("In the past seven days, I have been able to laugh and see the funny side of things"),
    ecwanbQ2: $gettext("In the past seven days, I have looked forward with enjoyment to things"),
    ecwanbQ3: $gettext("In the past seven days, I have blamed myself unnecessarily when things went wrong"),
    ecwanbQ4: $gettext("In the past seven days, I have been anxious or worried for no good reason"),
    ecwanbQ5: $gettext("In the past seven days, I have felt scared or panicky for no good reason"),
    ecwanbQ6: $gettext("In the past seven days, things have been getting on top of me"),
    ecwanbQ7: $gettext("In the past seven days, I have been so unhappy that I have had difficulty sleeping"),
    ecwanbQ8: $gettext("In the past seven days, I have felt sad or miserable"),
    ecwanbQ9: $gettext("In the past seven days, I have been so unhappy that I have been crying"),
    ecwanbQ10: $gettext("In the past seven days, the thought of harming myself has occurred to me"),
  }
}

export function getWagOptions() {
  return {
    yesNo: {
      1: $gettext("Yes"),
      2: $gettext("No"),
    },
    amounts: {
      1: $gettext("Not at all"),
      2: $gettext("The same or less"),
      3: $gettext("More"),
      4: $gettext("A lot more"),
    },
    difficulties: {
      1: $gettext("No difficulty"),
      2: $gettext("Some difficulty"),
      3: $gettext("A lot of difficulty"),
      4: $gettext("Cannot do at all"),
      7: $gettext("Refused"),
      9: $gettext("Don’t know"),
    },
    frequencies: {
      1: $gettext("Daily"),
      2: $gettext("Weekly"),
      3: $gettext("Monthly"),
      4: $gettext("A few times a year"),
      5: $gettext("Never"),
      7: $gettext("Refused"),
      9: $gettext("I do not know"),
    },
  }
}

export function getWag2to4Questions() {
  return {
    cf1: $gettext("Does child wear glasses?"),
    cf2: $gettext("When wearing his/her glasses, does child have difficulty seeing?"),
    cf3: $gettext("Does child have difficulty seeing?"),
    cf4: $gettext("Does child use a hearing aid?"),
    cf5: $gettext("When using his/her hearing aid, does child have difficulty hearing sounds like peoples’ voices or music?"),
    cf6: $gettext("Does child have difficulty hearing sounds like peoples’ voices or music?"),
    cf7: $gettext("Does child use any equipment or receive assistance for walking?"),
    cf8: $gettext("Without his/her equipment or assistance, does child have difficulty walking?"),
    cf9: $gettext("With his/her equipment or assistance, does child have difficulty walking?"),
    cf10: $gettext("Compared with children of the same age, does child have difficulty walking?"),
    cf11: $gettext("Compared with children of the same age, does child have difficulty picking up small objects with his/her hand?"),
    cf12: $gettext("Does child have difficulty understanding you?"),
    cf13: $gettext("When child speaks, do you have difficulty understanding him/her?"),
    cf14: $gettext("Compared with children of the same age, does child have difficulty learning things?"),
    cf15: $gettext("Compared with children of the same age, does child have difficulty playing?"),
    cf16: $gettext("Compared with children of the same age, how much does child kick, bite or hit other children or adults?"),
  }
}

export function getWag5to17Questions() {
  return {
    cf1: $gettext("Does child wear glasses?"),
    cf2: $gettext("When wearing his/her glasses, does child have difficulty seeing?"),
    cf3: $gettext("Does child have difficulty seeing?"),
    cf4: $gettext("Does child use a hearing aid?"),
    cf5: $gettext("When using his/her hearing aid, does child have difficulty hearing sounds like peoples’ voices or music?"),
    cf6: $gettext("Does child have difficulty hearing sounds like peoples’ voices or music?"),
    cf7: $gettext("Does child use any equipment or receive assistance for walking?"),
    cf8: $gettext("Without his/her equipment or assistance, does child have difficulty walking 100 meters on level ground? That would be about the length of 1 football field."),
    cf9: $gettext("Without his/her equipment or assistance, does child have difficulty walking 500 meters on level ground? That would be about the length of 5 football fields."),
    cf10: $gettext("With his/her equipment or assistance, does child have difficulty walking 100 meters on level ground? That would be about the length of 1 football field."),
    cf11: $gettext("With his/her equipment or assistance, does child have difficulty walking 500 meters on level ground? That would be about the length of 5 football fields."),
    cf12: $gettext("Compared with children of the same age, does child have difficulty walking 100 meters on level ground? That would be about the length of 1 football field."),
    cf13: $gettext("Compared with children of the same age, does child have difficulty walking 500 meters on level ground? That would be about the length of 5 football fields."),
    cf14: $gettext("Does child have difficulty with self-care such as feeding or dressing him/herself?"),
    cf15: $gettext("When child speaks, does he/she have difficulty being understood by people inside of this household?"),
    cf16: $gettext("When child speaks, does he/she have difficulty being understood by people outside of this household?"),
    cf17: $gettext("Compared with children of the same age, does child have difficulty learning things?"),
    cf18: $gettext("Compared with children of the same age, does child have difficulty remembering things?"),
    cf19: $gettext("Does child have difficulty concentrating on an activity that he/she enjoys doing?"),
    cf20: $gettext("Does child have difficulty accepting changes in his/her routine?"),
    cf21: $gettext("Compared with children of the same age, does child have difficulty controlling his/her behavior?"),
    cf22: $gettext("Does child have difficulty making friends?"),
    cf23: $gettext("How often does child seem very anxious, nervous or worried?"),
    cf24: $gettext("How often does child seem very sad or depressed?"),
  }
}

export function getWag2to4Domains() {
  return [
    { domain: $gettext("Seeing"), fields: ["cf1", "cf2", "cf3"] },
    { domain: $gettext("Hearing"), fields: ["cf4", "cf5", "cf6"] },
    { domain: $gettext("Mobility"), fields: ["cf7", "cf8", "cf9", "cf10"] },
    { domain: $gettext("Fine Motor"), fields: ["cf11"] },
    { domain: $gettext("Communication and Comprehension"), fields: ["cf12", "cf13"] },
    { domain: $gettext("Learning"), fields: ["cf14"] },
    { domain: $gettext("Playing"), fields: ["cf15"] },
    { domain: $gettext("Controlling Behavior"), fields: ["cf16"] },
  ]
}

export function getWag5to17Domains() {
  return [
    { domain: $gettext("Seeing"), fields: ["cf1", "cf2", "cf3"] },
    { domain: $gettext("Hearing"), fields: ["cf4", "cf5", "cf6"] },
    { domain: $gettext("Mobility"), fields: ["cf7", "cf8", "cf9", "cf10", "cf11", "cf12", "cf13"] },
    { domain: $gettext("Self-care"), fields: ["cf14"] },
    { domain: $gettext("Communication and Comprehension"), fields: ["cf15", "cf16"] },
    { domain: $gettext("Learning"), fields: ["cf17"] },
    { domain: $gettext("Remembering"), fields: ["cf18"] },
    { domain: $gettext("Attention and Concentrating"), fields: ["cf19"] },
    { domain: $gettext("Accepting Change"), fields: ["cf20"] },
    { domain: $gettext("Controlling Behavior"), fields: ["cf21"] },
    { domain: $gettext("Relationships"), fields: ["cf22"] },
    { domain: $gettext("Anxiety or Depression"), fields: ["cf23", "cf24"] },
  ]

}
