<template>
  <div class="modal is-active">
    <div class="modal-background" />
    <div class="modal-content">
      <div class="box">
        <div
          v-if="errorMessage"
          class="notification is-warning"
        >
          {{ errorMessage }}
        </div>
        <h1 class="title">
          {{ $gettext("Log In") }}
        </h1>
        <p class="muted">
          {{ $gettext("To continue, you will need to log in again.") }}
        </p>
        <FormKit
          type="form"
          :submit-label="$gettext('Log In')"
          :submit-attrs="{ inputClass: 'button is-primary' }"
          @submit="submitForm"
        >
          <FormKitSchema
            :schema="schema"
            :data="currentUser"
          />
        </FormKit>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from "vue"
  import { useRouter } from "vue-router"

  import { gettext } from "@/utils/Translation"
  import {
    setUserLoggedInStatus,
    showFlashMessage,
    getAccountInfo
  } from "@/utils/GlobalState"
  import { login } from "@/services/Api"
  import { getLoginSchema } from "@/schemas/Account"
  import { updateCachedAccountInfo, wipeSlate } from "@/services/Account"

  const emit = defineEmits(["loginComplete"])
  const { $gettext } = gettext
  const errorMessage = ref(null)
  const accountInfo = getAccountInfo()
  const currentUser = ref({ username: accountInfo.username })
  const router = useRouter()

  // Add a hidden field to the schema that's conditionally shown if the
  // username changes from the current user's.
  const schema = [
    ...getLoginSchema(),
    {
      $formkit: "checkbox",
      id: "acknowledgement",
      name: "acknowledgement",
      label: $gettext("I understand that all unsaved data will be lost"),
      help: $gettext("If you log in with an account other than %{ account }, the changes on this page–as well as all other unsaved data–will be deleted. Also, any downloaded data for offline usage will be removed. To save these changes, change the username back to %{ account }.", { account: accountInfo.username }),
      if: `$get(username).value !== ${accountInfo.username}`,
      validation: "required",
      outerClass: "dire-warning single-checkbox",
    }
  ]

  setUserLoggedInStatus(false)

  function submitForm(data) {
    const { username, password } = data
    this.submitAttrs.inputClass = "button is-loading"
    this.submitLabel = $gettext("Loading")
    login(username, password)
      .then(() => {
        setUserLoggedInStatus(true)
        updateCachedAccountInfo()
        // Base case: they re-authed as themselves. Whew. Bubble back up to the
        // parent that they should carry on.
        if (username === accountInfo.username) {
          showFlashMessage({ msg: $gettext("You are now logged in."), class: "is-success" })
          emit("loginComplete")
        }
        // Edge case: they changed login. That means we need to fully reset everything
        // and go back to the app home.
        else {
          wipeSlate()
            .then(() => {
              showFlashMessage(
                { msg: $gettext("You are now logged in as %{ username }.", { username }),
                class: "is-success" }
              )
              router.push({ name: "SiteList" })
            })
        }
      })
      .catch((error) => {
        errorMessage.value = error.userMessage
        this.submitAttrs.inputClass = "button"
        this.submitLabel = $gettext("Log In")
      })
  }

</script>

<style scoped>
</style>
