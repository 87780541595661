<template>
  <article id="introduction-slide">
    <section>
      <slot />
    </section>
    <nav class="intro-nav">
      <div>
        <router-link
          v-if="currentNumber"
          :to="{ name: routeNames[currentNumber - 1] }"
          :aria-label="$gettext('Go back')"
        >
          <IconLeftArrow />
        </router-link>
      </div>

      <ul class="dots">
        <li
          v-for="i in range(routeNames.length)"
          :key="i"
          aria-hidden="true"
        >
          <router-link :to="{ name: routeNames[i] }">
            <span />
          </router-link>
        </li>
      </ul>

      <div>
        <router-link
          v-if="currentNumber < routeNames.length - 1"
          :to="{ name: routeNames[currentNumber + 1] }"
          :aria-label="$gettext('Go forward')"
        >
          <IconRightArrow />
        </router-link>
      </div>
      <div class="has-text-centered skip-link muted">
        <a
          class="skip-intro"
          @click="skipIntro"
        >
          Skip intro
        </a>
      </div>
    </nav>
  </article>
</template>
<script setup>
  import { useRouter } from "vue-router"

  import { range } from "@/utils/Utilities"
  import { suppressIntroInFuture } from "@/services/Account"
  import IconLeftArrow from "@/components/svg/IconLeftArrow.vue"
  import IconRightArrow from "@/components/svg/IconRightArrow.vue"

  const router = useRouter()
  const routeNames = [
    "IntroSlide1",
    "IntroSlide2",
    "IntroSlide3",
    // "IntroSlide4",
  ]

  function skipIntro() {
    suppressIntroInFuture()
    router.push({ name: "SiteList" })
  }
  defineExpose({ skipIntro }) // Make this available to the parent (when finishing tour).
  defineProps({
    // What number slide are we on (0-indexed)?
    currentNumber: {
      type: Number,
      required: true,
    },
  })
</script>
<style>
</style>
