<template>
  <div :class="['expand-collapse', extraClass]">
    <div
      class="expander-toggle"
      @click="toggle"
    >
      <slot name="label" />
      <IconExpanded v-if="expanded" />
      <IconCollapsed v-else />
    </div>
    <transition
      name="expander"
      @before-enter="beforeExpand"
      @enter="expand"
      @before-leave="beforeCollapse"
      @leave="collapse"
    >
      <div
        v-show="expanded"
        class="expander-content-wrapper"
      >
        <div class="expander-content">
          <slot name="content" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
// This approach borrowed from https://codepen.io/ktsn/pen/dzNRjx
import { ref } from "vue"

import IconCollapsed from "@/components/svg/IconCollapsed.vue"
import IconExpanded from "@/components/svg/IconExpanded.vue"

const props = defineProps({
  expandedAtStart: {
    type: Boolean,
    default: false,
  },
  extraClass: {
    type: String,
    default: ""
  }
})

const expanded = ref(props.expandedAtStart)

function toggle() {
  expanded.value = !expanded.value
}

function beforeExpand(elem) {
  elem.style.height = 0
  elem.style.opacity = 0
}

function expand(elem) {
  elem.style.height = `${elem.scrollHeight}px`
  elem.style.opacity = 1
}

function beforeCollapse(elem) {
  elem.style.height = `${elem.scrollHeight}px`
  elem.style.opacity = 1
}

function collapse(elem) {
  elem.style.height = 0
  elem.style.opacity = 0
}
</script>
<style scoped>
  .expander-toggle {
    cursor: pointer;
  }
  .expander-content-wrapper {
    transition: 150ms ease-out;
  }
</style>
