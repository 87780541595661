import { db, IPendingUpload } from "@/db"
import { Child } from "@/models/Child"
import { uploadAnemiaAssessment, uploadAnemiaAssessmentNote } from "@/services/AnemiaAssessment"
import { dischargeChild, transferChild, uploadChild } from "@/services/Child"
import { uploadDevelopmentalScreening, uploadDevelopmentalScreeningNote } from "@/services/DevelopmentalScreening"
import {
  uploadBestPractice,
  uploadBestPracticeNote,
  uploadFeedingScreening,
  uploadFeedingScreeningNote,
} from "@/services/Feeding"
import { uploadGrowthAssessment, uploadGrowthAssessmentNote } from "@/services/GrowthAssessment"
import { uploadSiteVisitReport } from "@/services/SiteVisitReport"
import { setUserLoggedInStatus, updatePendingUploadCount, useUploadStatusStore } from "@/utils/GlobalState"
import { gettext } from "@/utils/Translation"
import { isOnline } from "@/utils/Utilities"

const { $gettext } = gettext

/*
 * Handle the logic around pulling queued uploads and trying to process them.
 * Order of operations matters: first must clear all pending children uploads
 * before pushing assessments, since we need CMI ids for the children.
 *
 * Can throw typical API errors.
 * @args {Function} uploadFunc - async function to be called to perform the actual upload
 * @args {any} uploadData - whatever argument should be passed on to uploadFunc
 * @return {Number} – number of uploads processed if successful.
 */
export async function handlePendingUploads() {
  const uploadStatusStore = useUploadStatusStore()
  uploadStatusStore.startSync()
  try {

    // Start with children
    const pendingChildren = await db.pendingUploads
      .where("type")
      .equals("child")
      .toArray()

    for (let i = 0; i < pendingChildren.length; i++) {
      await uploadChild(pendingChildren[i].localItemId)
        .then(() => db.pendingUploads.delete(pendingChildren[i].id))
        .then(() => updatePendingUploadCount())
    }

    // Transfer children
    const childrenToTransfer = await db.pendingUploads
      .where("type")
      .equals("childTransfer")
      .toArray()

    for (let i = 0; i < childrenToTransfer.length; i++) {
      await transferChild(childrenToTransfer[i].localItemId)
        .then(() => db.pendingUploads.delete(childrenToTransfer[i].id))
        .then(() => updatePendingUploadCount())
    }

    // Discharge children
    const childrenToDischarge = await db.pendingUploads
      .where("type")
      .equals("childDischarge")
      .toArray()

    for (let i = 0; i < childrenToDischarge.length; i++) {
      await dischargeChild(childrenToDischarge[i].localItemId)
        .then(() => db.pendingUploads.delete(childrenToDischarge[i].id))
        .then(() => updatePendingUploadCount())
    }

    // Growth Assessments
    const pendingAssessments = await db.pendingUploads
      .where("type")
      .equals("growthAssessment")
      .toArray()
    for (let i = 0; i < pendingAssessments.length; i++) {
      await uploadGrowthAssessment(pendingAssessments[i].localItemId)
        .then(() => db.pendingUploads.delete(pendingAssessments[i].id))
        .then(() => updatePendingUploadCount())
    }

    // Growth Assessments notes (added separately)
    const pendingAssessmentsWithNotes = await db.pendingUploads
      .where("type")
      .equals("growthAssessmentNote")
      .toArray()
    for (let i = 0; i < pendingAssessmentsWithNotes.length; i++) {
      await uploadGrowthAssessmentNote(pendingAssessmentsWithNotes[i].localItemId)
        .then(() => db.pendingUploads.delete(pendingAssessmentsWithNotes[i].id))
        .then(() => updatePendingUploadCount())
    }

    // Best Practices
    const pendingBPAs = await db.pendingUploads
      .where("type")
      .equals("childBestPracticeAssessment")
      .toArray()
    for (let i = 0; i < pendingBPAs.length; i++) {
      await uploadBestPractice(pendingBPAs[i].localItemId)
        .then(() => db.pendingUploads.delete(pendingBPAs[i].id))
        .then(() => updatePendingUploadCount())
    }

    // Best Practices with notes (added separately)
    const pendingBPAsWithNotes = await db.pendingUploads
      .where("type")
      .equals("childBestPracticeAssessmentNote")
      .toArray()
    for (let i = 0; i < pendingBPAsWithNotes.length; i++) {
      await uploadBestPracticeNote(pendingBPAsWithNotes[i].localItemId)
        .then(() => db.pendingUploads.delete(pendingBPAsWithNotes[i].id))
        .then(() => updatePendingUploadCount())
    }


    // Developmental Screenings
    const pendingScreenings = await db.pendingUploads
      .where("type")
      .equals("developmentalScreening")
      .toArray()
    for (let i = 0; i < pendingScreenings.length; i++) {
      await uploadDevelopmentalScreening(pendingScreenings[i].localItemId)
        .then(() => db.pendingUploads.delete(pendingScreenings[i].id))
        .then(() => updatePendingUploadCount())
    }

    // Developmental Screening notes (added separately)
    const pendingScreeningsWithNotes = await db.pendingUploads
      .where("type")
      .equals("developmentalScreeningNote")
      .toArray()
    for (let i = 0; i < pendingScreeningsWithNotes.length; i++) {
      await uploadDevelopmentalScreeningNote(pendingScreeningsWithNotes[i].localItemId)
        .then(() => db.pendingUploads.delete(pendingScreeningsWithNotes[i].id))
        .then(() => updatePendingUploadCount())
    }
    // Anemia assessments
    const pendingAnemia = await db.pendingUploads
      .where("type")
      .equals("anemiaAssessment")
      .toArray()
    for (let i = 0; i < pendingAnemia.length; i++) {
      await uploadAnemiaAssessment(pendingAnemia[i].localItemId)
        .then(() => db.pendingUploads.delete(pendingAnemia[i].id))
        .then(() => updatePendingUploadCount())
    }

    // Anemia assessment notes (added separately)
    const pendingAnemiaWithNotes = await db.pendingUploads
      .where("type")
      .equals("anemiaAssessmentNote")
      .toArray()
    for (let i = 0; i < pendingAnemiaWithNotes.length; i++) {
      await uploadAnemiaAssessmentNote(pendingAnemiaWithNotes[i].localItemId)
        .then(() => db.pendingUploads.delete(pendingAnemiaWithNotes[i].id))
        .then(() => updatePendingUploadCount())
    }

    // Feeding Screenings
    const pendingFeedingScreenings = await db.pendingUploads
      .where("type")
      .equals("feedingScreening")
      .toArray()
    for (let i = 0; i < pendingFeedingScreenings.length; i++) {
      await uploadFeedingScreening(pendingFeedingScreenings[i].localItemId)
        .then(() => db.pendingUploads.delete(pendingFeedingScreenings[i].id))
        .then(() => updatePendingUploadCount())
    }

    // Feeding screening notes (added separately)
    const pendingFeedingScreeningsWithNotes = await db.pendingUploads
      .where("type")
      .equals("feedingScreeningNote")
      .toArray()
    for (let i = 0; i < pendingFeedingScreeningsWithNotes.length; i++) {
      await uploadFeedingScreeningNote(pendingFeedingScreeningsWithNotes[i].localItemId)
        .then(() => db.pendingUploads.delete(pendingFeedingScreeningsWithNotes[i].id))
        .then(() => updatePendingUploadCount())
    }

    // Site Visit Reports
    const pendingSiteVisitReports = await db.pendingUploads
      .where("type")
      .equals("siteVisitReport")
      .toArray()
    for (let i = 0; i < pendingSiteVisitReports.length; i++) {
      await uploadSiteVisitReport(pendingSiteVisitReports[i].localItemId)
        .then(() => db.pendingUploads.delete(pendingSiteVisitReports[i].id))
        .then(() => updatePendingUploadCount())
    }

  }
  finally {
    uploadStatusStore.endSync()
  }
}

export async function queuePendingUpload(obj: IPendingUpload) {
  // Check to see if this guy is already in the queue–don't double-enter.
  const existingItem = await db.pendingUploads.get(obj)
  if (!existingItem) {
    await db.pendingUploads.put(obj)
    await updatePendingUploadCount()
  }
}

/*
 * Handle the logic around whether to attempt calling an upload function to save data to the server
 * and handling certain errors that might result. If the data are uploaded successfully,
 * delete them from the upload queue.
 * @args {Function} uploadFunc - async function to be called to perform the actual upload
 * @args {any} uploadData - whatever argument should be passed on to uploadFunc
 * Doesn't return anything.
 */
export async function attemptUpload(uploadFunc: (data: object) => Promise<void>, uploadData: object, queueData: IPendingUpload) {
  if (isOnline()) {
    const uploadStatusStore = useUploadStatusStore()
    uploadStatusStore.startSync()
    await uploadFunc(uploadData)
      .then(async () => {
        db.pendingUploads
          .where(queueData)
          .delete()
      })
      .catch(async (error: Error) => {
        // We should do specific things for other types of error as well, such
        // as SERVER_ERROR and FORBIDDEN. (CONNECTIVITY_REQUIRED not so much.)
        if (error.name === "LOGIN_REQUIRED") {
          setUserLoggedInStatus(false)
          // Rethrow the error; it should be handled by the interface.
          throw error
        }
        else {
          console.log(error)
        }
      })
      .finally(() => uploadStatusStore.endSync())

  }
  // else {
  //   await queuePendingUpload(queueData)
  // }
}

interface PendingUploadContent {
  label: string,
  count: number,
  items: Array<{ id: number, label: string }>
}

// Return null preemptively if the upload table is empty. Otherwise,
// an array of objects with props:
// - label
// - count
// - items (database results)
export async function getContentPendingUpload(): Promise<null | Array<PendingUploadContent>> {

  if (!await db.pendingUploads.count()) {
    return null
  }

  const results = []

  const pendingChildrenIds = await db.pendingUploads
    .where("type")
    .equals("child")
    .toArray(items => items.map(item => item.localItemId))

  if (pendingChildrenIds.length) {
    const pendingChildren = await db.children
      .where("id")
      .anyOf(pendingChildrenIds)
      .toArray(children => children.map(child => new Child(child)))

    results.push({
      label: $gettext("New or updated children"),
      count: pendingChildren.length,
      items: pendingChildren.map(child => { return { id: child.id, label: child.fullName } }),
    })
  }

  const pendingDischargeIds = await db.pendingUploads
    .where("type")
    .equals("childDischarge")
    .toArray(items => items.map(item => item.localItemId))

  if (pendingDischargeIds.length) {
    const pendingDischargedChildren = await db.children
      .where("id")
      .anyOf(pendingDischargeIds)
      .toArray(children => children.map(child => new Child(child)))

    results.push({
      label: $gettext("Children to be discharged"),
      count: pendingDischargedChildren.length,
      items: pendingDischargedChildren.map(child => { return { id: child.id, label: child.fullName } }),
    })
  }

  const pendingTransferIds = await db.pendingUploads
    .where("type")
    .equals("childTransfer")
    .toArray(items => items.map(item => item.localItemId))

  if (pendingTransferIds.length) {
    const pendingTransferChildren = await db.children
      .where("id")
      .anyOf(pendingTransferIds)
      .toArray(children => children.map(child => new Child(child)))

    results.push({
      label: $gettext("Children to be transferred to another site"),
      count: pendingTransferChildren.length,
      items: pendingTransferChildren.map(child => { return { id: child.id, label: child.fullName } }),
    })
  }

  const pendingGrowthIds = await db.pendingUploads
    .where("type")
    .equals("growthAssessment")
    .toArray(items => items.map(item => item.localItemId))

  if (pendingGrowthIds.length) {
    const pendingGrowthAssessments = await db.growthAssessments
      .where("id")
      .anyOf(pendingGrowthIds)
      .toArray()

    results.push({
      label: $gettext("Growth assessments"),
      count: pendingGrowthAssessments.length,
      items: pendingGrowthAssessments.map(assess => {
        return {
          id: assess.id,
          label: $gettext("Assessment performed %{date}", { date: assess.dateOfAssessment.toLocaleDateString() })
        }
      }),
    })
  }

  const pendingGrowthNotesIds = await db.pendingUploads
    .where("type")
    .equals("growthAssessmentNote")
    .toArray(items => items.map(item => item.localItemId))

  if (pendingGrowthNotesIds.length) {
    const pendingGrowthAssessments = await db.growthAssessments
      .where("id")
      .anyOf(pendingGrowthNotesIds)
      .toArray()

    results.push({
      label: $gettext("Notes on existing growth assessments"),
      count: pendingGrowthAssessments.length,
      items: pendingGrowthAssessments.map(assess => {
        return {
          id: assess.id,
          label: $gettext("Assessment performed %{date}", { date: assess.dateOfAssessment.toLocaleDateString() })
        }
      }),
    })
  }

  const pendingScreeningIds = await db.pendingUploads
    .where("type")
    .equals("developmentalScreening")
    .toArray(items => items.map(item => item.localItemId))

  if (pendingScreeningIds.length) {
    const pendingScreenings = await db.developmentalScreenings
      .where("id")
      .anyOf(pendingScreeningIds)
      .toArray()

    results.push({
      label: $gettext("Developmental screenings"),
      count: pendingScreenings.length,
      items: pendingScreenings.map(assess => {
        return {
          id: assess.id,
          label: $gettext("Screening performed %{date}", { date: assess.dateOfAssessment.toLocaleDateString() })
        }
      }),
    })
  }

  const pendingScreeningsNotesIds = await db.pendingUploads
    .where("type")
    .equals("developmentalScreeningNote")
    .toArray(items => items.map(item => item.localItemId))

  if (pendingScreeningsNotesIds.length) {
    const pendingScreenings = await db.developmentalScreenings
      .where("id")
      .anyOf(pendingScreeningsNotesIds)
      .toArray()

    results.push({
      label: $gettext("New notes on existing developmental screenings"),
      count: pendingScreenings.length,
      items: pendingScreenings.map(assess => {
        return {
          id: assess.id,
          label: $gettext("Screening performed %{date}", { date: assess.dateOfAssessment.toLocaleDateString() })
        }
      }),
    })
  }

  const pendingAnemiaIds = await db.pendingUploads
    .where("type")
    .equals("anemiaAssessment")
    .toArray(items => items.map(item => item.localItemId))

  if (pendingAnemiaIds.length) {
    const pendingAssessments = await db.anemiaAssessments
      .where("id")
      .anyOf(pendingAnemiaIds)
      .toArray()

    results.push({
      label: $gettext("Anemia assessments"),
      count: pendingAssessments.length,
      items: pendingAssessments.map(assess => {
        return {
          id: assess.id,
          label: $gettext("Assessment performed %{date}", { date: assess.dateOfAssessment.toLocaleDateString() })
        }
      }),
    })
  }

  const pendingAnemiaNotesIds = await db.pendingUploads
    .where("type")
    .equals("anemiaAssessmentNote")
    .toArray(items => items.map(item => item.localItemId))

  if (pendingAnemiaNotesIds.length) {
    const pendingAssessments = await db.anemiaAssessments
      .where("id")
      .anyOf(pendingAnemiaNotesIds)
      .toArray()

    results.push({
      label: $gettext("New notes on existing anemia assessments"),
      count: pendingAssessments.length,
      items: pendingAssessments.map(assess => {
        return {
          id: assess.id,
          label: $gettext("Assessment performed %{date}", { date: assess.dateOfAssessment.toLocaleDateString() })
        }
      }),
    })
  }

  const pendingFeedingScreeningIds = await db.pendingUploads
    .where("type")
    .equals("feedingScreening")
    .toArray(items => items.map(item => item.localItemId))

  if (pendingFeedingScreeningIds.length) {
    const pendingAssessments = await db.feedingScreenings
      .where("id")
      .anyOf(pendingFeedingScreeningIds)
      .toArray()

    results.push({
      label: $gettext("Feeding screenings"),
      count: pendingAssessments.length,
      items: pendingAssessments.map(assess => {
        return {
          id: assess.id,
          label: $gettext("Assessment performed %{date}", { date: assess.dateOfAssessment.toLocaleDateString() })
        }
      }),
    })
  }

  const pendingFeedingScreeningNotesIds = await db.pendingUploads
    .where("type")
    .equals("feedingScreeningNote")
    .toArray(items => items.map(item => item.localItemId))

  if (pendingFeedingScreeningNotesIds.length) {
    const pendingAssessments = await db.feedingScreenings
      .where("id")
      .anyOf(pendingFeedingScreeningNotesIds)
      .toArray()

    results.push({
      label: $gettext("New notes on existing feeding screenings"),
      count: pendingAssessments.length,
      items: pendingAssessments.map(assess => {
        return {
          id: assess.id,
          label: $gettext("Assessment performed %{date}", { date: assess.dateOfAssessment.toLocaleDateString() })
        }
      }),
    })
  }

  // Site Visit Reports
  const pendingSiteVisitReportIds = await db.pendingUploads
    .where("type")
    .equals("siteVisitReport")
    .toArray(items => items.map(item => item.localItemId))

  if (pendingSiteVisitReportIds.length) {
    const pendingSiteVisitReports = await db.siteVisitReports
      .where("id")
      .anyOf(pendingSiteVisitReportIds)
      .toArray()

    results.push({
      label: $gettext("Site visit reports"),
      count: pendingSiteVisitReports.length,
      items: pendingSiteVisitReports.map(report => {
        return {
          id: report.id,
          label: $gettext("Report performed %{date}", { date: report.dateOfVisit.toLocaleDateString() })
        }
      }),
    })
  }

  return results
}
