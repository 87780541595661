<script lang="ts" setup>
import { ref } from "vue"

import {
  getCachedProjectsAndSites,
  updateSitesFromServer,
} from "@/services/Site"
import {
  getLastUpdateInfo,
  areCachedDataStale,
  showFlashMessage,
  setStalenessNotificationTime,
  setCurrentViewContext,
  getAccountInfo,
 } from "@/utils/GlobalState"
import { gettext } from "@/utils/Translation"
import useServerRefresher from "@/composables/useServerRefresher"

import PageTitleWithRefresh from "@/components/PageTitleWithRefresh.vue"
import ProjectListItem from "@/components/sites/ProjectListItem.vue"
import SiteListItem from "@/components/sites/SiteListItem.vue"
import LastSynced from "@/components/LastSynced.vue"
import PageLoading from "@/components/PageLoading.vue"

const { $gettext } = gettext
const { refreshUnderway, serverRefresher } = useServerRefresher()
const { canSeeAppLevelReports } = getAccountInfo()
const staleNotificationQueryKey = "sitesAndProjects"

const projects = ref([])
const sites = ref([])
const haveResults = ref(false) // Has getCachedProjectsAndSites returned yet, really.
const lastSynced = ref(undefined)

setCurrentViewContext()

async function getSitesLocal() {
  let projectsAndSites = await getCachedProjectsAndSites()
  projects.value = projectsAndSites.projects
  sites.value = projectsAndSites.sites
  haveResults.value = true
  lastSynced.value = await getLastUpdateInfo({ type: "sitesAndProjects" })
}

async function getSitesServer(wasManuallyTriggered) {
  await serverRefresher(updateSitesFromServer, [], staleNotificationQueryKey, wasManuallyTriggered)
    .catch(error => {
      if (error.name === "CONNECTIVITY_REQUIRED") {
        const msg = $gettext("You do not appear to be online.")
        let details = ""
        if (lastSynced.value) {
          details = $gettext("This list was last updated %{ date }.", { date: lastSynced.value })
        }
        else {
          details = $gettext("Your sites have never been updated from the server.")
        }
        showFlashMessage({ msg: `${msg} ${details}`, class: "is-warning", appearanceDelay: 0 })
        setStalenessNotificationTime(staleNotificationQueryKey)
      }
      else {
        throw error
      }
    })
  await getSitesLocal()
}

async function getData() {
  if (await areCachedDataStale({ type: "sitesAndProjects" })) {
    await getSitesServer()
  }
  else {
    await getSitesLocal()
    if (!sites.value.length && !projects.value.length) {
      await getSitesServer(true)
    }
  }
}

getData()
</script>

<template>
  <article>
    <PageTitleWithRefresh
      :title="$gettext('My Sites')"
      :refresh-function="getSitesServer"
      :refresh-underway="refreshUnderway"
    />

    <router-link
      v-if="canSeeAppLevelReports"
      :to="{ name: 'AppLevelDemographicReport' }"
      class="button is-fullwidth"
    >
      App-level reports
    </router-link>

    <div v-if="haveResults">
      <template
        v-for="project in projects"
        :key="project.id"
      >
        <ProjectListItem :project="project" />
      </template>
      <template
        v-for="site in sites"
        :key="site.id"
      >
        <SiteListItem :site="site" />
      </template>
      <p v-if="!sites.length && !projects.length">
        {{ $gettext("No sites are available to show.") }}
      </p>
    </div>
    <PageLoading
      v-else
      :with-text="true"
    />
    <LastSynced :last-synced="lastSynced" />
  </article>
</template>

<style scoped>
.button {
  margin-bottom: 2em;
}
</style>
