import dayjs from "dayjs"
import { Observation, SexSpecification } from "jsgrowup2"

import { INTERPRETATIONS, LEARN_MORE, RECOMMENDATIONS } from "@/data/GrowthRecommendations"
import { IGrowthAssessment } from "@/db"
import { isAssessmentEditable } from "@/models/Common"
import { getAllSavedGrowthAssessmentsForChild } from "@/services/GrowthAssessment"
import { gettext } from "@/utils/Translation"
import { isKnown } from "@/utils/Utilities"
import { Child } from "./Child"

const { $gettext } = gettext

// Intermediate object returned by get<X>Status methods
interface StatusSummary {
  verdict: string,
  stoplightColor: string,
  interpretationIds: Array<number>,
  recommendationIds: Array<number>,
  followupAssessmentInterval?: object,
}

// Object containing a discrete interpretation or recommendation
interface RecommendationItem {
  contentId: number,   // Corresponds w/ row nums in Google Sheet
  content: string,     // Interpretation or recommendation content
  learnMore: string,   // Hidden by default
  isReferral: boolean, // Does this item indicate that this assessment should trigger a referral?
}

// The entire content for a given growth metric (weight, head circumference, etc)
interface Interpretation {
  metric: string,  // e.g. "weight", "muac"
  label: string,  // translated string, e.g. "Head Size"
  userFacingMeasurement: string, // e.g. "13.1 kg"
  verdict: string,
  stoplightColor: string,
  interpretations: Array<RecommendationItem>,
}


export class GrowthAssessment implements IGrowthAssessment {
  child: Child

  _ageInDays: number
  _ageInMonths: number
  _ageInYears: number
  _previousAssessments: Array<GrowthAssessment>
  _interpretations: { weight?: Interpretation, height?: Interpretation, headSize?: Interpretation, muac?: Interpretation }
  _recommendations: Array<RecommendationItem>

  id: number
  isComplete: boolean
  childId: number
  siteId: number
  cmiId?: number
  canChildStand: boolean
  canStraightenLegs: boolean
  dateOfAssessment: Date
  dateCreated?: Date
  headCircumferenceInCm?: number
  weightIsDirty?: boolean
  headSizeIsDirty?: boolean
  lengthInCm?: number
  lengthIsDirty?: boolean
  muacInCm?: number
  typeOfLength?: string
  weightInKilograms?: number

  hasReferrals: boolean
  zScoreAcfa: number
  zScoreBmi: number
  zScoreHca: number
  zScoreLhfa: number
  zScoreWfa: number
  zScoreWfh: number
  zScoreWfl: number
  followupAssessmentInterval: object

  constructor(child: Child, data: IGrowthAssessment) {
    this.child = child instanceof Child ? child : new Child(child)
    this.siteId = child.siteId
    this.isComplete = false
    this._interpretations = {}
    this._recommendations = []
    for (const [key, value] of Object.entries(data)) {
      this[key] = value
    }
    if (this.cmiId) {
      // Presumably imported from server
      this.isComplete = true
    }
  }

  get wrappedChild() {
    // For some reason, our child is devolving to an object at certain points
    // (Noticed when calling get niceAge)
    return this.child instanceof Child ? this.child : new Child(this.child)
  }

  get zScoreWflh() {
    return isKnown(this.zScoreWfl) ? this.zScoreWfl : this.zScoreWfh
  }

  get ageInDays() {
    if (!this._ageInDays && this.dateOfAssessment && this.child.dob) {
      this._ageInDays = dayjs(this.dateOfAssessment).diff(this.child.dob, "day")
    }
    return this._ageInDays
  }

  get ageInMonths() {
    if (!this._ageInMonths && this.dateOfAssessment && this.child.dob) {
      this._ageInMonths = dayjs(this.dateOfAssessment).diff(this.child.dob, "month", true)
    }
    return this._ageInMonths
  }

  get ageInYears() {
    if (!this._ageInYears && this.dateOfAssessment && this.child.dob) {
      this._ageInYears = dayjs(this.dateOfAssessment).diff(this.child.dob, "year", true)
    }
    return this._ageInYears
  }

  get previousAssessments() {
    return this._previousAssessments
  }

  // Requires that async processAssessment() be called first!
  get interpretations() {
    return this._interpretations
  }

  // Requires that async processAssessment() be called first!
  get recommendations() {
    return this._recommendations
  }

  // Requires that async processAssessment() be called first!
  get referrals() {
    return this._recommendations
      .filter(item => item.isReferral)
      .map(item => { return { id: item.contentId, content: item.content } })
  }

  // Return the date by which we would like a referral to be made. We're saying
  // two weeks from the date of assessment. Note: this doesn't check to see *if*
  // a referral was made.
  get referralDate() {
    return dayjs(this.dateOfAssessment).add(2, "week").toDate()
  }

  // Requires that async processAssessment() be called first!
  get reportCard(): { stoplightColor: string, statements: Array<string> } {
    const results = { stoplightColor: "", statements: [] }
    const problems = Object.fromEntries(Object.entries(this.interpretations).filter(pair => {
      return pair[1].stoplightColor != "green"
    }))
    if (Object.keys(problems).length) {
      results.stoplightColor = "red"
      results.statements = Object.values(problems).map(int => $gettext("%{label}: %{sentence}", { label: int.label, sentence: int.verdict }))
    }
    else {
      results.stoplightColor = "green"
      results.statements = [$gettext("Growth appears to be on track")]
    }
    return results
  }

  isYoungerThan(options: { years?: number }): boolean {
    const doa = dayjs(this.dateOfAssessment)
    if (options.years) {
      return doa.subtract(options.years, "year").isBefore(this.child.dob)
    }
  }

  get bmi() {
    // Body Mass Index (BMI) is a person's weight in kilograms divided by the
    // square of height in meters.
    if (!this.weightInKilograms || !this.adjustedLengthInCm) {
      return
    }
    return this.weightInKilograms / (this.adjustedLengthInCm / 100) ** 2
  }

  get niceAge() {
    if (this.dateOfAssessment) {
      return this.wrappedChild.getNiceAge(this.dateOfAssessment)
    }
  }

  // Return the adjusted length: subtract 0.7cm for children over 2 that were measured recumbently.
  get adjustedLengthInCm() {
    if (this.lengthInCm && this.ageInYears >= 2 && this.typeOfLength == "length") {
      return this.lengthInCm - 0.7
    }
    return this.lengthInCm
  }

  // Is this assessment old enough that we should prevent edits to it?
  get isEditable() {
    return isAssessmentEditable(this)
  }

  get hasDirtyData() {
    return this.lengthIsDirty || this.weightIsDirty || this.headSizeIsDirty
  }

  // For our current child, save an array of previous growth assessments, sorted
  // from youngest to oldest, to the instance variable previousAssessments.
  async setPreviousAssessments() {
    const assessments = []
    let data = await getAllSavedGrowthAssessmentsForChild(this.child.id)
    data.reverse()
    data = data.filter((a: IGrowthAssessment) => a.dateOfAssessment < this.dateOfAssessment)
    for (let i = 0; i < data.length; i++) {
      const assessment = new GrowthAssessment(this.child, data[i])
      await assessment.setZScores()
      assessments.push(assessment)
    }
    this._previousAssessments = assessments
  }

  // Return a wrapped GrowthAssessment that immediately preceded this one, or null.
  async getPreviousAssessment() {
    let data = await getAllSavedGrowthAssessmentsForChild(this.child.id)
    data.reverse()
    data = data.filter((a: IGrowthAssessment) => a.dateOfAssessment < this.dateOfAssessment)
    return data.length ? new GrowthAssessment(this.child, data[0]) : null
  }

  getPreviousCleanAssessment(metric: string) {
    const mapping = {
      headSize: {
        dirtyFieldName: "headSizeIsDirty",
        fieldName: "headCircumferenceInCm",
      },
      length: {
        dirtyFieldName: "lengthIsDirty",
        fieldName: "lengthInCm",
      },
      weight: {
        dirtyFieldName: "weightIsDirty",
        fieldName: "weightInKilograms",
      },
    }
    const { fieldName, dirtyFieldName } = mapping[metric]
    const filtered = this.previousAssessments.filter(a => a[fieldName] && !a[dirtyFieldName])
    return filtered.length ? filtered[0] : null
  }

  async setZScores() {
    const observation = new Observation(
      this.child.sex as SexSpecification,
      { dob: this.child.dob, dateOfObservation: this.dateOfAssessment }
    )

    // All age checks are based on jsgrowup2's expectations.
    // Head Circumference
    if (this.headCircumferenceInCm && this.ageInDays < 1856) {
      try {
        this.zScoreHca = parseFloat(await observation.headCircumferenceForAge(this.headCircumferenceInCm))
      }
      catch (error) {
        // Mainly to catch measurements out of range.
        console.log(error)
      }
    }

    // Arm Circumference (MUAC)
    if (this.muacInCm && this.ageInDays < 1856 && this.ageInDays > 91) {
      try {
        this.zScoreAcfa = parseFloat(await observation.armCircumferenceForAge(this.muacInCm))
      }
      catch (error) {
        // Mainly to catch measurements out of range.
        console.log(error)
      }
    }

    // Weight for length/height or BMI for age
    if (this.weightInKilograms && this.adjustedLengthInCm) {
      if (this.ageInDays < 365 * 2) {
        try {
          this.zScoreWfl = parseFloat(await observation.weightForLength(this.weightInKilograms, this.adjustedLengthInCm))
        }
        catch (error) {
          // Mainly to catch measurements out of range.
          console.log(error)
        }
      }
      else if (this.ageInDays < 365 * 5) {
        try {
          this.zScoreWfh = parseFloat(await observation.weightForHeight(this.weightInKilograms, this.adjustedLengthInCm))
        }
        catch (error) {
          // Mainly to catch measurements out of range.
          console.log(error)
        }
      }
      // Calculate BMI for age regardless of whether we have one of the above.
      if (this.ageInDays < 365 * 19) {
        try {
          this.zScoreBmi = parseFloat(await observation.bmiForAge(this.bmi))
        }
        catch (error) {
          // Mainly to catch measurements out of range.
          console.log(error)
        }
      }
    }

    // Weight for age
    if (this.weightInKilograms && this.ageInDays < 365 * 10) {
      try {
        this.zScoreWfa = parseFloat(await observation.weightForAge(this.weightInKilograms))
      }
      catch (error) {
        // Mainly to catch measurements out of range.
        console.log(error)
      }
    }

    // Length/height for age
    if (this.adjustedLengthInCm && this.ageInDays < 365 * 19) {
      try {
        // We're assuming that the length is already adjusted if over 2.
        this.zScoreLhfa = parseFloat(await observation.lengthOrHeightForAge(this.adjustedLengthInCm))
      }
      catch (error) {
        // Mainly to catch measurements out of range.
        console.log(error)
      }
    }
  }

  // The computation of interpretations, recommendations, and the followupAssessmentInterval
  // all happen at once in this sprawling tangle of if statements. The side effects of calling
  // this method are the caching of values for:
  // this._interpretations
  // this._recommendations
  // this._followupAssessmentDate
  // Note that if justFollowupAssessmentInterval is true, then we skip the recs/int
  // calculation (it gets chicken and eggy; we need the f/u interval to separately set
  // the child's next assessment date, which we need as a placeholder in the content
  // generation.)
  async processAssessment(justFollowupAssessmentInterval = false): Promise<void> {
    await this.setZScores()
    await this.setPreviousAssessments()

    // We have to process all the metrics before we can parse them into actual recs,
    // since we need to figure out the next assessment date before we can flesh out
    // the placeholders in the content.
    let results: StatusSummary
    const metrics = []
    const recommendations = []
    const fuAssessmentIntervals = []

    // Figure out weight
    // Weight is a little complicated – three possible routes to getting recs.
    results = undefined // Empty it out and then check later to see if if there's anything to process.
    if (isKnown(this.zScoreWflh) && this.ageInYears < 5) {
      results = this.getWflhRecs()
    }
    else if (isKnown(this.zScoreBmi && this.ageInYears < 19)) {
      results = this.getBmiRecs()
    }
    else if (isKnown(this.zScoreWfa && this.ageInYears < 10)) {
      results = this.getWfaRecs()
    }
    if (isKnown(results)) {
      recommendations.push(...results.recommendationIds)
      if (results.followupAssessmentInterval) {
        fuAssessmentIntervals.push(results.followupAssessmentInterval)
      }
      metrics.push({
        ...{
          metric: "weight",
          label: $gettext("Weight"),
          userFacingMeasurement: $gettext("%{number} kg", { number: this.weightInKilograms.toString() }),
          interpretations: [],
          recommendations: [],
        },
        ...results
      })
    }

    // Figure out length/height
    if (isKnown(this.zScoreLhfa) && this.isYoungerThan({ years: 19 })) {
      results = this.getHeightRecs()
      recommendations.push(...results.recommendationIds)
      if (results.followupAssessmentInterval) {
        fuAssessmentIntervals.push(results.followupAssessmentInterval)
      }
      metrics.push({
        ...{
          metric: "height",
          label: this.typeOfLength == "height" ? $gettext("Height") : $gettext("Length"),
          userFacingMeasurement: $gettext("%{number} cm", { number: this.lengthInCm.toString() }),
          interpretations: [],
        },
        ...results
      })
    }

    // Figure out head circumference
    if (isKnown(this.zScoreHca) && this.isYoungerThan({ years: 5 })) {
      results = this.getHeadSizeRecs()
      recommendations.push(...results.recommendationIds)
      if (results.followupAssessmentInterval) {
        fuAssessmentIntervals.push(results.followupAssessmentInterval)
      }
      metrics.push({
        ...{
          metric: "headSize",
          label: $gettext("Head Size"),
          userFacingMeasurement: $gettext("%{number} cm", { number: this.headCircumferenceInCm.toString() }),
          interpretations: [],
        },
        ...results
      })
    }

    // Figure out MUAC. Note that even if MUAC is measured, we only deal with it here
    // under specific circumstances (no weight measured OR older child & no height)
    if (isKnown(this.muacInCm)) {
      if (!this.weightInKilograms || (!this.lengthInCm && this.ageInYears >= 10)) {
        results = this.getMuacRecs()
        recommendations.push(...results.recommendationIds)
        if (results.followupAssessmentInterval) {
          fuAssessmentIntervals.push(results.followupAssessmentInterval)
        }
        metrics.push({
          ...{
            metric: "muac",
            label: $gettext("Arm Size"),
            userFacingMeasurement: $gettext("%{number} cm", { number: this.muacInCm.toString() }),
            interpretations: [],
          },
          ...results
        })
      }
    }

    // Process follow up interval
    // Sort our list from shortest interval on up. Each element is an obj with either
    // a single property: weeks or months, with integer values
    fuAssessmentIntervals.sort((a, b) => {
      if ("weeks" in a) {
        if ("weeks" in b) {
          return a.weeks > b.weeks ? 1 : a.weeks == b.weeks ? 0 : -1
        }
        else {
          return -1
        }
      }
      else if ("weeks" in b) {
        return 1
      }
      else {
        return a.months > b.months ? 1 : a.months == b.months ? 0 : -1
      }
    })
    this.followupAssessmentInterval = fuAssessmentIntervals[0] || null

    if (!justFollowupAssessmentInterval) {
      // Begin post-processing
      const placeholders = {
        length_or_height: this.typeOfLength == "height" ? $gettext("height") : $gettext("length"),
        last_assessment_date: this.previousAssessments[0]?.dateOfAssessment.toLocaleDateString(),
        follow_up_date: this.child.nextGrowthAssessmentDate?.toLocaleDateString(),
      }

      // Process interpretations
      metrics.forEach(metric => {
        metric.interpretations = this.convertRecs(metric.interpretationIds, INTERPRETATIONS, placeholders)
        this._interpretations[metric.metric] = metric
      })
      // Process recommendations
      // Dedupe and process
      this._recommendations = this.convertRecs([...new Set(recommendations)], RECOMMENDATIONS, placeholders)
    }
  }

  // Analyze head circumference
  getHeadSizeRecs() {
    const prev = this.getPreviousCleanAssessment("headSize")
    const delta = prev ? this.zScoreHca - prev.zScoreHca : null

    if (-2 <= this.zScoreHca && this.zScoreHca <= 2) {
      if (!prev || !isKnown(prev.zScoreHca)) {
        // Initial case
        return {
          verdict: $gettext("Normal for age"),
          stoplightColor: "green",
          interpretationIds: [1],
          recommendationIds: [],
        }
      }
      else {
        if (Math.abs(delta) <= 0.5) {
          return {
            verdict: $gettext("Normal for age"),
            stoplightColor: "green",
            interpretationIds: [2],
            recommendationIds: [],
          }
        }
        else if (delta > 0.5) {
          return {
            verdict: $gettext("Increasing sharply"),
            stoplightColor: "red",
            followupAssessmentInterval: { months: 1 },
            interpretationIds: [3, 4],
            recommendationIds: [2, 3, 17],
          }
        }
        else {
          // i.e. delta < -0.5
          return {
            verdict: $gettext("Slowing down"),
            stoplightColor: "red",
            followupAssessmentInterval: { months: 3 },
            interpretationIds: [5, 4],
            recommendationIds: [2, 4, 17],
          }
        }
      }
    }
    else if (this.zScoreHca < -2) {
      if (!prev || !isKnown(prev.zScoreHca)) {
        // Initial case
        return {
          verdict: $gettext("Small for age"),
          stoplightColor: "red",
          followupAssessmentInterval: { months: 1 },
          interpretationIds: [6, 9],
          recommendationIds: [7, 17],
        }
      }
      else {
        if (Math.abs(delta) <= 0.5) {
          return {
            verdict: $gettext("Normal for age"),
            stoplightColor: "green",
            interpretationIds: [7],
            recommendationIds: [],
          }
        }
        else if (delta > 0.5) {
          return {
            verdict: $gettext("Increasing sharply"),
            stoplightColor: "red",
            followupAssessmentInterval: { months: 1 },
            interpretationIds: [3, 4],
            recommendationIds: [2, 3, 17],
          }
        }
        else {
          // i.e. delta < -0.5
          return {
            verdict: $gettext("Slowing down"),
            stoplightColor: "red",
            followupAssessmentInterval: { months: 1 },
            interpretationIds: [8, 9],
            recommendationIds: [6, 17],
          }
        }
      }
    }
    else {
      // i.e. this.zScoreHca > 2
      if (!prev || !isKnown(prev.zScoreHca)) {
        // Initial case
        return {
          verdict: $gettext("Large for age"),
          stoplightColor: "red",
          followupAssessmentInterval: { months: 1 },
          interpretationIds: [10],
          recommendationIds: [7, 17],
        }
      }
      else {
        if (Math.abs(delta) <= 0.5) {
          return {
            verdict: $gettext("Normal for age"),
            stoplightColor: "green",
            interpretationIds: [11],
            recommendationIds: [],
          }
        }
        else if (delta > 0.5) {
          return {
            verdict: $gettext("Increasing sharply"),
            stoplightColor: "red",
            followupAssessmentInterval: { months: 1 },
            interpretationIds: [3, 4],
            recommendationIds: [2, 8, 17],
          }
        }
        else {
          // i.e. delta < -0.5
          return {
            verdict: $gettext("Slowing down"),
            stoplightColor: "red",
            followupAssessmentInterval: { months: 3 },
            interpretationIds: [5, 4],
            recommendationIds: [2, 4, 17],
          }
        }
      }
    }
  }

  // Analyze length/height
  getHeightRecs(): StatusSummary {
    const prev = this.getPreviousCleanAssessment("length")
    if (this.isYoungerThan({ years: 5 })) {
      if (this.zScoreLhfa >= -2) {
        if (!prev || !isKnown(prev.zScoreLhfa)) {
          // Initial case
          return {
            verdict: $gettext("Normal for age"),
            stoplightColor: "green",
            interpretationIds: [30],
            recommendationIds: [],
          }
        } else {
          const delta = this.zScoreLhfa - prev.zScoreLhfa
          if (Math.abs(delta) <= 0.5) {
            return {
              verdict: $gettext("Normal for age"),
              stoplightColor: "green",
              interpretationIds: [31],
              recommendationIds: [],
            }
          } else if (delta > 0.5) {
            return {
              verdict: $gettext("Increasing sharply"),
              stoplightColor: "green",
              interpretationIds: [32, 23, 4],
              recommendationIds: [24],
            }
          } else {
            // z_score_delta < -0.5
            return {
              verdict: $gettext("Slowing down"),
              stoplightColor: "red",
              followupAssessmentInterval: { months: 3 },
              interpretationIds: [33, 37, 4],
              recommendationIds: [24, 26, 17],
            }
          }
        }
      } else {
        // z_score_lhfa < -2
        if (!prev || !isKnown(prev.zScoreLhfa)) {
          // Initial case
          return {
            verdict: $gettext("Short for age"),
            stoplightColor: "red",
            followupAssessmentInterval: { "months": 1 },
            interpretationIds: [34],
            recommendationIds: [17],
          }
        } else {
          const delta = this.zScoreLhfa - prev.zScoreLhfa
          if (Math.abs(delta) <= 0.5) {
            return {
              verdict: $gettext("Normal for age"),
              stoplightColor: "green",
              followupAssessmentInterval: this.isYoungerThan({ years: 1 }) ? { "months": 1 } : null,
              interpretationIds: [35],
              recommendationIds: [],
            }
          } else if (delta > 0.5) {
            return {
              // Row 49
              verdict: $gettext("Increasing sharply"),
              stoplightColor: "green",
              followupAssessmentInterval: this.isYoungerThan({ years: 1 }) ? { "months": 1 } : null,
              interpretationIds: [32, 23, 4],
              recommendationIds: [24, 17],
            }
          } else {
            // z_score_delta < -0.5
            return {
              verdict: $gettext("Slowing down"),
              stoplightColor: "red",
              followupAssessmentInterval: this.isYoungerThan({ years: 5 }) ? { "months": 1 } : null,
              interpretationIds: [33, 37, 4],
              recommendationIds: [24, 27, 17],
            }
          }
        }
      }
    } else {
      if (this.zScoreLhfa >= -2) {
        if (!prev || !isKnown(prev.zScoreLhfa)) {
          // Initial case (row 75)
          return {
            verdict: $gettext("Normal for age"),
            stoplightColor: "green",
            interpretationIds: [51],
            recommendationIds: [],
          }
        } else {
          const delta = this.zScoreLhfa - prev.zScoreLhfa
          if (Math.abs(delta) <= 0.5) {
            // Row 76
            return {
              verdict: $gettext("Normal for age"),
              stoplightColor: "green",
              interpretationIds: [52],
              recommendationIds: [],
            }
          } else if (delta > 0.5) {
            // Row 77
            return {
              verdict: $gettext("Increasing sharply"),
              stoplightColor: "green",
              interpretationIds: [53, 55, 4],
              recommendationIds: [30],
            }
          } else {
            // delta < -0.5
            // Row 78
            return {
              verdict: $gettext("Slowing down"),
              stoplightColor: "red",
              followupAssessmentInterval: { "months": 3 },
              interpretationIds: [54, 56, 4],
              recommendationIds: [30, 32, 17],
            }
          }

        }

      }
      else {
        // z-score < -2
        if (!prev || !isKnown(prev.zScoreLhfa)) {
          // Initial case (row 79)
          return {
            verdict: $gettext("Short for age"),
            stoplightColor: "red",
            followupAssessmentInterval: { "months": 3 },
            interpretationIds: [34],
            recommendationIds: [17],
          }
        } else {
          const delta = this.zScoreLhfa - prev.zScoreLhfa
          if (Math.abs(delta) <= 0.5) {
            // Row 80
            return {
              verdict: $gettext("Normal for age"),
              stoplightColor: "green",
              interpretationIds: [35],
              recommendationIds: [],
            }
          } else if (delta > 0.5) {
            // Row 81
            return {
              verdict: $gettext("Increasing sharply"),
              stoplightColor: "green",
              interpretationIds: [53, 55, 4],
              recommendationIds: [30],
            }
          } else {
            // delta < -0.5
            // Row 82
            return {
              verdict: $gettext("Slowing down"),
              stoplightColor: "red",
              followupAssessmentInterval: { "months": 3 },
              interpretationIds: [54, 56, 4],
              recommendationIds: [30, 33, 17],
            }
          }
        }
      }
    }
  }

  // Analyze weight (weight for length/height)
  getWflhRecs(): StatusSummary {
    const current = this.zScoreWflh
    const prev = this.getPreviousCleanAssessment("weight")
    const prevVal = prev ? prev.zScoreWflh : null
    const lengthOrHeight = this.typeOfLength == "length" ? $gettext("length") : $gettext("height")

    // Case 1: not wasted or overweight
    if (-2 <= current && current <= 2) {
      if (!prev || !isKnown(prevVal)) {
        // Initial case (row 17)
        const recommendationIds = []
        if (this.ageInMonths < 6) {
          recommendationIds.push(40)
        }
        else if (this.ageInMonths < 12) {
          recommendationIds.push(41)
        }
        else {
          recommendationIds.push(42)
        }
        recommendationIds.push(43)
        return {
          verdict: $gettext("Normal for %{ length_or_height }", { length_or_height: lengthOrHeight }),
          stoplightColor: "green",
          interpretationIds: [12],
          recommendationIds,
        }
      }
      else {
        const delta = current - prevVal
        if (Math.abs(delta) <= 0.5) {
          // Row 18
          const recommendationIds = []
          if (this.ageInMonths < 6) {
            recommendationIds.push(40)
          }
          else if (this.ageInMonths < 12) {
            recommendationIds.push(41)
          }
          else {
            recommendationIds.push(42)
          }
          recommendationIds.push(43)
          return {
            verdict: $gettext("Normal for %{ length_or_height }", { length_or_height: lengthOrHeight }),
            stoplightColor: "green",
            interpretationIds: [13],
            recommendationIds,
          }
        } else
          if (delta > 0.5) {
            // Rows 19/20
            const recommendationIds = []
            if (this.ageInMonths < 6) {
              recommendationIds.push(37)
            }
            else if (this.ageInMonths < 12) {
              recommendationIds.push(36, 38)
            }
            else {
              recommendationIds.push(38)
            }
            recommendationIds.push(12, 17)
            return {
              verdict: $gettext("Increasing sharply"),
              stoplightColor: "red",
              followupAssessmentInterval: { months: 3 },
              interpretationIds: [14, 15],
              recommendationIds,
            }
          }
          else {
            // i.e. delta < -0.5
            const recommendationIds = []
            let interpretationIds: Array<number>
            if (this.weightInKilograms >= prev.weightInKilograms) {
              // Rows 21/22
              interpretationIds = [16, 18]
              if (this.ageInMonths < 6) {
                recommendationIds.push(37)
              }
              else if (this.ageInMonths < 12) {
                recommendationIds.push(36, 38)
              }
              else {
                recommendationIds.push(38)
              }
              recommendationIds.push(14, 17)
            }
            else {
              // Rows 23/24
              interpretationIds = [17, 19]
              if (this.ageInMonths < 6) {
                recommendationIds.push(37)
              }
              else if (this.ageInMonths < 12) {
                recommendationIds.push(36, 38)
              }
              else {
                recommendationIds.push(38)
              }
              recommendationIds.push(16, 17)
            }
            return {
              verdict: $gettext("Slowing down"),
              stoplightColor: "red",
              followupAssessmentInterval: { months: 1 },
              interpretationIds,
              recommendationIds,
            }
          }
      }
    }
    // Case 2: wasted
    else if (current < -2) {
      if (!prev || !isKnown(prevVal)) {
        // Initial case (rows 25/26)
        const recommendationIds = []
        if (current >= -3) {
          if (this.ageInMonths < 6) {
            recommendationIds.push(37, 44)
          }
          else if (this.ageInMonths < 12) {
            recommendationIds.push(36, 38, 44, 39)
          }
          else {
            recommendationIds.push(38, 45, 39)
          }
          recommendationIds.push(17)
        }
        else {
          recommendationIds.push(35, 17)
        }
        return {
          verdict: $gettext("Low for %{ length_or_height }", { length_or_height: lengthOrHeight }),
          stoplightColor: "red",
          followupAssessmentInterval: current < -3 ? { weeks: 2 } : { months: 1 },
          interpretationIds: [20, 21],
          recommendationIds,
        }
      }
      else {
        const delta = current - prevVal
        if (Math.abs(delta) <= 0.5) {
          if (current < prevVal) {
            // Weight loss; Row 105
            const recommendationIds = []
            if (this.ageInMonths < 6) {
              recommendationIds.push(37, 44)
            }
            else if (this.ageInMonths < 12) {
              recommendationIds.push(36, 38, 44, 39)
            }
            else {
              recommendationIds.push(38, 45, 39)
            }
            recommendationIds.push(22, 17)
            return {
              verdict: $gettext("Slowing down"),
              stoplightColor: "red",
              followupAssessmentInterval: { months: 1 },
              interpretationIds: [17, 19],
              recommendationIds,
            }
          }
          else if (current > prevVal) {
            // Weight gain
            if (delta <= 0) {
              // But not enough. Row 107
              const recommendationIds = []
              if (this.ageInMonths < 6) {
                recommendationIds.push(37, 44)
              }
              else if (this.ageInMonths < 12) {
                recommendationIds.push(36, 38, 44, 39)
              }
              else {
                recommendationIds.push(38, 45, 39)
              }
              recommendationIds.push(14, 17)
              return {
                verdict: $gettext("Slowing down"),
                stoplightColor: "red",
                followupAssessmentInterval: { months: 1 },
                interpretationIds: [16, 18],
                recommendationIds,
              }
            }
            else {
              // Modest z score improvement. Row 108.
              const recommendationIds = []
              if (this.ageInMonths < 6) {
                recommendationIds.push(37, 44)
              }
              else if (this.ageInMonths < 12) {
                recommendationIds.push(36, 38, 44, 39)
              }
              else {
                recommendationIds.push(38, 45, 39)
              }
              recommendationIds.push(17)
              return {
                verdict: $gettext("Normal for %{ length_or_height }", { length_or_height: lengthOrHeight }),
                stoplightColor: "green",
                followupAssessmentInterval: { months: 1 },
                interpretationIds: [22],
                recommendationIds,
              }
            }
          }
          else {
            // Static weight. Row 106, which is the same as 107.
            const recommendationIds = []
            if (this.ageInMonths < 6) {
              recommendationIds.push(37, 44)
            }
            else if (this.ageInMonths < 12) {
              recommendationIds.push(36, 38, 44, 39)
            }
            else {
              recommendationIds.push(38, 45, 39)
            }
            recommendationIds.push(14, 17)
            return {
              verdict: $gettext("Slowing down"),
              stoplightColor: "red",
              followupAssessmentInterval: { months: 1 },
              interpretationIds: [16, 18],
              recommendationIds,
            }
          }
        }
        else if (delta > 0.5) {
          // (rows 28/29)
          const recommendationIds = []
          if (this.ageInMonths < 6) {
            recommendationIds.push(37, 44)
          }
          else if (this.ageInMonths < 12) {
            recommendationIds.push(36, 38, 44, 39)
          }
          else {
            recommendationIds.push(38, 45, 39)
          }
          recommendationIds.push(17)
          return {
            verdict: $gettext("Increasing sharply"),
            stoplightColor: "red",
            followupAssessmentInterval: { months: 1 },
            interpretationIds: [14, 23],
            recommendationIds,
          }
        }
        else {
          // i.e. delta < -0.5
          const recommendationIds = []
          let interpretationIds: Array<number>
          if (this.weightInKilograms >= prev.weightInKilograms) {
            // (rows 30, 31)
            interpretationIds = [16, 18]
            if (current >= -3) {
              if (this.ageInMonths < 6) {
                recommendationIds.push(37, 44)
              } else if (this.ageInMonths < 12) {
                recommendationIds.push(36, 38, 44, 39)
              }
              else {
                recommendationIds.push(38, 45, 39)
              }
              recommendationIds.push(21, 17)
            }
            else {
              recommendationIds.push(35, 17)
            }
          }
          else {
            // (rows 32, 33)
            interpretationIds = [17, 19]
            if (current >= -3) {
              if (this.ageInMonths < 6) {
                recommendationIds.push(37, 44)
              }
              if (this.ageInMonths < 12) {
                recommendationIds.push(36, 38, 44, 39)
              }
              else {
                recommendationIds.push(38, 45, 39)
              }
              recommendationIds.push(22, 17)
            }
            else {
              recommendationIds.push(35, 17)
            }
          }
          return {
            verdict: $gettext("Slowing down"),
            stoplightColor: "red",
            followupAssessmentInterval: current < -3 ? { weeks: 2 } : { months: 1 },
            interpretationIds,
            recommendationIds,
          }
        }
      }
    }
    // Case 3: overweight
    else {
      // i.e. current > 2:
      if (!prev || !isKnown(prevVal)) {
        // Initial case (row 34)
        const recommendationIds = []
        if (this.ageInMonths < 6) {
          recommendationIds.push(37)
        }
        else if (this.ageInMonths < 12) {
          recommendationIds.push(36, 38)
        }
        else {
          recommendationIds.push(38)
        }
        recommendationIds.push(17)
        return {
          verdict: $gettext("High for %{ length_or_height }", { length_or_height: lengthOrHeight }),
          stoplightColor: "red",
          followupAssessmentInterval: { months: 1 },
          interpretationIds: [24, 25],
          recommendationIds,
        }
      }
      else {
        const delta = current - prevVal
        if (Math.abs(delta) <= 0.5) {
          // Row 35
          const recommendationIds = []
          if (this.ageInMonths < 6) {
            recommendationIds.push(40)
          }
          else if (this.ageInMonths < 12) {
            recommendationIds.push(41)
          }
          else {
            recommendationIds.push(42)
          }
          recommendationIds.push(43)
          return {
            verdict: $gettext("Normal for %{ length_or_height }", { length_or_height: lengthOrHeight }),
            stoplightColor: "green",
            interpretationIds: [26],
            recommendationIds,
          }
        }
        else if (delta > 0.5) {
          // Row 36, 37
          const recommendationIds = []
          if (this.ageInMonths < 6) {
            recommendationIds.push(37)
          }
          else if (this.ageInMonths < 12) {
            recommendationIds.push(36, 38)
          }
          else {
            recommendationIds.push(38)
          }
          recommendationIds.push(12, 17)
          return {
            verdict: $gettext("Increasing sharply"),
            stoplightColor: "red",
            followupAssessmentInterval: { months: 3 },
            interpretationIds: [14, 15, 27],
            recommendationIds,
          }
        }
        else {
          // i.e. delta < -0.5
          const recommendationIds = []
          let followupAssessmentInterval: object, interpretationIds: Array<number>
          if (this.weightInKilograms >= prev.weightInKilograms) {
            // Rows 38, 39
            interpretationIds = [16, 28]
            followupAssessmentInterval = this.ageInMonths < 12 ? { months: 1 } : { months: 3 }
            if (this.ageInMonths < 6) {
              recommendationIds.push(37)
            }
            else if (this.ageInMonths < 12) {
              recommendationIds.push(36, 38)
            }
            else {
              recommendationIds.push(38)
            }
            recommendationIds.push(14, 17)
          }
          else {
            // Rows 40, 41
            interpretationIds = [17, 29]
            followupAssessmentInterval = { months: 1 }
            if (this.ageInMonths < 6) {
              recommendationIds.push(37)
            }
            else if (this.ageInMonths < 12) {
              recommendationIds.push(36, 38)
            }
            else {
              recommendationIds.push(38)
            }
            recommendationIds.push(16, 17)
          }
          return {
            verdict: $gettext("Slowing down"),
            stoplightColor: "red",
            followupAssessmentInterval,
            interpretationIds,
            recommendationIds,
          }
        }
      }
    }
  }

  // Analyze weight (BMI for age)
  getBmiRecs() {
    const prev = this.getPreviousCleanAssessment("weight")
    const prevVal = prev ? prev.zScoreBmi : null
    const delta = isKnown(prevVal) ? this.zScoreBmi - prevVal : null

    if (-2 <= this.zScoreBmi && this.zScoreBmi <= 1) {
      if (!prev || !isKnown(prevVal)) {
        // Initial case (row 53)
        return {
          verdict: $gettext("Normal for age"),
          stoplightColor: "green",
          interpretationIds: [38],
          recommendationIds: [42, 43],
        }
      }
      else {
        if (Math.abs(delta) <= 0.5) {
          // Row 54
          return {
            verdict: $gettext("Normal for age"),
            stoplightColor: "green",
            interpretationIds: [39],
            recommendationIds: [42, 43],
          }
        }
        else if (delta > 0.5) {
          // Row 55
          return {
            verdict: $gettext("Increasing sharply"),
            stoplightColor: "red",
            followupAssessmentInterval: { months: 3 },
            interpretationIds: [40, 41],
            recommendationIds: [28, 38, 17],
          }
        }
        else {
          // That is, delta < -0.5
          if (this.weightInKilograms >= prev.weightInKilograms) {
            // Row 56
            return {
              verdict: $gettext("Slowing down"),
              stoplightColor: "red",
              followupAssessmentInterval: { months: 3 },
              interpretationIds: [42, 18],
              recommendationIds: [38, 14, 17],
            }
          }
          else {
            // Row 57
            return {
              verdict: $gettext("Slowing down"),
              stoplightColor: "red",
              followupAssessmentInterval: { months: 1 },
              interpretationIds: [17, 19],
              recommendationIds: [38, 16, 17],
            }
          }
        }
      }
    }
    else if (this.zScoreBmi < -2) {
      if (!prev || !isKnown(prevVal)) {
        // Initial case (row 58)
        return {
          verdict: $gettext("Low for age"),
          stoplightColor: "red",
          followupAssessmentInterval: { months: 1 },
          interpretationIds: [43, 65],
          recommendationIds: [38, 39, 17],
        }
      }
      else {
        if (Math.abs(delta) <= 0.5) {
          // Row 59
          return {
            verdict: $gettext("Normal for age"),
            stoplightColor: "red",
            followupAssessmentInterval: { months: 3 },
            interpretationIds: [44],
            recommendationIds: [38, 39, 17],
          }
        }
        else if (delta > 0.5) {
          // Row 60
          return {
            verdict: $gettext("Increasing sharply"),
            stoplightColor: "red",
            followupAssessmentInterval: { months: 3 },
            interpretationIds: [40, 23],
            recommendationIds: [38, 39, 17],
          }
        }
        else {
          // That is, delta < -0.5
          if (this.weightInKilograms >= prev.weightInKilograms) {
            // Row 61
            return {
              verdict: $gettext("Slowing down"),
              stoplightColor: "red",
              followupAssessmentInterval: { months: 1 },
              interpretationIds: [42, 18],
              recommendationIds: [38, 39, 21, 17],
            }
          }
          else {
            // Row 62
            return {
              verdict: $gettext("Slowing down"),
              stoplightColor: "red",
              followupAssessmentInterval: { months: 1 },
              interpretationIds: [17, 19],
              recommendationIds: [38, 39, 22, 17],
            }
          }
        }
      }
    }
    else if (1 < this.zScoreBmi && this.zScoreBmi <= 2) {
      if (!prev || !isKnown(prevVal)) {
        // Initial case (row 63)
        return {
          verdict: $gettext("High for age"),
          stoplightColor: "red",
          followupAssessmentInterval: { months: 1 },
          interpretationIds: [45, 49],
          recommendationIds: [28, 38, 17],
        }
      }
      else {
        if (Math.abs(delta) <= 0.5) {
          // Row 64
          return {
            verdict: $gettext("Normal for age"),
            stoplightColor: "green",
            interpretationIds: [46],
            recommendationIds: [28, 38],
          }
        }
        else if (delta > 0.5) {
          // Row 65
          return {
            verdict: $gettext("Increasing sharply"),
            stoplightColor: "red",
            followupAssessmentInterval: { months: 3 },
            interpretationIds: [40, 41, 47],
            recommendationIds: [28, 38, 12, 17],
          }
        }
        else {
          // That is, delta < -0.5
          if (this.weightInKilograms >= prev.weightInKilograms) {
            // Row 66
            return {
              verdict: $gettext("Slowing down"),
              stoplightColor: "red",
              followupAssessmentInterval: { months: 3 },
              interpretationIds: [42, 28],
              recommendationIds: [28, 38, 14, 17],
            }
          }
          else {
            // Row 67
            return {
              verdict: $gettext("Slowing down"),
              stoplightColor: "red",
              followupAssessmentInterval: { months: 1 },
              interpretationIds: [17, 29],
              recommendationIds: [28, 38, 16, 17],
            }
          }
        }
      }
    }
    else {
      // That is, this.zScoreBmi > 2
      if (!prev || !isKnown(prevVal)) {
        // Initial case (row 68)
        return {
          verdict: $gettext("High for age"),
          followupAssessmentInterval: { months: 1 },
          stoplightColor: "red",
          interpretationIds: [48, 49],
          recommendationIds: [28, 38, 17],
        }
      }
      else {
        if (Math.abs(delta) <= 0.5) {
          // Row 69
          return {
            verdict: $gettext("Normal for age"),
            stoplightColor: "green",
            followupAssessmentInterval: { months: 3 },
            interpretationIds: [39, 50],
            recommendationIds: [28, 38, 17],
          }
        }
        else if (delta > 0.5) {
          // Row 70
          return {
            verdict: $gettext("Increasing sharply"),
            stoplightColor: "red",
            followupAssessmentInterval: { months: 1 },
            interpretationIds: [40, 41],
            recommendationIds: [28, 38, 12, 17],
          }
        }
        else {
          // That is, delta < -0.5
          if (this.weightInKilograms >= prev.weightInKilograms) {
            // Row 71
            return {
              verdict: $gettext("Slowing down"),
              stoplightColor: "red",
              followupAssessmentInterval: { months: 3 },
              interpretationIds: [42, 28],
              recommendationIds: [28, 38, 14, 17],
            }
          }
          else {
            // Row 72
            return {
              verdict: $gettext("Slowing down"),
              stoplightColor: "red",
              followupAssessmentInterval: { months: 3 },
              interpretationIds: [17, 29],
              recommendationIds: [28, 38, 16, 17],
            }
          }
        }
      }
    }
  }

  // Analyze weight (weight for age)
  getWfaRecs(): StatusSummary {
    const prev = this.getPreviousCleanAssessment("weight")
    const prevVal = isKnown(prev) ? prev.zScoreWfa : null
    const delta = isKnown(prevVal) ? this.zScoreWfa - prevVal : null
    if (this.zScoreWfa >= -2) {
      if (!isKnown(prevVal)) {
        // Initial case (row 84)
        const recommendationIds = []
        if (this.ageInMonths < 6) {
          recommendationIds.push(40)
        }
        else if (this.ageInMonths < 12) {
          recommendationIds.push(41)
        }
        else {
          recommendationIds.push(42)
        }
        recommendationIds.push(43)
        return {
          verdict: $gettext("Normal for age"),
          stoplightColor: "green",
          interpretationIds: [57],
          recommendationIds
        }
      }
      else {
        if (Math.abs(delta) <= 0.5) {
          // Row 85
          const recommendationIds = []
          if (this.ageInMonths < 6) {
            recommendationIds.push(40)
          }
          else if (this.ageInMonths < 12) {
            recommendationIds.push(41)
          }
          else {
            recommendationIds.push(42)
          }
          recommendationIds.push(43)
          return {
            verdict: $gettext("Normal for age"),
            stoplightColor: "green",
            interpretationIds: [58],
            recommendationIds,
          }
        }
        else if (delta > 0.5) {
          // Rows 86/87
          const recommendationIds = []
          if (this.ageInMonths < 6) {
            recommendationIds.push(37)
          }
          else if (this.ageInMonths < 12) {
            recommendationIds.push(36, 38)
          }
          else {
            recommendationIds.push(38)
          }
          recommendationIds.push(12, 17)
          return {
            verdict: $gettext("Increasing sharply"),
            stoplightColor: "red",
            followupAssessmentInterval: { months: 3 },
            interpretationIds: [59, 60],
            recommendationIds
          }
        }
        else {
          // i.e. delta < -0.5
          const interpretationIds = []
          const recommendationIds = []
          if (this.weightInKilograms >= prev.weightInKilograms) {
            // Rows 88/89
            interpretationIds.push(61, 18)
            if (this.ageInMonths < 6) {
              recommendationIds.push(37)
            }
            else if (6 <= this.ageInMonths && this.ageInMonths < 12) {
              recommendationIds.push(36, 38)
            }
            else {
              recommendationIds.push(38)
            }
            recommendationIds.push(14, 17)
          }
          else {
            // Rows 90/91
            interpretationIds.push(17, 19)
            recommendationIds.push(11)
            if (this.ageInMonths < 6) {
              recommendationIds.push(37)
            }
            else if (6 <= this.ageInMonths && this.ageInMonths < 12) {
              recommendationIds.push(36, 38)
            }
            else {
              recommendationIds.push(38)
            }
            recommendationIds.push(16, 17)
          }
          return {
            verdict: $gettext("Slowing down"),
            stoplightColor: "red",
            followupAssessmentInterval: { months: 1 },
            interpretationIds,
            recommendationIds,
          }
        }
      }
    }
    else {
      // this.zScoreWfa < -2
      if (!isKnown(prevVal)) {
        // Initial case (rows 92/93)
        const recommendationIds = []
        if (this.ageInMonths < 6) {
          recommendationIds.push(37, 44)
        }
        else if (6 <= this.ageInMonths && this.ageInMonths < 12) {
          recommendationIds.push(36, 38, 44, 39)
        }
        else {
          recommendationIds.push(38, 45, 39)
        }
        recommendationIds.push(17)
        return {
          verdict: $gettext("Low for age"),
          stoplightColor: "red",
          followupAssessmentInterval: { months: 1 },
          interpretationIds: [62, 63],
          recommendationIds,
        }
      }
      else {
        // The recs for rows 95-104 are mostly the same, so set them here.
        const recommendationIds = []
        if (this.ageInMonths < 6) {
          recommendationIds.push(37, 44)
        }
        else if (6 <= this.ageInMonths && this.ageInMonths < 12) {
          recommendationIds.push(36, 38, 44, 39)
        }
        else {
          recommendationIds.push(38, 45, 39)
        }
        if (Math.abs(delta) <= 0.5) {
          // (rows 101-104)
          const stoplightColor = "red"
          if (this.zScoreWfa < prevVal) {
            // Weight loss; Row 101
            recommendationIds.push(22, 17)
            return {
              verdict: $gettext("Slowing down"),
              interpretationIds: [17, 19],
              followupAssessmentInterval: { months: 1 },
              stoplightColor,
              recommendationIds,
            }
          }
          else if (this.zScoreWfa > prevVal) {
            // Modest weight gain
            if (delta <= 0) {
              // Modest z score drop; Row 103
              recommendationIds.push(14, 17)
              return {
                verdict: $gettext("Slowing down"),
                interpretationIds: [61, 18],
                followupAssessmentInterval: { "months": this.ageInYears < 5 ? 1 : 3 },
                stoplightColor,
                recommendationIds
              }
            }
            else {
              // Modest z score improvement; Row 104
              recommendationIds.push(17)
              return {
                stoplightColor: "green",
                verdict: $gettext("Normal for age"),
                followupAssessmentInterval: { "months": this.ageInYears < 5 ? 1 : 3 },
                interpretationIds: [64],
                recommendationIds,
              }
            }
          }
          else {
            // this.zScoreWfa == prevVal: static weight; Row 102
            recommendationIds.push(14, 17)
            return {
              verdict: $gettext("Slowing down"),
              stoplightColor,
              followupAssessmentInterval: { "months": this.ageInYears < 5 ? 1 : 3 },
              interpretationIds: [61, 18],
              recommendationIds,
            }
          }
        }
        else if (delta > 0.5) {
          // (rows 95/6)
          recommendationIds.push(17)
          return {
            verdict: $gettext("Increasing sharply"),
            stoplightColor: "red",
            followupAssessmentInterval: { months: this.ageInYears < 5 ? 1 : 3 },
            interpretationIds: [59, 23],
            recommendationIds,
          }
        }
        else {
          let interpretationIds: Array<number>
          // i.e. delta < -0.5
          if (this.weightInKilograms >= prev.weightInKilograms) {
            // (rows 97, 98)
            interpretationIds = [61, 18]
            recommendationIds.push(21, 17)
          }
          else {
            // (rows 99, 100)
            interpretationIds = [17, 19]
            recommendationIds.push(22, 17)
          }
          return {
            verdict: $gettext("Slowing down"),
            stoplightColor: "red",
            followupAssessmentInterval: { months: 1 },
            interpretationIds,
            recommendationIds,
          }
        }
      }
    }
  }

  /*
    Return one of null, 'normal', 'mam', or 'sam' (moderate|severe acute
    malnutrition) based on the child's age at evaluation and the MUAC
    measurement.

    We use Z-scores for children under 5, and age-based cutoff points up to
    18 years.

    We return null if:
    - no measurement
    - age is out of range
    - we inexplicably lack a z-score when one is expected
  */
  getMuacEvaluation() {
    if (
      !this.muacInCm ||
      this.ageInMonths < 3 ||
      this.ageInYears > 18 ||
      (this.ageInYears < 5 && !isKnown(this.zScoreAcfa))
    ) {
      return
    }

    let indicator: number
    let normalMin: number
    let mamMin: number

    // Under 5: use z-score
    if (this.ageInYears < 5) {
      indicator = this.zScoreAcfa
      normalMin = -2
      mamMin = -3
    }
    else if (this.ageInYears < 10) {
      indicator = this.muacInCm
      normalMin = 14.5
      mamMin = 13.5
    }
    else if (this.ageInYears < 15) {
      indicator = this.muacInCm
      normalMin = 18.5
      mamMin = 16
    }
    else {
      // Less than 18 years
      indicator = this.muacInCm
      normalMin = 21
      mamMin = 18.5
    }
    if (indicator >= normalMin) {
      return "normal"
    }
    else if (indicator >= mamMin) {
      return "mam"
    }
    else {
      return "sam"
    }
  }

  getMuacRecs(): StatusSummary {
    const verdict = this.getMuacEvaluation()
    const recommendationIds = []
    // We assume verdict is not null; that is, it's one of normal, sam, or mam
    if (verdict == "normal") {
      if (this.ageInYears < 5) {
        // Row 3
        if (this.ageInMonths < 6) {
          recommendationIds.push(40)
        }
        else if (this.ageInMonths <= 12) {
          recommendationIds.push(41)
        }
        else {
          recommendationIds.push(42)
        }
        recommendationIds.push(43)
      }
      else {
        // Rows 8, 13, 18
        recommendationIds.push(42, 43)
      }
      return {
        verdict: $gettext("Normal for age"),
        stoplightColor: "green",
        interpretationIds: [66],
        recommendationIds,
      }
    }
    else if (verdict == "mam") {
      // Rows 4/5
      if (this.ageInYears < 5) {
        if (this.ageInMonths < 6) {
          recommendationIds.push(37, 44)
        }
        else if (this.ageInMonths <= 12) {
          recommendationIds.push(36, 38, 44, 39)
        }
        else {
          recommendationIds.push(38, 45, 39)
        }
        recommendationIds.push(17)
      }
      else {
        // Rows 9, 10, 14, 15, 19, 20
        return {
          verdict: $gettext("Small for age"),
          stoplightColor: "red",
          followupAssessmentInterval: { "months": 1 },
          interpretationIds: [67, 69],
          recommendationIds: [38, 45, 39, 17],
        }
      }
    }
    else {
      // i.e. verdict == "sam"
      // Rows 6, 7, 11, 12, 16, 17, 21, 22
      return {
        verdict: $gettext("Very small for age"),
        stoplightColor: "red",
        followupAssessmentInterval: this.ageInYears < 5 ? { "weeks": 2 } : { "months": 1 },
        interpretationIds: [68, 69],
        recommendationIds: [35, 17],
      }
    }
  }

  // Given an array of content ids, a source variable (either INTERPRETATIONS or RECOMMENDATIONS)
  // and an object with possible placeholder replacement values, return an array
  // of the corresponding RecommendationItems.
  convertRecs(ids: Array<number>, src: object, placeholders: { [key: string]: string }): Array<RecommendationItem> {
    const results = []
    ids.forEach((id: number) => {
      const item = src[id]
      if (!item) {
        console.log(`Missing growth interpretation or recommendation for id ${id}`)
        return
      }
      const line = this.child.usesCommunityRecs ? item.comRec || item.line : item.line
      let learnMore = ""
      if (item.learnMoreId) {
        const learnMoreObj = LEARN_MORE[item.learnMoreId]
        learnMore = this.child.usesCommunityRecs ? learnMoreObj.comLine || learnMoreObj.line : learnMoreObj.line
      }
      results.push({
        contentId: item.contentId,
        content: $gettext(line, placeholders),
        learnMore: learnMore ? $gettext(learnMore, placeholders) : "",
        isReferral: item.isReferral,
      })
    })
    return results
  }
}
