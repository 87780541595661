import { gettext } from "@/utils/Translation.js"

const { $gettext } = gettext

export const INTERPRETATIONS = {
    1: {
        "contentId": 1,
        "i18n_line": $gettext("Current head size is normal for the child's age."),
        "line": "Current head size is normal for the child's age.",
        "learnMoreId": 1,
    },
    2: {
        "contentId": 2,
        "i18n_line": $gettext("Head size appears to be growing normally for the child's age."),
        "line": "Head size appears to be growing normally for the child's age.",
        "learnMoreId": 1,
    },
    3: {
        "contentId": 3,
        "i18n_line": $gettext("Head size has increased sharply since %{ last_assessment_date }."),
        "line": "Head size has increased sharply since %{ last_assessment_date }.",
        "learnMoreId": 3,
    },
    4: {
        "contentId": 4,
        "i18n_line": $gettext("If this is unexpected, it could be due to an error in measurement."),
        "line": "If this is unexpected, it could be due to an error in measurement.",
    },
    5: {
        "contentId": 5,
        "i18n_line": $gettext("Head size growth has slowed down relative to the child's age since %{ last_assessment_date }."),
        "line": "Head size growth has slowed down relative to the child's age since %{ last_assessment_date }.",
    },
    6: {
        "contentId": 6,
        "i18n_line": $gettext("Current head size appears to be too small for the child's age."),
        "line": "Current head size appears to be too small for the child's age.",
        "learnMoreId": 6,
    },
    7: {
        "contentId": 7,
        "i18n_line": $gettext("While the current head size is small, it appears to be growing normally relative to the child's age."),
        "line": "While the current head size is small, it appears to be growing normally relative to the child's age.",
    },
    8: {
        "contentId": 8,
        "i18n_line": $gettext("Head size is too small for age and its growth has slowed down since %{ last_assessment_date }."),
        "line": "Head size is too small for age and its growth has slowed down since %{ last_assessment_date }.",
    },
    9: {
        "contentId": 9,
        "i18n_line": $gettext("This may place the child at risk for developmental delays. Therefore, close monitoring is needed."),
        "line": "This may place the child at risk for developmental delays. Therefore, close monitoring is needed.",
        "learnMoreId": 2,
    },
    10: {
        "contentId": 10,
        "i18n_line": $gettext("Current head size appears to be too large for the child's age."),
        "line": "Current head size appears to be too large for the child's age.",
        "learnMoreId": 9,
    },
    11: {
        "contentId": 11,
        "i18n_line": $gettext("While the current head size is large, it appears to be growing normally relative to the child's age."),
        "line": "While the current head size is large, it appears to be growing normally relative to the child's age.",
    },
    12: {
        "contentId": 12,
        "i18n_line": $gettext("Current weight is normal for the child's %{ length_or_height }."),
        "line": "Current weight is normal for the child's %{ length_or_height }.",
        "learnMoreId": 16,
    },
    13: {
        "contentId": 13,
        "i18n_line": $gettext("Weight gain appears to be normal for the child's %{ length_or_height }."),
        "line": "Weight gain appears to be normal for the child's %{ length_or_height }.",
        "learnMoreId": 16,
    },
    14: {
        "contentId": 14,
        "i18n_line": $gettext("Child has gained weight more quickly than expected for %{ length_or_height }."),
        "line": "Child has gained weight more quickly than expected for %{ length_or_height }.",
    },
    15: {
        "contentId": 15,
        "i18n_line": $gettext("Rapid weight gain could be caused by a growth spurt or overfeeding."),
        "line": "Rapid weight gain could be caused by a growth spurt or overfeeding.",
        "learnMoreId": 11,
    },
    16: {
        "contentId": 16,
        "i18n_line": $gettext("Child has not gained enough weight relative to %{ length_or_height } since %{ last_assessment_date }."),
        "line": "Child has not gained enough weight relative to %{ length_or_height } since %{ last_assessment_date }.",
    },
    17: {
        "contentId": 17,
        "i18n_line": $gettext("Child has lost weight since %{ last_assessment_date }."),
        "line": "Child has lost weight since %{ last_assessment_date }.",
    },
    18: {
        "contentId": 18,
        "i18n_line": $gettext("Slow weight gain could be caused by poor food intake or an illness."),
        "line": "Slow weight gain could be caused by poor food intake or an illness.",
        "learnMoreId": 12,
    },
    19: {
        "contentId": 19,
        "i18n_line": $gettext("Weight loss could be caused by poor food intake or an illness."),
        "line": "Weight loss could be caused by poor food intake or an illness.",
        "learnMoreId": 12,
    },
    20: {
        "contentId": 20,
        "i18n_line": $gettext("Current weight appears to be low for the child's %{ length_or_height }."),
        "line": "Current weight appears to be low for the child's %{ length_or_height }.",
        "learnMoreId": 22,
    },
    21: {
        "contentId": 21,
        "i18n_line": $gettext("Low weight for %{ length_or_height } could be caused by poor food intake, an illness, premature birth, low birth weight, or a normal tendency to be small."),
        "line": "Low weight for %{ length_or_height } could be caused by poor food intake, an illness, premature birth, low birth weight, or a normal tendency to be small.",
    },
    22: {
        "contentId": 22,
        "i18n_line": $gettext("While the current weight is low, child appears to be gaining weight at a normal rate relative to %{ length_or_height }."),
        "line": "While the current weight is low, child appears to be gaining weight at a normal rate relative to %{ length_or_height }.",
    },
    23: {
        "contentId": 23,
        "i18n_line": $gettext("Child may be experiencing a growth spurt or catch-up growth."),
        "line": "Child may be experiencing a growth spurt or catch-up growth.",
        "learnMoreId": 23,
    },
    24: {
        "contentId": 24,
        "i18n_line": $gettext("Current weight appears to be high for the child's %{ length_or_height }."),
        "line": "Current weight appears to be high for the child's %{ length_or_height }.",
        "learnMoreId": 24,
    },
    25: {
        "contentId": 25,
        "i18n_line": $gettext("High weight for %{ length_or_height } could be caused by overfeeding or a normal tendency to be big."),
        "line": "High weight for %{ length_or_height } could be caused by overfeeding or a normal tendency to be big.",
    },
    26: {
        "contentId": 26,
        "i18n_line": $gettext("While the current weight is high, child appears to be gaining weight at a normal rate relative to %{ length_or_height }."),
        "line": "While the current weight is high, child appears to be gaining weight at a normal rate relative to %{ length_or_height }.",
    },
    27: {
        "contentId": 27,
        "i18n_line": $gettext("Child is at risk of becoming overweight or obese."),
        "line": "Child is at risk of becoming overweight or obese.",
        "learnMoreId": 25,
    },
    28: {
        "contentId": 28,
        "i18n_line": $gettext("Child's weight could be stabilizing. Other reasons for slow weight gain could include poor food intake or an illness."),
        "line": "Child's weight could be stabilizing. Other reasons for slow weight gain could include poor food intake or an illness.",
        "learnMoreId": 26,
    },
    29: {
        "contentId": 29,
        "i18n_line": $gettext("Child's weight could be stabilizing. Other reasons for weight loss could include poor food intake or an illness."),
        "line": "Child's weight could be stabilizing. Other reasons for weight loss could include poor food intake or an illness.",
        "learnMoreId": 26,
    },
    30: {
        "contentId": 30,
        "i18n_line": $gettext("Current %{ length_or_height } is normal for the child's age."),
        "line": "Current %{ length_or_height } is normal for the child's age.",
        "learnMoreId": 27,
    },
    31: {
        "contentId": 31,
        "i18n_line": $gettext("Child appears to be growing normally in %{ length_or_height } for age."),
        "line": "Child appears to be growing normally in %{ length_or_height } for age.",
        "learnMoreId": 27,
    },
    32: {
        "contentId": 32,
        "i18n_line": $gettext("Child's %{ length_or_height } appears to have increased rapidly since %{ last_assessment_date }."),
        "line": "Child's %{ length_or_height } appears to have increased rapidly since %{ last_assessment_date }.",
    },
    33: {
        "contentId": 33,
        "i18n_line": $gettext("Growth in %{ length_or_height } has slowed down since %{ last_assessment_date }."),
        "line": "Growth in %{ length_or_height } has slowed down since %{ last_assessment_date }.",
    },
    34: {
        "contentId": 34,
        "i18n_line": $gettext("Child appears to be short for age."),
        "line": "Child appears to be short for age.",
        "learnMoreId": 28,
    },
    35: {
        "contentId": 35,
        "i18n_line": $gettext("While short for age, child appears to be growing normally relative to age."),
        "line": "While short for age, child appears to be growing normally relative to age.",
        "learnMoreId": 28,
    },
    37: {
        "contentId": 37,
        "i18n_line": $gettext("Slow growth in %{ length_or_height } could be due to a long period of poor food intake or repeated illness."),
        "line": "Slow growth in %{ length_or_height } could be due to a long period of poor food intake or repeated illness.",
        "learnMoreId": 21,
    },
    38: {
        "contentId": 38,
        "i18n_line": $gettext("Child is at a healthy weight for height and age."),
        "line": "Child is at a healthy weight for height and age.",
        "learnMoreId": 31,
    },
    39: {
        "contentId": 39,
        "i18n_line": $gettext("Weight gain appears to be normal for the child's height and age."),
        "line": "Weight gain appears to be normal for the child's height and age.",
        "learnMoreId": 31,
    },
    40: {
        "contentId": 40,
        "i18n_line": $gettext("Child has gained weight more quickly than expected for height and age."),
        "line": "Child has gained weight more quickly than expected for height and age.",
    },
    41: {
        "contentId": 41,
        "i18n_line": $gettext("Rapid weight gain could be caused by excessive food intake, lack of physical activity, or certain medications."),
        "line": "Rapid weight gain could be caused by excessive food intake, lack of physical activity, or certain medications.",
    },
    42: {
        "contentId": 42,
        "i18n_line": $gettext("Child's weight gain has slowed down relative to height and age since %{ last_assessment_date }."),
        "line": "Child's weight gain has slowed down relative to height and age since %{ last_assessment_date }.",
    },
    43: {
        "contentId": 43,
        "i18n_line": $gettext("Child appears to be thin."),
        "line": "Child appears to be thin.",
    },
    44: {
        "contentId": 44,
        "i18n_line": $gettext("While thin, the child appears to be gaining weight at a normal rate relative to height and age."),
        "line": "While thin, the child appears to be gaining weight at a normal rate relative to height and age.",
    },
    45: {
        "contentId": 45,
        "i18n_line": $gettext("At this current BMI, child is considered to be overweight."),
        "line": "At this current BMI, child is considered to be overweight.",
        "learnMoreId": 25,
    },
    46: {
        "contentId": 46,
        "i18n_line": $gettext("While overweight, the child appears to be gaining weight at a normal rate relative to height and age."),
        "line": "While overweight, the child appears to be gaining weight at a normal rate relative to height and age.",
    },
    47: {
        "contentId": 47,
        "i18n_line": $gettext("Weight gain requires close monitoring."),
        "line": "Weight gain requires close monitoring.",
        "learnMoreId": 25,
    },
    48: {
        "contentId": 48,
        "i18n_line": $gettext("At this current BMI, child is considered to be obese."),
        "line": "At this current BMI, child is considered to be obese.",
        "learnMoreId": 25,
    },
    49: {
        "contentId": 49,
        "i18n_line": $gettext("High BMI could be caused by excessive food intake, lack of physical activity, certain medications, and rarely, a growth disorder."),
        "line": "High BMI could be caused by excessive food intake, lack of physical activity, certain medications, and rarely, a growth disorder.",
        "learnMoreId": 24,
    },
    50: {
        "contentId": 50,
        "i18n_line": $gettext("However, child is considered at an unhealthy weight. Weight gain requires close monitoring."),
        "line": "However, child is considered at an unhealthy weight. Weight gain requires close monitoring.",
        "learnMoreId": 25,
    },
    65: {
        "contentId": 65,
        "i18n_line": $gettext("Thinness could be caused by poor food intake, an illness, or a normal tendency to be small."),
        "line": "Thinness could be caused by poor food intake, an illness, or a normal tendency to be small.",
        "learnMoreId": 12,
    },
    51: {
        "contentId": 51,
        "i18n_line": $gettext("Current height is normal for the child's age."),
        "line": "Current height is normal for the child's age.",
        "learnMoreId": 31,
    },
    52: {
        "contentId": 52,
        "i18n_line": $gettext("Child appears to be growing normally in height."),
        "line": "Child appears to be growing normally in height.",
        "learnMoreId": 31,
    },
    53: {
        "contentId": 53,
        "i18n_line": $gettext("Child's height appears to have increased rapidly since %{ last_assessment_date }."),
        "line": "Child's height appears to have increased rapidly since %{ last_assessment_date }.",
    },
    54: {
        "contentId": 54,
        "i18n_line": $gettext("Growth in height has slowed down since %{ last_assessment_date }."),
        "line": "Growth in height has slowed down since %{ last_assessment_date }.",
    },
    55: {
        "contentId": 55,
        "i18n_line": $gettext("A rapid increase in height could be due to a growth spurt."),
        "line": "A rapid increase in height could be due to a growth spurt.",
        "learnMoreId": 30,
    },
    56: {
        "contentId": 56,
        "i18n_line": $gettext("Slow growth in height could be due to a long period of poor food intake, a chronic illness, or a growth disorder."),
        "line": "Slow growth in height could be due to a long period of poor food intake, a chronic illness, or a growth disorder.",
        "learnMoreId": 21,
    },
    57: {
        "contentId": 57,
        "i18n_line": $gettext("Current weight is normal for the child's age."),
        "line": "Current weight is normal for the child's age.",
        "learnMoreId": 16,
    },
    58: {
        "contentId": 58,
        "i18n_line": $gettext("Weight gain appears to be normal for the child's age."),
        "line": "Weight gain appears to be normal for the child's age.",
        "learnMoreId": 16,
    },
    59: {
        "contentId": 59,
        "i18n_line": $gettext("Child has gained weight more quickly than expected for age."),
        "line": "Child has gained weight more quickly than expected for age.",
    },
    60: {
        "contentId": 60,
        "i18n_line": $gettext("Rapid weight gain could be caused by a growth spurt or overfeeding."),
        "line": "Rapid weight gain could be caused by a growth spurt or overfeeding.",
        "learnMoreId": 11,
    },
    61: {
        "contentId": 61,
        "i18n_line": $gettext("Child has not gained enough weight relative to age since %{ last_assessment_date }."),
        "line": "Child has not gained enough weight relative to age since %{ last_assessment_date }.",
    },
    62: {
        "contentId": 62,
        "i18n_line": $gettext("Current weight appears to be low for the child's age."),
        "line": "Current weight appears to be low for the child's age.",
        "learnMoreId": 32,
    },
    63: {
        "contentId": 63,
        "i18n_line": $gettext("Low weight for age could be caused by poor food intake, an illness, premature birth, low birth weight, or a normal tendency to be small."),
        "line": "Low weight for age could be caused by poor food intake, an illness, premature birth, low birth weight, or a normal tendency to be small.",
    },
    64: {
        "contentId": 64,
        "i18n_line": $gettext("While the current weight is low, child appears to be gaining weight at a normal rate relative to age."),
        "line": "While the current weight is low, child appears to be gaining weight at a normal rate relative to age.",
    },
    66: {
        "contentId": 66,
        "i18n_line": $gettext("Current arm size is normal for the child's age."),
        "line": "Current arm size is normal for the child's age.",
        "learnMoreId": 33,
    },
    67: {
        "contentId": 67,
        "i18n_line": $gettext("Child appears to be thin."),
        "line": "Child appears to be thin.",
        "learnMoreId": 34,
    },
    68: {
        "contentId": 68,
        "i18n_line": $gettext("Child appears to be extremely thin."),
        "line": "Child appears to be extremely thin.",
        "learnMoreId": 34,
    },
    69: {
        "contentId": 69,
        "i18n_line": $gettext("Thinness could be caused by poor food intake, repeated illness, or a combination of the two."),
        "line": "Thinness could be caused by poor food intake, repeated illness, or a combination of the two.",
        "learnMoreId": 12,
    },
}

export const RECOMMENDATIONS = {
    2: {
        "contentId": 2,
        "i18n_line": $gettext("Check head size measurement, and if necessary, measure again."),
        "line": "Check head size measurement, and if necessary, measure again.",
        "learnMoreId": 4,
    },
    3: {
        "contentId": 3,
        "i18n_line": $gettext("If head size measurement is correct, compare head size chart to other growth charts. Is head growing at a faster rate than the body? If yes, refer to pediatrician for further evaluation."),
        "line": "If head size measurement is correct, compare head size chart to other growth charts. Is head growing at a faster rate than the body? If yes, refer to pediatrician for further evaluation.",
        "learnMoreId": 7,
        "isReferral": true,
    },
    4: {
        "contentId": 4,
        "i18n_line": $gettext("If head size measurement is correct, compare head size chart to other growth charts. Is the head growing at a slower rate than the body? If yes, refer to pediatrician for further evaluation."),
        "line": "If head size measurement is correct, compare head size chart to other growth charts. Is the head growing at a slower rate than the body? If yes, refer to pediatrician for further evaluation.",
        "learnMoreId": 5,
        "isReferral": true,
    },
    6: {
        "contentId": 6,
        "i18n_line": $gettext("Due to risk for developmental delays, child may benefit from early intervention services. Refer to pediatrician immediately for further evaluation."),
        "line": "Due to risk for developmental delays, child may benefit from early intervention services. Refer to pediatrician immediately for further evaluation.",
        "learnMoreId": 8,
        "isReferral": true,
    },
    7: {
        "contentId": 7,
        "i18n_line": $gettext("Compare head size chart to %{ length_or_height } growth charts. Is the child's head size in proportion to the body? If not, refer to pediatrician for further evaluation."),
        "line": "Compare head size chart to %{ length_or_height } growth charts. Is the child's head size in proportion to the body? If not, refer to pediatrician for further evaluation.",
        "learnMoreId": 10,
        "isReferral": true,
    },
    8: {
        "contentId": 8,
        "i18n_line": $gettext("If head size measurement is correct, refer to pediatrician immediately for further evaluation."),
        "line": "If head size measurement is correct, refer to pediatrician immediately for further evaluation.",
        "isReferral": true,
    },
    10: {
        "contentId": 10,
        "i18n_line": $gettext("Complete Mealtime assessment to identify and address feeding difficulties as needed."),
        "line": "Complete Mealtime assessment to identify and address feeding difficulties as needed.",
    },
    11: {
        "contentId": 11,
        "i18n_line": $gettext("Follow Mealtime recommendations."),
        "line": "Follow Mealtime recommendations.",
    },
    12: {
        "contentId": 12,
        "i18n_line": $gettext("Refer to pediatrician for further evaluation if you suspect that a health problem is causing rapid weight gain."),
        "line": "Refer to pediatrician for further evaluation if you suspect that a health problem is causing rapid weight gain.",
        "isReferral": true,
    },
    14: {
        "contentId": 14,
        "i18n_line": $gettext("Refer to pediatrician for further evaluation if child has had any illnesses that might have slowed weight gain."),
        "line": "Refer to pediatrician for further evaluation if child has had any illnesses that might have slowed weight gain.",
        "isReferral": true,
    },
    16: {
        "contentId": 16,
        "i18n_line": $gettext("Refer to pediatrician for further evaluation if child has had any illnesses that might have caused the weight loss."),
        "line": "Refer to pediatrician for further evaluation if child has had any illnesses that might have caused the weight loss.",
        "isReferral": true,
    },
    17: {
        "contentId": 17,
        "i18n_line": $gettext("Growth needs to be monitored closely. A follow-up assessment has been scheduled for %{ follow_up_date }."),
        "line": "Growth needs to be monitored closely. A follow-up assessment has been scheduled for %{ follow_up_date }.",
        "learnMoreId": 18,
    },
    21: {
        "contentId": 21,
        "i18n_line": $gettext("Refer to pediatrician immediately for further evaluation of slow weight gain."),
        "line": "Refer to pediatrician immediately for further evaluation of slow weight gain.",
        "isReferral": true,
    },
    22: {
        "contentId": 22,
        "i18n_line": $gettext("Refer to pediatrician immediately for further evaluation of weight loss."),
        "line": "Refer to pediatrician immediately for further evaluation of weight loss.",
        "isReferral": true,
    },
    24: {
        "contentId": 24,
        "i18n_line": $gettext("Check %{ length_or_height } measurement, and if necessary, measure again."),
        "line": "Check %{ length_or_height } measurement, and if necessary, measure again.",
        "learnMoreId": 20,
    },
    26: {
        "contentId": 26,
        "i18n_line": $gettext("Refer to pediatrician for further evaluation if child has had any chronic illnesses that might have slowed down growth in %{ length_or_height }."),
        "line": "Refer to pediatrician for further evaluation if child has had any chronic illnesses that might have slowed down growth in %{ length_or_height }.",
        "isReferral": true,
    },
    27: {
        "contentId": 27,
        "i18n_line": $gettext("If %{ length_or_height } measurement is correct, refer to pediatrician for further evaluation."),
        "line": "If %{ length_or_height } measurement is correct, refer to pediatrician for further evaluation.",
        "isReferral": true,
    },
    28: {
        "contentId": 28,
        "i18n_line": $gettext("Encourage healthy weight gain with adequate nutrition and regular physical activity."),
        "line": "Encourage healthy weight gain with adequate nutrition and regular physical activity.",
        "learnMoreId": 29,
    },
    30: {
        "contentId": 30,
        "i18n_line": $gettext("Check height measurement, and if necessary, measure again."),
        "line": "Check height measurement, and if necessary, measure again.",
        "learnMoreId": 20,
    },
    32: {
        "contentId": 32,
        "i18n_line": $gettext("Refer to pediatrician for further evaluation if child has had any chronic illnesses that might have slowed down growth in height."),
        "line": "Refer to pediatrician for further evaluation if child has had any chronic illnesses that might have slowed down growth in height.",
        "isReferral": true,
    },
    33: {
        "contentId": 33,
        "i18n_line": $gettext("If height measurement is correct, refer to pediatrician for further evaluation."),
        "line": "If height measurement is correct, refer to pediatrician for further evaluation.",
        "isReferral": true,
    },
    35: {
        "contentId": 35,
        "i18n_line": $gettext("Refer to pediatrician immediately for further evaluation and management of malnutrition."),
        "line": "Refer to pediatrician immediately for further evaluation and management of malnutrition.",
        "learnMoreId": 36,
        "isReferral": true,
    },
    36: {
        "contentId": 36,
        "i18n_line": $gettext("Make sure the infant is receiving an adequate amount of commercial infant formula and complementary foods."),
        "line": "Make sure the infant is receiving an adequate amount of commercial infant formula and complementary foods.",
        "i18n_com_rec": $gettext("Continue breastfeeding for up to age 24 months and beyond alongside suitable complementary foods."),
        "comRec": "Continue breastfeeding for up to age 24 months and beyond alongside suitable complementary foods.",
        "learnMoreId": 37,
    },
    37: {
        "contentId": 37,
        "i18n_line": $gettext("Make sure the infant is receiving an adequate amount of commercial infant formula."),
        "line": "Make sure the infant is receiving an adequate amount of commercial infant formula.",
        "i18n_com_rec": $gettext("Breastfeed exclusively until age 6 months."),
        "comRec": "Breastfeed exclusively until age 6 months.",
        "learnMoreId": 37,
    },
    38: {
        "contentId": 38,
        "i18n_line": $gettext("Offer the child foods that are nutrient-dense and age-appropriate. Prepare foods in a texture that is safe for the child to eat."),
        "line": "Offer the child foods that are nutrient-dense and age-appropriate. Prepare foods in a texture that is safe for the child to eat.",
        "learnMoreId": 38,
    },
    39: {
        "contentId": 39,
        "i18n_line": $gettext("If nutrients are not sufficiently available through local foods, offer specially formulated foods to supplement the regular diet."),
        "line": "If nutrients are not sufficiently available through local foods, offer specially formulated foods to supplement the regular diet.",
        "learnMoreId": 39,
    },
    40: {
        "contentId": 40,
        "i18n_line": $gettext("Infant appears to be growing well! Continue proper feeding with an adequate amount of commercial infant formula."),
        "line": "Infant appears to be growing well! Continue proper feeding with an adequate amount of commercial infant formula.",
        "i18n_com_rec": $gettext("Infant appears to be growing well! Breastfeed exclusively until age 6 months."),
        "comRec": "Infant appears to be growing well! Breastfeed exclusively until age 6 months.",
        "learnMoreId": 37,
    },
    41: {
        "contentId": 41,
        "i18n_line": $gettext("Infant appears to be growing well! Continue proper feeding with adequate amounts of commercial infant formula and nutrient-dense complementary foods."),
        "line": "Infant appears to be growing well! Continue proper feeding with adequate amounts of commercial infant formula and nutrient-dense complementary foods.",
        "i18n_com_rec": $gettext("Infant appears to be growing well! Continue breastfeeding for up to age 24 months and beyond alongside nutrient-dense complementary foods."),
        "comRec": "Infant appears to be growing well! Continue breastfeeding for up to age 24 months and beyond alongside nutrient-dense complementary foods.",
        "learnMoreId": 37,
    },
    42: {
        "contentId": 42,
        "i18n_line": $gettext("Child appears to be growing well! Continue proper feeding with nutrient-dense foods. Prepare foods in a texture that is safe for the child to eat."),
        "line": "Child appears to be growing well! Continue proper feeding with nutrient-dense foods. Prepare foods in a texture that is safe for the child to eat.",
        "learnMoreId": 38,
    },
    43: {
        "contentId": 43,
        "i18n_line": $gettext("A follow-up assessment has been scheduled for %{ follow_up_date }."),
        "line": "A follow-up assessment has been scheduled for %{ follow_up_date }.",
    },
    44: {
        "contentId": 44,
        "i18n_line": $gettext("Feed more often than usual, if tolerated, until the infant is growing well again."),
        "line": "Feed more often than usual, if tolerated, until the infant is growing well again.",
    },
    45: {
        "contentId": 45,
        "i18n_line": $gettext("Give food more often than usual, if tolerated, until the child is gowing well again."),
        "line": "Give food more often than usual, if tolerated, until the child is gowing well again.",
    },
}

export const LEARN_MORE = {
    1: {
        "contentId": 1,
        "i18n_line": $gettext("The greatest brain growth happens between the age of 0 and 5 years. The growth of the skull is determined by brain growth. That is why measuring head size is a quick and simple step in determining if the child's brain is growing and developing normally."),
        "line": "The greatest brain growth happens between the age of 0 and 5 years. The growth of the skull is determined by brain growth. That is why measuring head size is a quick and simple step in determining if the child's brain is growing and developing normally.",
    },
    2: {
        "contentId": 2,
        "i18n_line": $gettext("Multiple measurements over time provide more valuable information than a single measurement. They help screen a child for possible cognitive or motor delays and identify abnormal growth patterns and treatable conditions such as hydrocephalus (extra fluid surrounding the brain)."),
        "line": "Multiple measurements over time provide more valuable information than a single measurement. They help screen a child for possible cognitive or motor delays and identify abnormal growth patterns and treatable conditions such as hydrocephalus (extra fluid surrounding the brain).",
    },
    3: {
        "contentId": 3,
        "i18n_line": $gettext("A sharp increase in head size can be caused by a medical condition, a build-up of fluid around the brain (hydrocephalus), or if a child is experiencing catch up growth. It can also be due to an error in measurement."),
        "line": "A sharp increase in head size can be caused by a medical condition, a build-up of fluid around the brain (hydrocephalus), or if a child is experiencing catch up growth. It can also be due to an error in measurement.",
    },
    4: {
        "contentId": 4,
        "i18n_line": $gettext("Correct measurements offer important information about the child's growth. To obtain an accurate measurement of head size, place a nonelastic tape measure around the child's head just above the eyebrows and ears and around the most prominent portion of the back of the head. Pull the tape snugly to compress the hair and skin. Read measurement to the nearest 0.1 cm (e.g. 44.3 cm)."),
        "line": "Correct measurements offer important information about the child's growth. To obtain an accurate measurement of head size, place a nonelastic tape measure around the child's head just above the eyebrows and ears and around the most prominent portion of the back of the head. Pull the tape snugly to compress the hair and skin. Read measurement to the nearest 0.1 cm (e.g. 44.3 cm).",
    },
    5: {
        "contentId": 5,
        "i18n_line": $gettext("Head size is expected to grow in proportion to the child's body. For example, if a child's length or height is in the area between the (-1) and (-2) growth lines, head size should also be in or near that area. A head size too small may be expected with children who are very short for their age. If the child is not taking in enough calories or unable to utilize them, you are more likely to notice a drop in weight and length/height before noticing a slow growth in head size."),
        "line": "Head size is expected to grow in proportion to the child's body. For example, if a child's length or height is in the area between the (-1) and (-2) growth lines, head size should also be in or near that area. A head size too small may be expected with children who are very short for their age. If the child is not taking in enough calories or unable to utilize them, you are more likely to notice a drop in weight and length/height before noticing a slow growth in head size.",
    },
    6: {
        "contentId": 6,
        "i18n_line": $gettext("A head size too small for age is called microcephaly. It can place infants and young children at risk for delayed motor and cognitive development. Infants who are low birth weight or are born premature will likely have a smaller than average head size until they catch up. Certain conditions that affect brain growth can also cause smaller than normal head size.These include disabilities (e.g. cerebral palsy), genetic disorders (e.g. Down syndrome), infections (e.g. Zika virus during pregnancy), and severe malnutrition. Infants and young children with microcephaly may benefit from early intervention services such as physical, occupational, and speech therapy."),
        "line": "A head size too small for age is called microcephaly. It can place infants and young children at risk for delayed motor and cognitive development. Infants who are low birth weight or are born premature will likely have a smaller than average head size until they catch up. Certain conditions that affect brain growth can also cause smaller than normal head size.These include disabilities (e.g. cerebral palsy), genetic disorders (e.g. Down syndrome), infections (e.g. Zika virus during pregnancy), and severe malnutrition. Infants and young children with microcephaly may benefit from early intervention services such as physical, occupational, and speech therapy.",
    },
    7: {
        "contentId": 7,
        "i18n_line": $gettext("Head size is expected to grow in proportion to the child's body. For example, if a child's length or height is in the area between the (-1) and (-2) growth lines, head size should also be in or near that area. A sharp increase in head size should be evaluated to determine if it is due to an abnormal growth pattern that needs to be tested further."),
        "line": "Head size is expected to grow in proportion to the child's body. For example, if a child's length or height is in the area between the (-1) and (-2) growth lines, head size should also be in or near that area. A sharp increase in head size should be evaluated to determine if it is due to an abnormal growth pattern that needs to be tested further.",
    },
    8: {
        "contentId": 8,
        "i18n_line": $gettext("Some children with small head size (microcephaly) may require early intervention services to improve and maximize their physical and intellectual abilities. These services can include speech, physical, and occupational therapies."),
        "line": "Some children with small head size (microcephaly) may require early intervention services to improve and maximize their physical and intellectual abilities. These services can include speech, physical, and occupational therapies.",
    },
    9: {
        "contentId": 9,
        "i18n_line": $gettext("A head size too large is called macrocephaly. It can be caused by extra fluid surrounding the brain (hydrocephalus), other medical conditions, abnormal head shape, or a family tendency toward large head size. Multiple measurements over time provide more valuable information than a single measurement. They help screen a child for possible cognitive or motor delays and identify abnormal growth patterns and treatable conditions such as hydrocephalus."),
        "line": "A head size too large is called macrocephaly. It can be caused by extra fluid surrounding the brain (hydrocephalus), other medical conditions, abnormal head shape, or a family tendency toward large head size. Multiple measurements over time provide more valuable information than a single measurement. They help screen a child for possible cognitive or motor delays and identify abnormal growth patterns and treatable conditions such as hydrocephalus.",
    },
    10: {
        "contentId": 10,
        "i18n_line": $gettext("Head size is expected to grow in proportion to the child's body. For example, if a child's length or height is in the area between the (-1) and (-2) growth lines, head size should also be in or near that area. Often, repeated measurements of head size over time are needed to confirm if there is an abnormal growth pattern. But in some cases, a single measurement is enough to indicate a need for further evaluation."),
        "line": "Head size is expected to grow in proportion to the child's body. For example, if a child's length or height is in the area between the (-1) and (-2) growth lines, head size should also be in or near that area. Often, repeated measurements of head size over time are needed to confirm if there is an abnormal growth pattern. But in some cases, a single measurement is enough to indicate a need for further evaluation.",
    },
    11: {
        "contentId": 11,
        "i18n_line": $gettext("A child who is measuring very long or tall and is gaining weight rapidly could be experiencing a growth spurt or catch-up growth. This could also happen when a child is overfed (too much and/or too often). It is important to understand the child's eating habits, hunger cues, and satiety cues to avoid overfeeding or restricting food intake."),
        "line": "A child who is measuring very long or tall and is gaining weight rapidly could be experiencing a growth spurt or catch-up growth. This could also happen when a child is overfed (too much and/or too often). It is important to understand the child's eating habits, hunger cues, and satiety cues to avoid overfeeding or restricting food intake.",
    },
    12: {
        "contentId": 12,
        "i18n_line": $gettext("When children do not eat enough or the right types of food, they do not take in enough calories, vitamins, and minerals their bodies need. Illnesses, like diarrhea and vomiting, can cause a major loss of nutrients. Inflammation or infection of the gut can interrupt healthy digestion and absorption of nutrients from foods. Some medications can interfere with the way children's bodies absorb important vitamins and minerals."),
        "line": "When children do not eat enough or the right types of food, they do not take in enough calories, vitamins, and minerals their bodies need. Illnesses, like diarrhea and vomiting, can cause a major loss of nutrients. Inflammation or infection of the gut can interrupt healthy digestion and absorption of nutrients from foods. Some medications can interfere with the way children's bodies absorb important vitamins and minerals.",
    },
    16: {
        "contentId": 16,
        "i18n_line": $gettext("Normal growth happens when a child receives adequate nutrition, care, and social stimulation. Most infants double their birth weight between 4 and 6 months and triple it by 1 year old. Things start slowing down between 1 and 5 years old because at that age children are more active and do not eat as often. There is a wide array of normal when it comes to a child's weight. That's why it is more important to look at the growth pattern over time than just a single weight value."),
        "line": "Normal growth happens when a child receives adequate nutrition, care, and social stimulation. Most infants double their birth weight between 4 and 6 months and triple it by 1 year old. Things start slowing down between 1 and 5 years old because at that age children are more active and do not eat as often. There is a wide array of normal when it comes to a child's weight. That's why it is more important to look at the growth pattern over time than just a single weight value.",
    },
    18: {
        "contentId": 18,
        "i18n_line": $gettext("Poor growth can be the first sign of a problem in some children. That's why frequent monitoring is important when you suspect abnormal growth. It establishes a growth pattern over time. It shows you whether the child's growth is returning to normal after treatment. And it helps you decide if a referral to a pediatrician or a specialist is needed."),
        "line": "Poor growth can be the first sign of a problem in some children. That's why frequent monitoring is important when you suspect abnormal growth. It establishes a growth pattern over time. It shows you whether the child's growth is returning to normal after treatment. And it helps you decide if a referral to a pediatrician or a specialist is needed.",
    },
    20: {
        "contentId": 20,
        "i18n_line": $gettext("Correct measurements offer important information about the child's growth. To obtain an accurate measurement of %{ length_or_height }, use appropriate equipment (not a measuring tape). Make sure the line of sight of the child is perpendicular to the floor for length or parallel to the floor for height. Make sure the head and foot pieces are pressing firmly against the head and feet. Read measurement to the nearest 0.1 cm (e.g. 99.7 cm)."),
        "line": "Correct measurements offer important information about the child's growth. To obtain an accurate measurement of %{ length_or_height }, use appropriate equipment (not a measuring tape). Make sure the line of sight of the child is perpendicular to the floor for length or parallel to the floor for height. Make sure the head and foot pieces are pressing firmly against the head and feet. Read measurement to the nearest 0.1 cm (e.g. 99.7 cm).",
    },
    21: {
        "contentId": 21,
        "i18n_line": $gettext("Childen who are not gaining %{ length_or_height } (or is gaining very slowly) may not be receiving sufficient calories or their bodies cannot use the calories they needs to grow. Other causes may include a chronic illness or infection and lack of social stimulation."),
        "line": "Childen who are not gaining %{ length_or_height } (or is gaining very slowly) may not be receiving sufficient calories or their bodies cannot use the calories they needs to grow. Other causes may include a chronic illness or infection and lack of social stimulation.",
    },
    22: {
        "contentId": 22,
        "i18n_line": $gettext("A low weight-for-height is called wasting. A low weight is a concern when the child is not receiving adequate nutrition due to poor appetite or difficulty with feeding or has been experiencing an illness (e.g. diarrhea). The child’s size also depends on genetics - meaning if the parents have a small frame and are slender, their child more likely will too. Multiple measurements over time will help you determine if the child is growing at a normal rate even when their weight is low in proportion to their %{ length_or_height }."),
        "line": "A low weight-for-height is called wasting. A low weight is a concern when the child is not receiving adequate nutrition due to poor appetite or difficulty with feeding or has been experiencing an illness (e.g. diarrhea). The child’s size also depends on genetics - meaning if the parents have a small frame and are slender, their child more likely will too. Multiple measurements over time will help you determine if the child is growing at a normal rate even when their weight is low in proportion to their %{ length_or_height }.",
    },
    23: {
        "contentId": 23,
        "i18n_line": $gettext("Catch-up growth refers to the rapid growth that a child experiences after a period of slow growth. For example, during periods of poor nutrition or severe illness like prolonged diarrhea, a child may experience slow growth but will likely catch up after the nutrition improves or the illness is treated. Growth spurts refer to times when rapid growth in weight and %{ length_or_height } happens in short intense bursts of what can seem like sharp increases. This normally happens during infancy and adolescence. The duration of a growth spurt, as well as how much a child grows during one, differs from child to child. Generally, weeks or months of slightly slower growth alternate with mini \"growth spurts\" in most children.That's why children can sometimes look almost \"chubby\" one month but lean the next. It is important to support the child's increased nutrition needs during catch-up growth or a growth spurt. This is best accomplished by understanding and being responsive to the child's hunger and satiety cues and by not overfeeding or restricting food intake."),
        "line": "Catch-up growth refers to the rapid growth that a child experiences after a period of slow growth. For example, during periods of poor nutrition or severe illness like prolonged diarrhea, a child may experience slow growth but will likely catch up after the nutrition improves or the illness is treated. Growth spurts refer to times when rapid growth in weight and %{ length_or_height } happens in short intense bursts of what can seem like sharp increases. This normally happens during infancy and adolescence. The duration of a growth spurt, as well as how much a child grows during one, differs from child to child. Generally, weeks or months of slightly slower growth alternate with mini \"growth spurts\" in most children.That's why children can sometimes look almost \"chubby\" one month but lean the next. It is important to support the child's increased nutrition needs during catch-up growth or a growth spurt. This is best accomplished by understanding and being responsive to the child's hunger and satiety cues and by not overfeeding or restricting food intake.",
    },
    24: {
        "contentId": 24,
        "i18n_line": $gettext("Much of the child’s size depends on genetics - meaning if the parents have a large frame and a tendency to be heavier, their child more likely will too. A high weight is a concern if it is due to overfeeding, lack of physical activity, a growth disorder, or certain medications. Multiple measurements over time will help you determine if a child is growing at a normal rate even when their weight is high in proportion to their %{ length_or_height }. It is important to understand the child's eating habits, hunger cues, and satiety cues to avoid overfeeding or restricting food intake."),
        "line": "Much of the child’s size depends on genetics - meaning if the parents have a large frame and a tendency to be heavier, their child more likely will too. A high weight is a concern if it is due to overfeeding, lack of physical activity, a growth disorder, or certain medications. Multiple measurements over time will help you determine if a child is growing at a normal rate even when their weight is high in proportion to their %{ length_or_height }. It is important to understand the child's eating habits, hunger cues, and satiety cues to avoid overfeeding or restricting food intake.",
    },
    25: {
        "contentId": 25,
        "i18n_line": $gettext("Being overweight or obese increases a child's risk for a number of diseases and conditions, including: asthma, trouble sleeping, menstrual problems for girls, and being overweight and having chronic diseases as an adult."),
        "line": "Being overweight or obese increases a child's risk for a number of diseases and conditions, including: asthma, trouble sleeping, menstrual problems for girls, and being overweight and having chronic diseases as an adult.",
    },
    26: {
        "contentId": 26,
        "i18n_line": $gettext("When children are supported with proper nutrition and feeding, they grow to the size that is appropriate for them. It is important to understand the child's eating habits, hunger cues, and satiety cues to avoid overfeeding or restricting food intake."),
        "line": "When children are supported with proper nutrition and feeding, they grow to the size that is appropriate for them. It is important to understand the child's eating habits, hunger cues, and satiety cues to avoid overfeeding or restricting food intake.",
    },
    27: {
        "contentId": 27,
        "i18n_line": $gettext("Children grow most rapidly during their first year of life - 25 cm in length on average. Growth in length slows down after age 1. From age 2 until adolescence, children usually grow about 6 cm per year. Adequate nutrition, good feeding practices, proper care, and social stimulation help children grow at a healthy rate and reach their potential for height."),
        "line": "Children grow most rapidly during their first year of life - 25 cm in length on average. Growth in length slows down after age 1. From age 2 until adolescence, children usually grow about 6 cm per year. Adequate nutrition, good feeding practices, proper care, and social stimulation help children grow at a healthy rate and reach their potential for height.",
    },
    28: {
        "contentId": 28,
        "i18n_line": $gettext("A child’s %{ length_or_height } depends on genetics - meaning if the parents are short, their child will likely be too. Infants who are low birth weight or are born prematurely will likely be smaller than average until they catch up. Being short for age (also called stunting) is concerning if the child has experienced long-term poor nutrition, an illness or infection, and/or inappropriate care and stimulation from adults. When they receive adequate nutrition and care, stunted children (especially if younger than 2 years) are generally able to regain some lost growth potential and \"catch-up\" in %{ length_or_height }. Multiple measurements over time will help you determine if a child is catching up or growing at a normal rate."),
        "line": "A child’s %{ length_or_height } depends on genetics - meaning if the parents are short, their child will likely be too. Infants who are low birth weight or are born prematurely will likely be smaller than average until they catch up. Being short for age (also called stunting) is concerning if the child has experienced long-term poor nutrition, an illness or infection, and/or inappropriate care and stimulation from adults. When they receive adequate nutrition and care, stunted children (especially if younger than 2 years) are generally able to regain some lost growth potential and \"catch-up\" in %{ length_or_height }. Multiple measurements over time will help you determine if a child is catching up or growing at a normal rate.",
    },
    29: {
        "contentId": 29,
        "i18n_line": $gettext("Offer a variety of healthy foods. Encourage children's natural tendency to be active. Emphasize health, and not on weight."),
        "line": "Offer a variety of healthy foods. Encourage children's natural tendency to be active. Emphasize health, and not on weight.",
    },
    30: {
        "contentId": 30,
        "i18n_line": $gettext("After infancy, adolescence is the period of most rapid change. For girls, the growth spurt begins between ages 10 and 11 and lasts until around 15; for boys, it starts between ages 12 and 13 and lasts until around 17. Girls typically stop growing about three years after they have had their first period, but boys continue to grow throughout their teens."),
        "line": "After infancy, adolescence is the period of most rapid change. For girls, the growth spurt begins between ages 10 and 11 and lasts until around 15; for boys, it starts between ages 12 and 13 and lasts until around 17. Girls typically stop growing about three years after they have had their first period, but boys continue to grow throughout their teens.",
    },
    31: {
        "contentId": 31,
        "i18n_line": $gettext("Normal growth is supported by good nutrition, enough sleep, and regular exercise."),
        "line": "Normal growth is supported by good nutrition, enough sleep, and regular exercise.",
    },
    32: {
        "contentId": 32,
        "i18n_line": $gettext("Much of the child’s size depends on genetics - meaning if the parents have a small frame and are slender, their child more likely will too. A low weight is a concern when the child is not receiving adequate nutrition due to poor appetite or difficulty with feeding or has been experiencing an illness (e.g. diarrhea). Multiple measurements over time will help you determine if the child is growing at a normal rate even when their weight is low in proportion to their age."),
        "line": "Much of the child’s size depends on genetics - meaning if the parents have a small frame and are slender, their child more likely will too. A low weight is a concern when the child is not receiving adequate nutrition due to poor appetite or difficulty with feeding or has been experiencing an illness (e.g. diarrhea). Multiple measurements over time will help you determine if the child is growing at a normal rate even when their weight is low in proportion to their age.",
    },
    33: {
        "contentId": 33,
        "i18n_line": $gettext("Mid-upper arm circumference, or MUAC, is a direct measure of body muscle and fat. Muscle and fat are important to children's health and their ability to fight infections and illnesses."),
        "line": "Mid-upper arm circumference, or MUAC, is a direct measure of body muscle and fat. Muscle and fat are important to children's health and their ability to fight infections and illnesses.",
    },
    34: {
        "contentId": 34,
        "i18n_line": $gettext("Mid-upper arm circumference, or MUAC, is a direct measure of body muscle and fat. Muscle and fat are important for health. When children are undernourished, the amount of muscle and fat in their bodies decreases. A low MUAC indicates that the child might be malnourished due to inadequate intake of food (quality and quantity), repeated illnesses or infections, or a combination of these."),
        "line": "Mid-upper arm circumference, or MUAC, is a direct measure of body muscle and fat. Muscle and fat are important for health. When children are undernourished, the amount of muscle and fat in their bodies decreases. A low MUAC indicates that the child might be malnourished due to inadequate intake of food (quality and quantity), repeated illnesses or infections, or a combination of these.",
    },
    36: {
        "contentId": 36,
        "i18n_line": $gettext("Children who are severely malnourished (extremely thin or wasted) may need urgent medical treatment and specialized therapeutic foods to recover. Children with medical complications or oedema should be immediately referred to an inpatient therapeutic care facility for treatment. Children who do not have medical complications or oedema can be referred to an outpatient therapeutric care facility for treatment."),
        "line": "Children who are severely malnourished (extremely thin or wasted) may need urgent medical treatment and specialized therapeutic foods to recover. Children with medical complications or oedema should be immediately referred to an inpatient therapeutic care facility for treatment. Children who do not have medical complications or oedema can be referred to an outpatient therapeutric care facility for treatment.",
    },
    37: {
        "contentId": 37,
        "i18n_line": $gettext("Dietary recommendations by age for typically-developing infants of normal birth weight: 0-1 month old (infant formula 550-700 ml/day); 1-2 months old (infant formula 650-850 ml/day); 2-3 months old (infant formula 700-950 ml/day); 4-6 months old (infant formula 750-1350 ml/day); 6-8 months old (infant forumla 700-950 ml/day and complementary foods 2-3 times/day); 9-12 months old (infant formula 500-950 ml/day and complementary foods 3-4 times/day). Make sure the infant formula is fortified with iron."),
        "line": "Dietary recommendations by age for typically-developing infants of normal birth weight: 0-1 month old (infant formula 550-700 ml/day); 1-2 months old (infant formula 650-850 ml/day); 2-3 months old (infant formula 700-950 ml/day); 4-6 months old (infant formula 750-1350 ml/day); 6-8 months old (infant forumla 700-950 ml/day and complementary foods 2-3 times/day); 9-12 months old (infant formula 500-950 ml/day and complementary foods 3-4 times/day). Make sure the infant formula is fortified with iron.",
        "i18n_comLine": $gettext("Exclusive breastfeeding (no other food is given) is recommended for the first 6 months of life. Continued breastfeeding is recommended for up to 24 months of age and beyond, along with nutrient-dense complementary foods. How much and how often an infant feeds depends on the infant's needs. In general, it is recommended to breastfeed infants on demand (when they show signs of hunger). For infants who are not able to breastfeed or do not have access to breast milk, a suitable breast milk alternative is used."),
        "comLine": "Exclusive breastfeeding (no other food is given) is recommended for the first 6 months of life. Continued breastfeeding is recommended for up to 24 months of age and beyond, along with nutrient-dense complementary foods. How much and how often an infant feeds depends on the infant's needs. In general, it is recommended to breastfeed infants on demand (when they show signs of hunger). For infants who are not able to breastfeed or do not have access to breast milk, a suitable breast milk alternative is used.",
    },
    38: {
        "contentId": 38,
        "i18n_line": $gettext("Nutrient-dense foods provide high amounts of protein, healthy fats, fiber, vitamins, and minerals compared to the amount of calories they supply. It is important to offer children a wide variety of nutrient-dense foods such as poultry, meat, liver, fish, eggs, nuts, seeds, dairy products, beans, fruits, and vegetables. To promote weight gain, select the foods that also provide a lot of calories such as meat, fish, eggs, nuts, seeds, and dairy products."),
        "line": "Nutrient-dense foods provide high amounts of protein, healthy fats, fiber, vitamins, and minerals compared to the amount of calories they supply. It is important to offer children a wide variety of nutrient-dense foods such as poultry, meat, liver, fish, eggs, nuts, seeds, dairy products, beans, fruits, and vegetables. To promote weight gain, select the foods that also provide a lot of calories such as meat, fish, eggs, nuts, seeds, and dairy products.",
    },
    39: {
        "contentId": 39,
        "i18n_line": $gettext("Options for specially formulated foods or food supplements include fortified blended foods, commercial infant cereals, high calorie/protein powders, micronutrient powders (vitamins and minerals), or ready-to-use foods like pastes, compressed bars, or biscuits. If these options are not available or easily accessible, the child may need to be referred to a health facility or a supplementary feeding center."),
        "line": "Options for specially formulated foods or food supplements include fortified blended foods, commercial infant cereals, high calorie/protein powders, micronutrient powders (vitamins and minerals), or ready-to-use foods like pastes, compressed bars, or biscuits. If these options are not available or easily accessible, the child may need to be referred to a health facility or a supplementary feeding center.",
    },
}

