<!-- From https://www.iconfinder.com/icons/3994376/arrow_back_left_navigation_previous_icon -->
<template>
  <svg
    aria-hidden="true"
    class="icon-left-arrow"
    height="100%"
    width="100%"
    viewBox="0 0 96 96"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.3756,48.0022l30.47-25.39a6.0035,6.0035,0,0,0-7.6878-9.223L26.1563,43.3906a6.0092,6.0092,0,0,0,0,9.2231L62.1578,82.615a6.0035,6.0035,0,0,0,7.6878-9.2231Z"
    />
  </svg>
</template>
