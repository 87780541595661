<script setup>
  import { ref } from "vue"

  import IntroSlide from "@/components/IntroSlide.vue"

  const childComponentRef = ref(null)

  function finish() {
    childComponentRef.value.skipIntro()
  }
</script>
<template>
  <IntroSlide
    ref="childComponentRef"
    :current-number="3"
  >
    <article class="introduction-slide">
      <h1 class="title">
        How do I get started?
      </h1>

      <p class="lede">
        The first thing to do is to install the app on your phone if you would like. Just do this thing [instructions TK].
      </p>

      <p>The Help menu is always available in the top right of each screen. You can find the following resources there.</p>

      <ul class="list">
        <li><strong>Tutorials</strong>: take tours for parts of the app.</li>
        <li><strong>Resource Library</strong>: review the techniques and concepts useful using the app.</li>
        <li><strong>Introduction</strong>: revisit this introduction</li>
        <li><strong>Feedback</strong>: provide feedback to SPOON, the creators of <em>Count Me In</em>.</li>
      </ul>
      <div class="has-text-centered">
        <a
          class="button"
          @click="finish"
        >
          Finish
        </a>
      </div>
    </article>
  </IntroSlide>
</template>
<style scoped>
  ul.list {
    margin-bottom: 1rem;
  }
</style>
