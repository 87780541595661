<template>
  <svg
    aria-hidden="true"
    class="icon-spinner"
    height="100%"
    width="100%"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      class="bk"
      width="100"
      height="100"
      fill="none"
    />
    <rect
      transform="rotate(0 50 50) translate(0 -30)"
      x="46.5"
      y="37.5"
      width="7"
      height="25"
      rx="5"
      ry="5"
      fill="#3ba9dc"
    >
      <animate
        attributeName="opacity"
        begin="0s"
        dur="1s"
        from="1"
        repeatCount="indefinite"
        to="0"
      />
    </rect>
    <rect
      transform="rotate(30 50 50) translate(0 -30)"
      x="46.5"
      y="37.5"
      width="7"
      height="25"
      rx="5"
      ry="5"
      fill="#3ba9dc"
    >
      <animate
        attributeName="opacity"
        begin="0.08333333333333333s"
        dur="1s"
        from="1"
        repeatCount="indefinite"
        to="0"
      />
    </rect>
    <rect
      transform="rotate(60 50 50) translate(0 -30)"
      x="46.5"
      y="37.5"
      width="7"
      height="25"
      rx="5"
      ry="5"
      fill="#3ba9dc"
    >
      <animate
        attributeName="opacity"
        begin="0.16666666666666666s"
        dur="1s"
        from="1"
        repeatCount="indefinite"
        to="0"
      />
    </rect>
    <rect
      transform="rotate(90 50 50) translate(0 -30)"
      x="46.5"
      y="37.5"
      width="7"
      height="25"
      rx="5"
      ry="5"
      fill="#3ba9dc"
    >
      <animate
        attributeName="opacity"
        begin="0.25s"
        dur="1s"
        from="1"
        repeatCount="indefinite"
        to="0"
      />
    </rect>
    <rect
      transform="rotate(120 50 50) translate(0 -30)"
      x="46.5"
      y="37.5"
      width="7"
      height="25"
      rx="5"
      ry="5"
      fill="#3ba9dc"
    >
      <animate
        attributeName="opacity"
        begin="0.3333333333333333s"
        dur="1s"
        from="1"
        repeatCount="indefinite"
        to="0"
      />
    </rect>
    <rect
      transform="rotate(150 50 50) translate(0 -30)"
      x="46.5"
      y="37.5"
      width="7"
      height="25"
      rx="5"
      ry="5"
      fill="#3ba9dc"
    >
      <animate
        attributeName="opacity"
        begin="0.4166666666666667s"
        dur="1s"
        from="1"
        repeatCount="indefinite"
        to="0"
      />
    </rect>
    <rect
      transform="rotate(180 50 50) translate(0 -30)"
      x="46.5"
      y="37.5"
      width="7"
      height="25"
      rx="5"
      ry="5"
      fill="#3ba9dc"
    >
      <animate
        attributeName="opacity"
        begin="0.5s"
        dur="1s"
        from="1"
        repeatCount="indefinite"
        to="0"
      />
    </rect>
    <rect
      transform="rotate(210 50 50) translate(0 -30)"
      x="46.5"
      y="37.5"
      width="7"
      height="25"
      rx="5"
      ry="5"
      fill="#3ba9dc"
    >
      <animate
        attributeName="opacity"
        begin="0.5833333333333334s"
        dur="1s"
        from="1"
        repeatCount="indefinite"
        to="0"
      />
    </rect>
    <rect
      transform="rotate(240 50 50) translate(0 -30)"
      x="46.5"
      y="37.5"
      width="7"
      height="25"
      rx="5"
      ry="5"
      fill="#3ba9dc"
    >
      <animate
        attributeName="opacity"
        begin="0.6666666666666666s"
        dur="1s"
        from="1"
        repeatCount="indefinite"
        to="0"
      />
    </rect>
    <rect
      transform="rotate(270 50 50) translate(0 -30)"
      x="46.5"
      y="37.5"
      width="7"
      height="25"
      rx="5"
      ry="5"
      fill="#3ba9dc"
    >
      <animate
        attributeName="opacity"
        begin="0.75s"
        dur="1s"
        from="1"
        repeatCount="indefinite"
        to="0"
      />
    </rect>
    <rect
      transform="rotate(300 50 50) translate(0 -30)"
      x="46.5"
      y="37.5"
      width="7"
      height="25"
      rx="5"
      ry="5"
      fill="#3ba9dc"
    >
      <animate
        attributeName="opacity"
        begin="0.8333333333333334s"
        dur="1s"
        from="1"
        repeatCount="indefinite"
        to="0"
      />
    </rect>
    <rect
      transform="rotate(330 50 50) translate(0 -30)"
      x="46.5"
      y="37.5"
      width="7"
      height="25"
      rx="5"
      ry="5"
      fill="#3ba9dc"
    >
      <animate
        attributeName="opacity"
        begin="0.9166666666666666s"
        dur="1s"
        from="1"
        repeatCount="indefinite"
        to="0"
      />
    </rect>
  </svg>
</template>
