<template>
  <ReLoginModal
    v-if="showReLoginModal"
    @login-complete="handleReLogin"
  />

  <slot name="header" />

  <h1 class="title">
    {{ pageTitle }}
  </h1>

  <slot name="preface" />

  <FormKit
    v-if="formIsReady"
    type="form"
    :submit-label="$gettext('Save')"
    :submit-attrs="{ inputClass: 'button is-primary' }"
    @submit="submitForm"
  >
    <FormKitSchema
      :schema="getSchema()"
      :data="formData"
    />
  </FormKit>
  <section v-else>
    <PageLoading :with-text="true" />
  </section>
</template>

<script setup>
  import { ref, isProxy, toRaw } from "vue"
  import { useRouter } from "vue-router"

  import { gettext } from "@/utils/Translation"
  import { showFlashMessage } from "@/utils/GlobalState"
  import ReLoginModal from "@/components/accounts/ReLoginModal.vue"
  import PageLoading from "@/components/PageLoading.vue"

  const { $gettext } = gettext
  const showReLoginModal = ref(false)
  const formData = ref(null)
  const formIsReady = ref(false)
  const reauthNeededDuringSave = ref(false) // Hacky way to tell where we were when we popped the ReLoginModal
  const stashedData = ref(null)  // Hacky way to stash form data for access post-reauth.
  const router = useRouter()

  const props = defineProps({
    pageTitle: {
      type: String,
      required: true,
    },
    // Exactly one of successRoute and pushSuccessRouteFunc must be supplied
    // Needs to resolve to a route
    successRoute: {
      type: Object,
      default: null,
    },
    pushSuccessRouteFunc: {
      type: Function,
      default: null,
    },
    // Exactly one of schema and getSchemaFunc must be supplied
    // Needs to be a FormKit schema
    schema: {
      type: Object,
      default: null,
    },
    // Function that receives the object returned by formDataRetrievalFunc and returns
    // a FormKit schema. (Used to contextualize the schema based on the object.)
    getSchemaFunc: {
      type: Function,
      default: null,
    },
    // Async function that queries the server for initial data. Ideally this is
    // where any auth issues would surface. Omit when creating new objects.
    formDataRetrievalFunc: {
      type: Function,
      default: null,
    },
    // Async function that attempts to upload the item to the server. It receives
    // the form data.
    uploadFunc: {
      type: Function,
      required: true,
    },
  })

  function getSchema() {
    return props.schema || props.getSchemaFunc(formData.value)
  }

  // Called after the save process succeeded
  function saveSuccess(result) {
    showFlashMessage({ msg: $gettext("Your changes have been saved."), class: "is-success" })
    if (props.successRoute) {
      router.push(props.successRoute)
    }
    else if (props.pushSuccessRouteFunc) {
      props.pushSuccessRouteFunc(result)
    }
    // I guess we're not going anywhere!
  }

  // Called after user successfully logs in, if needed (and with the same account!)
  function handleReLogin() {
    showReLoginModal.value = false
    if (reauthNeededDuringSave.value) {
      const data = isProxy(stashedData.value) ? toRaw(stashedData.value) : stashedData.value
      props.uploadFunc(data)
        .then(saveSuccess)
        .catch(error => {
          // How on earth would we end up here?
          if (error.name === "LOGIN_REQUIRED") {
            showReLoginModal.value = true
          }
        })
    }
    else {
      getData() // async
    }
  }

  function submitForm(data) {
    this.submitAttrs.inputClass = "button is-loading"
    this.submitLabel = $gettext("Loading")
    // Inexplicably, in between here and the catch block below, we lose the
    // id property of our object. What?
    const localId = data.id
    props.uploadFunc(data)
      .then(saveSuccess)
      .catch(error => {
        // Hackily preserve state before handing off to relogin modal
        stashedData.value = data
        if (localId) {
          stashedData.value.id = localId
        }
        reauthNeededDuringSave.value = true
        if (error.name === "LOGIN_REQUIRED") {
          showReLoginModal.value = true
        }
      })
  }

  async function getData() {
    if (props.formDataRetrievalFunc) {
      await props.formDataRetrievalFunc()
        .then(data => {
          if (data) {
            formData.value = data
            formIsReady.value = true
          }
          else {
            router.replace({ name: "SiteList" })
          }
        })
        .catch(error => {
          if (error.name === "LOGIN_REQUIRED") {
            showReLoginModal.value = true
          }
        })
      }
    else {
      formIsReady.value = true
    }
  }

  getData()
</script>
