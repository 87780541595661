<template>
  <div
    v-if="wrapperClasses"
    :class="wrapperClasses"
  >
    <template v-if="withText">
      {{ $gettext("Loading") }}
    </template>
    <progress
      :class="classes"
      max="100"
    >
      60%
    </progress>
  </div>
  <template v-else>
    <template v-if="withText">
      {{ $gettext("Loading") }}
    </template>
    <progress
      :class="classes"
      max="100"
    >
      60%
    </progress>
  </template>
</template>
<script setup>
  const props = defineProps({
    // If not specified, the <progress> element won't be wrapped at all.
    wrapperClasses: {
      type: String,
      default: null,
    },
    // Size is a string that completes one of Bulma's "is-*" styles:
    // small, medium, normal, large.
    size: {
      type: String,
      default: "large",
    },
    withText: {
      type: Boolean,
      default: false,
    }
  })
  const classes = `progress is-${props.size} is-info`
</script>
<style scoped>
  .padded {
    padding: 1.5em;
  }
</style>