import { gettext } from "@/utils/Translation.js"

const { $gettext } = gettext

export function getLearnMores() {
  return {
    1: $gettext("Iron is critical to support the rapid growth and brain development that happens during the first year of life. So it is very important that an infant receives enough iron from infant formula milk. To find out if the formula has enough iron, look at the back of the formula canister. Iron content is normally listed in milligrams (mg). If the label shows more than \"5 mg of iron per 100 g\" of formula, then the formula has enough iron (this could also be listed as \"1 mg of iron per 100 calories\" or \"0.67 mg of iron per 100 ml\"). If not, find out if infant formula with added iron is available."),
    2: $gettext("Animal milk (e.g. cow's milk, goat's milk) contains little iron. It may cause inflammation and small bleeds in the gut resulting in blood loss. A child who drinks animal milk instead of infant formula with added iron will not receive the amount of iron that is essential for their growth and development."),
    3: $gettext('Iron is critical to support the rapid growth and brain development that happens during the first year of life. So it is very important that a child receive enough iron from infant formula and complementary foods. To find out if the formula has enough iron, look at the back of the formula canister. Iron content is normally listed in milligrams (mg). If the label shows more than "5 mg of iron per 100 g" of formula, then the formula has enough iron (this could also be listed as "1 mg of iron per 100 calories" or "0.67 mg of iron per 100 ml"). If not, find out if infant formula with added iron is available. In addition to formula milk, offer foods high in iron. Prepare foods in a texture that is a safe for the child to eat.'),
    4: $gettext("Foods that are high in iron include red meat, chicken, fish, seafood, and eggs; legumes like beans, peas, lentils and soybeans; dark green leafy vegetables like spinach; dried fruits like raisins and apricots; whole grains; nuts and seeds. These foods should be prepared according to the child's development and feeding skills. Soaking or sprouting legumes and grains before cooking helps the body more easily use the iron from these foods."),
    5: $gettext("While the body easily absorbs iron from meats, the type of iron in vegetables and legumes is harder to access. Vitamin C helps to break down this type of iron into parts that the child's body can more easily use. Foods high in vitamin C include vegetables like tomatoes, bell peppers, cabbage, cauliflower, sweet potatoes, and dark leafy greens (spinach, kale) and fruits like papaya and citrus fruits (e.g. orange, lemon, grapefruit). For maximum vitamin C, these foods should be consumed fresh or lightly cooked and should be prepared according to the child's development and feeding skills. Ideas for pairing: leafy greens + lemon juice; lentils + red bell peppers; beans + sweet potato. "),
    6: $gettext("Milk contains nutrition that is very important to a child. But large amounts can make it difficult for the body to use iron. This could increase the risk for low-iron anemia. "),
    7: $gettext("If low iron is one of the causes of anemia (low hemoglobin), we should expect hemoglobin to increase after iron supplementation."),
    8: $gettext("Anemia can have many causes other than low iron. These include low intake of folate and vitamin B12, infection or inflammation, blood disorders (e.g., sickle cell disease) and some cancers. If anemia is not caused by low iron, then iron supplements will not result in an increase in hemoglobin."),
    10: $gettext("Although the child is no longer anemic, continuing iron supplements for a few more months builds the child's iron stores and prevents low-iron anemia from occurring again."),
    11: $gettext("Infections can lead to low hemoglobin levels regardless of whether the child has low iron."),
    12: $gettext('If you give iron to a child who has enough iron in their body, you may overload their system with iron. Too much iron in the body causes imbalance in the "good" versus "bad" bacteria in the gut, placing the child at risk for infections.'),
    13: $gettext("Bacteria that cause infection need iron to grow. If you give iron to a child with an infection, bacteria can multiply, which makes it more difficult for the child to overcome the infection."),
    14: $gettext("Vitamin C helps the body use iron from supplements. Always give iron supplements together with vitamin C supplements or foods high in vitamin C."),
    15: $gettext("Milk and tea contain substances that bind to iron so it cannot be used by the body. If the child drinks milk or tea, give iron supplements 1 hour before or after. "),
    16: $gettext("Fresh juices like orange, grapefruit, lemon, papaya, mango and tomato are great alternatives to vitamin C supplements."),
    17: $gettext("Iron may interfere with absorption of certain antibiotics."),
    18: $gettext("Fiber is found in plant foods. Foods high in fiber include beans, legumes of all kinds, whole grains, leafy greens, nuts, and some fruits like guava and passion fruit."),
    19: $gettext("Wash hands BEFORE food preparation, feeding a child, or giving medicine to a child, and AFTER using the toilet, changing diapers, cleaning a child who has defecated, and tending to someone who is sick."),
  }
}

export function getCommunityLearnMores() {
  return {
    1: $gettext("Iron is critical to support the rapid growth and brain development that happens during the first year of life. Exclusive breastfeeding (no other food is given) is recommended for the first 6 months of life. Continued breastfeeding is recommended for up to 24 months of age and beyond, along with iron-rich complementary foods. For infants who are not able to breastfeed or do not have access to breast milk, a suitable breast milk alternative is used."),
    2: $gettext("Animal milk (e.g. cow's milk, goat's milk) contains little iron. It may cause inflammation and small bleeds in the gut resulting in blood loss. A child who drinks animal milk instead of breast milk will not receive the amount of iron that is essential for their growth and development. In the absence of breast milk, commercial infant formula fortified with iron is the best alternative."),
    3: $gettext("Iron is critical to support the rapid growth and brain development that happens during the first year of life. Exclusive breastfeeding (no other food is given) is recommended for the first 6 months of life. Continued breastfeeding is recommended for up to 24 months of age and beyond, along with iron-rich complementary foods. In addition to breast milk, offer foods high in iron. Prepare foods in a texture that is safe for the child to eat. For infants who are not able to breastfeed or do not have access to breast milk, a suitable breast milk alternative is used."),
  }
}

export function getDietaryRecommendationsContent() {
  const LEARN_MORE = getLearnMores()
  return {
    // [Age < 6 months]
    1: [{
      line: $gettext("Make sure child is receiving infant formula with added iron until 12 months of age."),
      learnMore: LEARN_MORE[1],
    },
    {
      line: $gettext("Avoid giving animal milk (e.g. cow's milk) until child is older than 12 months."),
      learnMore: LEARN_MORE[2],
    }],
    // [Age 6-12 months]
    2: [{
      line: $gettext("Offer infant formula with added iron and baby food high iron until 12 months of age."),
      learnMore: LEARN_MORE[3],
    },
    {
      line: $gettext("Avoid giving animal milk (e.g. cow's milk) until child is older than 12 months."),
      learnMore: LEARN_MORE[2],
    },
    {
      line: $gettext("Offer foods high in iron at least once a day."),
      learnMore: LEARN_MORE[4],
    },
    {
      line: $gettext("Pair plant-based foods rich in iron with fresh foods high in vitamin C."),
      learnMore: LEARN_MORE[5],
    },
    ],
    // [Age ≥ 12 months]
    3: [{
      line: $gettext("Offer foods high in iron at least once a day."),
      learnMore: LEARN_MORE[4],
    },
    {
      line: $gettext("Pair plant-based foods rich in iron with fresh foods high in vitamin C."),
      learnMore: LEARN_MORE[5],
    },
    {
      line: $gettext("Limit intake of animal milk (e.g. cow milk) to 2-3 cups (480-720 ml) a day."),
      learnMore: LEARN_MORE[6],
    },
    ],
  }
}

export function getCommunityDietaryRecommendationsContent() {
  const COMMUNITY_LEARN_MORE = getCommunityLearnMores()
  const LEARN_MORE = getLearnMores()
  return {
    // [Age < 6 months]
    1: [{
      line: $gettext("Breastfeed exclusively until age 6 months."),
      learnMore: COMMUNITY_LEARN_MORE[1],
    },
    {
      line: $gettext("Avoid giving animal milk (e.g. cow's milk) until child is older than 12 months."),
      learnMore: COMMUNITY_LEARN_MORE[2],
    }],
    // [Age 6-12 months]
    2: [{
      line: $gettext("Continue breastfeeding until at least age 24 months alongside complementary foods high in iron."),
      learnMore: LEARN_MORE[3],
    },
    {
      line: $gettext("Avoid giving animal milk (e.g. cow's milk) until child is older than 12 months."),
      learnMore: COMMUNITY_LEARN_MORE[2],
    },
    {
      line: $gettext("Offer foods high in iron at least once a day."),
      learnMore: LEARN_MORE[4],
    },
    {
      line: $gettext("Pair plant-based foods rich in iron with fresh foods high in vitamin C."),
      learnMore: LEARN_MORE[5],
    }],
    // [Age ≥ 12 months]
    3: [{
      line: $gettext("Offer foods high in iron at least once a day."),
      learnMore: LEARN_MORE[4],
    },
    {
      line: $gettext("Pair plant-based foods rich in iron with fresh foods high in vitamin C."),
      learnMore: LEARN_MORE[5],
    },
    {
      line: $gettext("Limit intake of animal milk (e.g. cow milk) to 2-3 cups (480-720 ml) a day."),
      learnMore: LEARN_MORE[6],
    }],
  }
}

export function getHygieneRecsContent() {
  const LEARN_MORE = getLearnMores()
  return [
    {
      line: $gettext("Practice good hygiene, especially handwashing with soap."),
      learnMore: LEARN_MORE[19]
    },
    { line: $gettext("Keep food preparation areas and the child's environment as clean as possible.") },
    { line: $gettext("Use safe water for drinking and food preparation.") },
  ]
}

export function getSupplementsFormContent(index: number) {
  const mapping = {
    // [age 0-6 months]
    1: [{ line: $gettext("Liquid like drops, tonic, or syrup"), title: $gettext("Form") }],
    // [age 6 months-2 years]
    2: [
      { line: $gettext("Choose a supplement form that is appropriate for child:"), title: $gettext("Form") },
      { line: $gettext("Liquid like drops, tonic, syrup"), class: "sub-item" },
      { line: $gettext("Powder mixed with liquid or food"), class: "sub-item" },
    ],
    // [age 2-4 years]
    3: [
      { line: $gettext("Choose a supplement form that is appropriate for child:"), title: $gettext("Form") },
      { line: $gettext("Liquid like drops, tonic, syrup"), class: "sub-item" },
      { line: $gettext("Powder mixed with liquid or food"), class: "sub-item" },
      { line: $gettext("Gummies or chewables"), class: "sub-item" },
    ],
    // [age > 4 years]
    4: [
      { line: $gettext("Choose a supplement form that is appropriate for child:"), title: $gettext("Form") },
      { line: $gettext("Liquid like drops, tonic, syrup"), class: "sub-item" },
      { line: $gettext("Powder mixed with liquid or food"), class: "sub-item" },
      { line: $gettext("Gummies or chewables"), class: "sub-item" },
      { line: $gettext("Tablets or capsules"), class: "sub-item" },
    ],
  }
  return { lines: mapping[index] }
}

export function getSupplementsContent() {
  const LEARN_MORE = getLearnMores()
  return {
    supplements: [
      {
        title: $gettext("Iron"),
        lines: [
          {
            title: $gettext("Dosage"),
            line: $gettext("Follow instructions on package"),
          },
          {
            line: $gettext("Give iron with vitamin C supplements."),
            learnMore: LEARN_MORE[14],
          },
          {
            line: $gettext("Give iron with food but not with milk or tea."),
            learnMore: LEARN_MORE[15],
          },
          {
            line: $gettext("If taking antibiotics, give iron 2 hours before or after."),
            learnMore: LEARN_MORE[17],
          },
          // Next one only for children over 6 months
          {
            line: $gettext("Offer plenty of water and foods high in fiber to prevent constipation."),
            learnMore: LEARN_MORE[18],
          }
        ],
      },
      {
        title: $gettext("Vitamin C"),
        lines: [
          {
            title: $gettext("Dosage"),
            line: $gettext("Follow instructions on package"),
          },
          // Next one only for children over 6 months
          {
            line: $gettext("If vitamin C supplements are not available, offer 120 ml of fresh orange juice or other foods high in vitamin C."),
            learnMore: LEARN_MORE[16],
          },
          {
            line: $gettext("Give vitamin C at the same time as iron supplements."),
            learnMore: LEARN_MORE[14],
          },
        ]
      },
      {
        title: $gettext("Folic Acid"),
        lines: [
          {
            title: $gettext("Dosage"),
            line: $gettext("Follow instructions on package"),
          },
        ],
      },
    ],
    noSupplements: [{
      lines: [
        { line: $gettext("No supplements recommended.") },
        {
          line: $gettext("Giving iron supplements to a child who does not have low-iron anemia could be harmful."),
          learnMore: LEARN_MORE[12],
        },
      ]
    }],
    noSupplementsBcOfInfection: [{
      lines: [
        { line: $gettext("No supplements recommended.") },
        {
          line: $gettext("Giving iron supplements to a child who has an infection or inflammation could be harmful."),
          learnMore: LEARN_MORE[13],
        },
      ]
    }],
  }
}

export function getInterpretationsContent(stage: string, { interval, follow_up_date, hb_increase = null }: { interval: number, follow_up_date: string, hb_increase: number }) {
  const LEARN_MORE = getLearnMores()
  const mapping = {
    a: [
      { line: $gettext("Child does not have anemia.") },
      { line: $gettext("Iron supplements are not needed.") },
      { line: $gettext("Follow food recommendations to prevent low-iron anemia (see below).") },
      { line: $gettext("A follow-up assessment has been scheduled for %{ follow_up_date }.", { follow_up_date }) },
    ],
    b: [
      // Translators: "severity" is one of: "mild", "moderate", or "severe"
      { line: $gettext("Give supplements for 1 month (see below).") },
      { line: $gettext("Follow food recommendations (see below).") },
      {
        line: $gettext("Retest hemoglobin in 1 month to confirm if anemia is caused by low iron."),
        learnMore: LEARN_MORE[7],
      },
      // Only if severity is severe
      { line: $gettext("Because the anemia is severe, refer for further evaluation."), isReferral: true },
      { line: $gettext("A follow-up assessment has been scheduled for %{ follow_up_date }.", { follow_up_date }) },
    ],
    c: [
      // In stage c, child could well no longer be anemic. This is handled in
      // the model's save_interpretations() method.
      {
        line: $gettext("Iron supplements increased hemoglobin by %{ hb_increase } mg/dl. This confirms that anemia is caused, at least in part, by low iron.", { hb_increase: String(hb_increase) }),
        learnMore: LEARN_MORE[7]
      },
      { line: $gettext("Continue supplements for %{ interval } months (see below).", { interval: String(interval) }) },
      { line: $gettext("Follow food recommendations (see below).") },
      // Only if severe
      { line: $gettext("A follow-up assessment has been scheduled for %{ follow_up_date }.", { follow_up_date }) },
      { line: $gettext("Because the anemia is severe, refer for further evaluation."), isReferral: true },
    ],
    d: [
      {
        line: $gettext("Iron supplements did not increase hemoglobin. This indicates that anemia is not caused by low iron."),
        learnMore: LEARN_MORE[8],
      },
      { line: $gettext("Stop supplements.") },
      { line: $gettext("Follow food recommendations (see below).") },
      {
        line: $gettext("Refer for further evaluation to determine the cause of anemia and proper treatment."),
        isReferral: true
      },
      { line: $gettext("A follow-up assessment has been scheduled for %{ follow_up_date }.", { follow_up_date }) },
    ],
    e: [
      { line: $gettext("Child no longer has anemia.") },
      {
        line: $gettext("Continue supplements for %{ interval } months to prevent low-iron anemia (see below).", { interval: String(interval) }),
        learnMore: LEARN_MORE[10],
      },
      { line: $gettext("Follow food recommendations (see below).") },
      { line: $gettext("A follow-up assessment has been scheduled for %{ follow_up_date }.", { follow_up_date }) },
    ],
    f: [
      { line: $gettext("You indicated child has an infection or inflammation.") },
      {
        line: $gettext("Allow for the condition to resolve before testing hemoglobin."),
        learnMore: LEARN_MORE[11],
      },
      { line: $gettext("Stop supplements.") },  // Conditional
      { line: $gettext("Refer for further evaluation and proper treatment of the infection."), isReferral: true },
      { line: $gettext("A follow-up assessment has been scheduled for %{ follow_up_date }.", { follow_up_date }) },
    ],
    g: [
      {
        line: $gettext("Iron supplements did not further increase hemoglobin. This indicates that anemia is not caused by low iron."),
        learnMore: LEARN_MORE[8],
      },
      { line: $gettext("Stop supplements.") },
      { line: $gettext("Follow food recommendations (see below).") },
      { line: $gettext("Refer for further evaluation to determine the cause of anemia and proper treatment."), isReferral: true },
      { line: $gettext("A follow-up assessment has been scheduled for %{ follow_up_date }.", { follow_up_date }) },
    ],
  }
  return mapping[stage]
}
