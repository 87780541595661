<script setup>
  import { ref, onMounted, onUnmounted } from "vue"
  import { useRouter } from "vue-router"

  import { shouldShowIntro } from "@/services/Account"
  import { getAgreementDocumentList, uploadAgreements } from "@/services/Account"
  import { setCurrentViewContext } from "@/utils/GlobalState"
  import { getAccountInfo, setAccountInfo } from "@/utils/GlobalState"
  import OnlineRequiredForm from "@/components/OnlineRequiredForm.vue"
  import { gettext } from "@/utils/Translation"

  const { $gettext } = gettext
  const pageReady = ref(false)
  const schema = ref([])
  const router = useRouter()

  function pushSuccessRouteFunc() {
    const name = shouldShowIntro() ? "IntroSlide1" : "HomeRedirect"
    router.push({ name })
  }

  async function uploadFunc(data) {
    const ids = []
    // data looks like {document-1: true, document-2: true, __init: true}
    for (const key in data) {
      if (key.startsWith("document-")) {
        ids.push(parseInt(key.replace("document-", "")))
      }
    }
    const newHasOutstandingAgreements = await uploadAgreements(ids)
    if (!newHasOutstandingAgreements) {
      // This should really be false, unless like new docs were added to the site
      // in the middle of this transaction!
      const accountInfo = getAccountInfo()
      accountInfo.hasOutstandingAgreements = newHasOutstandingAgreements
      setAccountInfo(accountInfo)
    }
  }

  async function getData() {
    const documents = await getAgreementDocumentList()
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i]
      schema.value.push(
        {
          $el: "a",
          attrs: {
            class: "text-link",
            style: { "font-size": "125%", "font-weight": "bold" },
            href: document.file,
          },
          children: document.title,
        },
        {
          $formkit: "checkbox",
          id: `document-${document.id}`,
          name: `document-${document.id}`,
          // This would make parsing form data much easier, but inexplicably causes
          // validation of the last (at least when the form had two docs in it) checkbox
          // to fail. Tested on FormKit 1.5.9.
          // "on-value": document.id,
          label: $gettext("I agree to the terms in this document."),
          "outer-class": "single-checkbox",
          validation: "accepted",
        }
      )
    }
    pageReady.value = true
  }

  // Hack on a class to the body element, so we can customize appearance of #app
  onMounted(() => document.body.classList.add("full-bleed") )
  onUnmounted(() => document.body.classList.remove("full-bleed") )

  setCurrentViewContext()
  getData()
</script>
<template>
  <div class="wrapper">
    <div class="fake-nav-bar">
      <router-link
        :to="{ name: 'Logout' }"
        class="text-link"
      >
        {{ $gettext("Log out") }}
      </router-link>
    </div>
    <article v-if="pageReady">
      <OnlineRequiredForm
        :page-title="$gettext('Agreements')"
        :schema="schema"
        :upload-func="uploadFunc"
        :push-success-route-func="pushSuccessRouteFunc"
      >
        <template #preface>
          <p>
            {{ $gettext("Please read the following documents and agree to their terms to continue using Count Me In.") }}
          </p>
        </template>
      </OnlineRequiredForm>
    </article>
  </div>
</template>
<style scoped lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .fake-nav-bar {
    text-align: right;
    a {
      color: white;
      display: inline-block;
      padding: 1em;
      font-size: 75%;
    }
  }
  article {
    overflow: auto;
    padding: 1em;
    margin: auto 2em;
    background-color: white;
    border-radius: 10px;
  }
</style>
