import { isProxy, toRaw } from "vue"

import { db, ISite, ISiteVisitReport } from "@/db"
import * as api from "@/services/Api"
import { handlePendingUploads, queuePendingUpload } from "@/services/Upload"
import { updateLastUpdateTime } from "@/utils/GlobalState"

/**
 * Return data from IndexedDB or null if not found.
 */
export async function getSiteVisitReportById(id: number): Promise<ISiteVisitReport | null> {
  return await db.siteVisitReports.get(id)
}

export async function getLocalSiteVisitReportsForSite(siteId: number): Promise<ISiteVisitReport[]> {
  return await db.siteVisitReports.where({ siteId }).reverse().sortBy("dateOfVisit")
}

export async function getLocalSiteVisitReportsForProject(projectCmiId: number): Promise<ISiteVisitReport[]> {
  const sites = await db.sites.where({ projectId: projectCmiId }).toArray()
  const siteIds = sites.map((item: ISite) => item.cmiId)
  return await db.siteVisitReports.where("siteId").anyOf(siteIds).reverse().sortBy("dateOfVisit")
}

export async function updateSiteVisitReportsFromServer(siteId: number, isBatch = false): Promise<void> {
  // We'll let API errors percolate up.
  if (!isBatch) await handlePendingUploads()
  const reportsFromServer = await api.getSiteVisitReportsForSite(siteId)

  await db.transaction("rw", db.siteVisitReports, async () => {
    // Purge previous records
    const promises = [db.siteVisitReports.where({ siteId }).delete()]
    // Replace them with the freshies from the server.
    promises.push(
      ...reportsFromServer.map((report: ISiteVisitReport) => db.siteVisitReports.add(report)),
    )
    return Promise.all(promises)
  })

  // Log the new last-update time for this site.
  const site = await db.sites.get({ cmiId: siteId })
  const localItemId = site.id
  await updateLastUpdateTime({ type: "siteVisitReports", localItemId })
}

export async function updateSiteVisitReportsFromServerForProject(projectCmiId: number) {
  await handlePendingUploads()
  const sites = await db.sites.where({ projectId: projectCmiId }).toArray()
  const siteIds = sites.map((item: ISite) => item.cmiId)
  const promises = []
  siteIds.forEach(siteId => promises.push(updateSiteVisitReportsFromServer(siteId)))
  return Promise.all(promises)
}

/**
 * Save or update an instance of a site visit report in IndexedDB;
 * queue its upload to the server;
 * return its id.
 * Note that it is up to the calling function to actually
 * attempt the upload.
 */
export async function finalizeSiteVisitReport(report: ISiteVisitReport): Promise<number> {
  if (isProxy(report)) {
    report = toRaw(report)
  }
  let reportId: number
  await db.transaction("rw", db.siteVisitReports, db.lastUpdated, db.pendingUploads, async () => {
    if (report.id) {
      reportId = report.id
      // update only touches the fields that are provided
      await db.siteVisitReports.update(reportId, report)
    }
    else {
      // PUT either inserts (if new) or *replaces* (if existing) what's in the db
      reportId = await db.siteVisitReports.put(report)
    }
    queuePendingUpload({ type: "siteVisitReport", localItemId: reportId })
  })
  return reportId
}

export async function uploadSiteVisitReport(reportId: number) {
  const report = await getSiteVisitReportById(reportId)
  const fieldNames = [
    "id",
    "cmiId",
    "createdBy",
    "dateOfVisit",
    "timeOfArrival",
    "timeOfDeparture",
    "visitType",
    "activities",
    "nextVisitTiming",
    "successes",
    "challenges",
    "goals",
  ]
  const toUpload = Object.fromEntries(fieldNames.map((field) => [field, report[field]])) as ISiteVisitReport

  return api.uploadSiteVisitReport(report.siteId, toUpload).then(async function(data) {
    // If a successful POST, update the internal CMI id
    if (!report.cmiId) {
      await db.siteVisitReports.update(reportId, { cmiId: data.id })
    }
    return data
  })
}

export function getFoldInSiteVisitReportsFromServer(siteId: number) {
  async function foldInSiteVisitReportsFromServer(reports: Array<object>) {
    // Handle update/insert of the API results
    // Letting all the async calls run in parallel. Should happen fast enough that
    // it just works.
    reports.forEach((report: ISiteVisitReport) => {
      report.siteId = siteId
      db.siteVisitReports.add(report)
    })
  }
  return foldInSiteVisitReportsFromServer
}
