// Logic related to gating the app behind a short PIN.
import { CONFIG } from "@/config"
import { createGlobalState, useStorage } from "@vueuse/core"

const MAX_TRIES = 5
// For now, require session locking logic outside of dev scenarios. We could
// one day make this conditional on some other factors, such as a site setting,
// a user-level preference, or device-specific.
export const shouldUseSessionLocking = !CONFIG.DEV

// Session statuses are:
// - u: unauthed
// - l: live
// - e: expired

const useSessionStore = createGlobalState(
  () => useStorage("session", {
    pin: "",
    count: 0,     // how many tries left
    status: "u",  // see above
  }),
)

export function getSessionStatus(): string {
  const session = useSessionStore()
  return session.value.status
}

export function resetSession() {
  const session = useSessionStore()
  session.value.pin = ""
  session.value.status = "u"
}

// Raises an error if the pin doesn't meet very basic criteria
export function setPin(pin: string) {
  if (new Set(Array.from(pin)).size === 1) {
    throw new Error("SAME_CHAR")
  }
  const obviousFails = new Set(["1234", "4321"])
  if (obviousFails.has(pin)) {
    throw new Error("TOO_SIMPLE")
  }
  renewSession(pin)
}

// Update session to live and number of tries to max
export function renewSession(pin?: string) {
  const session = useSessionStore()
  if (pin) {
    session.value.pin = atob(pin)
  }
  session.value.count = MAX_TRIES
  session.value.status = "l"
}

// Update status to expired. If there isn't a pin set, or no more attempts remain,
// throw an error.
export function pauseSession() {
  const session = useSessionStore()
  session.value.status = "e"
  if (!session.value.pin || session.value.count < 1) {
    throw new Error("Not able to confirm pin.")
  }
}

// Checks submitted attempt against stored PIN value.
// Returns either true (checks out), false (not a match, but tries remain) or
// throws an error meaning not a match and no more tries left.
export function checkPin(attempt: string) {
  const session = useSessionStore()
  if (session.value.pin === atob(attempt)) {
    return true
  }
  session.value.count--
  if (session.value.count < 1) {
    throw new Error("TOO_MANY_TRIES")
  }
  return false
}

// Navigation guard before most pages. Are we good with our session status?
// Do we need to set up a pin, or re-enter it? Returns either true (continue to route)
// or the route params for where it should go instead.
export function checkSession() {
  if (shouldUseSessionLocking) {
    const session = useSessionStore()
    if (session.value.status === "u") {
      return { name: "NewPinForm" }
    }
    if (session.value.status === "e") {
      return { name: "ReenterPinForm" }
    }
  }
  return true
}

// Add a data field to the <body> so tests can tell whether to expect the
// set-PIN form.
export function appendSessionStatus() {
  const bodyEl = document.querySelector("body");
  bodyEl.setAttribute("data-pin-required", shouldUseSessionLocking.toString())
}
