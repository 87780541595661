import dayjs from "dayjs"

import * as AnemiaContent from "@/data/AnemiaContent"
import { IAnemiaAssessment } from "@/db"
import { Child } from "@/models/Child"
import { isAssessmentEditable } from "@/models/Common"
import { getAllSavedAnemiaAssessmentsForChild } from "@/services/AnemiaAssessment"
import { gettext } from "@/utils/Translation"

const { $gettext } = gettext
const ANEMIA_AGE_BRACKETS = {
  "6mo-2yr": [10.5, 9.5, 7],
  "2-5": [11, 10, 7],
  "5-11": [11.5, 11, 8],
  "12-14": [12, 11, 8],
  "f15+": [12, 11, 8],
  "m15+": [13, 11, 8],
}

// This is more a reference than anything used directly.
/* eslint-disable @typescript-eslint/no-unused-vars */
const ANEMIA_STAGES = {
  "a": "Not anemic after baseline",
  "b": "Anemic after baseline",
  "c": "Hb improved after initial diagnosis",
  "d": "Hb not improved after initial diagnosis",
  "e": "Not anemic after therapeutic dose",
  "f": "Infection prevented Hb test",
  "g": "Hb no longer improving after initial success",
}
/* eslint-enable @typescript-eslint/no-unused-vars */

const ANEMIA_SEVERITIES_BRIEF = [
  $gettext("No Anemia"),
  $gettext("Mild"),
  $gettext("Moderate"),
  $gettext("Severe"),
]


export class AnemiaAssessment implements IAnemiaAssessment {
  child: Child

  _anemiaLevel: number
  _ageInDays: number
  _ageInMonths: number
  _ageInYears: number
  previousAssessment: AnemiaAssessment
  previousAssessments: Array<IAnemiaAssessment>
  _interpretations?: Array<object>
  _supplements?: Array<object>

  id: number
  isComplete: boolean
  childId: number
  siteId: number
  cmiId?: number
  dateOfAssessment: Date
  testDate: Date
  dateCreated?: Date
  creatorName?: Date
  dueDate?: Date
  notes?: Date
  hasInfection?: boolean
  hasReferrals: boolean
  hemoglobinLevel?: number
  testingLocation?: string
  currentlyTakingIronSupplements?: boolean
  ironSupplementsTakenWithVitaminC?: boolean
  ironSupplementsMilligramsPerDay?: number
  ironSupplementFrequency?: string
  ironSupplementStartDate?: Date
  stage: string

  constructor(child: Child, data: IAnemiaAssessment) {
    this.child = child instanceof Child ? child : new Child(child)
    this.siteId = child.siteId
    this.isComplete = false
    for (const [key, value] of Object.entries(data)) {
      this[key] = value
    }
    if (this.cmiId) {
      // Presumably imported from server
      this.isComplete = true
    }
  }

  get wrappedChild() {
    // For some reason, our child is devolving to an object at certain points
    // (Noticed when calling get niceAge)
    return this.child instanceof Child ? this.child : new Child(this.child)
  }

  get ageInDays() {
    if (!this._ageInDays && this.testDate && this.child.dob) {
      this._ageInDays = dayjs(this.testDate).diff(this.child.dob, "day")
    }
    return this._ageInDays
  }

  get ageInMonths() {
    if (!this._ageInMonths && this.testDate && this.child.dob) {
      this._ageInMonths = dayjs(this.testDate).diff(this.child.dob, "month", true)
    }
    return this._ageInMonths
  }

  get ageInYears() {
    if (!this._ageInYears && this.testDate && this.child.dob) {
      this._ageInYears = dayjs(this.testDate).diff(this.child.dob, "year", true)
    }
    return this._ageInYears
  }

  get niceAge() {
    if (this.testDate) {
      return this.child.getNiceAge(this.dateOfAssessment)
    }
  }

  // Is this assessment old enough that we should prevent edits to it?
  get isEditable() {
    return isAssessmentEditable(this)
  }

  get reportCard(): { stoplightColor: string, statements: Array<string> } {
    if (!this.isComplete) {
      return { stoplightColor: "", statements: [] }
    }
    if (this.hasInfection) {
      return {
        stoplightColor: "green",
        statements: [$gettext("Hemoglobin not tested because of infection")],
      }
    }
    if (this.anemiaLevel === 3) {
      return {
        stoplightColor: "red",
        statements: [$gettext("Child has severe anemia")],
      }
    }
    if (this.anemiaLevel === 2) {
      return {
        stoplightColor: "red",
        statements: [$gettext("Child has moderate anemia")],
      }
    }
    if (this.anemiaLevel === 1) {
      return {
        stoplightColor: "yellow",
        statements: [$gettext("Child has mild anemia")],
      }
    }
    return {
      stoplightColor: "green",
      statements: [$gettext("Child has healthy hemoglobin levels")],
    }
  }

  get isAnemic() {
    return this.anemiaLevel > 0
  }

  get anemiaLevel() {
    function getAnemiaLevel(assessment: AnemiaAssessment) {
      if (assessment.hasInfection || !assessment.hemoglobinLevel) {
        return -1
      }
      const index = assessment.getAgeBracketIndex(assessment.ageInMonths)
      const bracket = ANEMIA_AGE_BRACKETS[index]
      for (const [i, threshold] of bracket.entries()) {
        if (assessment.hemoglobinLevel >= threshold) return i
      }
      // If we get to this point, it's the worst level
      return bracket.length
    }
    this._anemiaLevel = this._anemiaLevel ?? getAnemiaLevel(this)
    return this._anemiaLevel
  }

  get verdict() {
    const mapping = {
      0: $gettext("No Anemia"),
      1: $gettext("Mild Anemia"),
      2: $gettext("Moderate Anemia"),
      3: $gettext("Severe Anemia"),
    }
    const result = mapping[this.anemiaLevel]
    return result ?? $gettext("Unknown")
  }

  // Assumes processAssessment has already been called!
  get interpretations(): Array<object> {
    function getInterpretations(assessment: AnemiaAssessment) {
      const formatStrings = {
        interval: assessment.getAssessmentInterval(),
        follow_up_date: assessment.child.nextAnemiaAssessmentDate?.toLocaleDateString(),
        hb_increase: 0,
      }
      if (assessment.stage === "c") {
        formatStrings.hb_increase = assessment.hemoglobinLevel - assessment.previousAssessment.hemoglobinLevel
      }
      const items = AnemiaContent.getInterpretationsContent(assessment.stage, formatStrings)
      // Add a first interpretation if it depends on anemia severity
      if (["b", "c", "d", "g"].includes(assessment.stage)) {
        let severity: string
        if (assessment.anemiaLevel === 1) severity = $gettext("Child has mild anemia.")
        else if (assessment.anemiaLevel === 2) severity = $gettext("Child has moderate anemia.")
        else severity = $gettext("Child has severe anemia.")
        if (assessment.stage === "b") severity += " " + $gettext("This can be due to low iron.")
        items.splice(0, 0, { line: severity })
      }
      if (assessment.stage === "b" && assessment.anemiaLevel !== 3) {
        items.splice(-2, 1) // Remove the 2nd-from-last item: a referral
      }
      else if (assessment.stage === "c") {
        if (!assessment.isAnemic) {
          items[0]["line"] = $gettext("Child no longer has anemia.")
        }
        if (assessment.anemiaLevel !== 4) {
          items.pop()
        }
      }
      else if (assessment.stage === "f") {
        if (!(assessment.previousAssessment && assessment.previousAssessment.supplements)) {
          items.splice(2, 1) // Remove "Stop supplements" advice.
        }
      }
      return items
    }
    this._interpretations = this._interpretations ?? getInterpretations(this)
    return this._interpretations
  }

  // Assumes processAssessment has already been called!
  get referrals() {
    const enumerated = this.interpretations
      .filter(item => item.isReferral)
      .entries()
    return Array.from(enumerated).map(([id, item]) => { return { id, content: item.line } })
  }

  // Return the date by which we would like a referral to be made. We're saying
  // two weeks from the date of assessment. Note: this doesn't check to see *if*
  // a referral was made.
  get referralDate() {
    return dayjs(this.dateOfAssessment).add(2, "week").toDate()
  }

  get supplements() {
    function getSupplements(assessment: AnemiaAssessment) {
      const content = AnemiaContent.getSupplementsContent()
      if (assessment.stage === "f") {
        return content.noSupplementsBcOfInfection
      }
      if (["a", "d", "g"].includes(assessment.stage)) {
        return content.noSupplements
      }
      // We ARE recommending supplements, which has multiple parts
      const results = []

      // Supplement "Form"
      let supplementFormInstructions: { lines: Array<object> }
      if (assessment.ageInMonths < 6) supplementFormInstructions = AnemiaContent.getSupplementsFormContent(1)
      else if (assessment.ageInMonths < 2 * 12) supplementFormInstructions = AnemiaContent.getSupplementsFormContent(2)
      else if (assessment.ageInMonths < 4 * 12) supplementFormInstructions = AnemiaContent.getSupplementsFormContent(3)
      else supplementFormInstructions = AnemiaContent.getSupplementsFormContent(4)
      if (assessment.child.nextAnemiaAssessmentDate) {
        const date = assessment.child.nextAnemiaAssessmentDate.toLocaleDateString()
        supplementFormInstructions.lines.push({
          title: $gettext("Duration"),
          line: $gettext("Until next anemia assessment, due %{date}.", { date }),
        })
      }
      else {
        supplementFormInstructions.lines.push({
          title: $gettext("Duration"),
          line: $gettext("Until next anemia assessment."),
        })
      }
      results.push(supplementFormInstructions)

      // Actual supplement info
      const supplements = AnemiaContent.getSupplementsContent().supplements
      if (assessment.ageInMonths < 6) {
        supplements[0].lines.pop()
        supplements[1].lines.splice(1, 1)
      }
      results.push(...supplements)

      return results
    }
    this._supplements = this._supplements ?? getSupplements(this)
    return this._supplements
  }

  get dietaryInfo() {
    let contentIndex: number
    if (this.ageInMonths < 6) contentIndex = 1
    else if (this.ageInMonths < 12) contentIndex = 2
    else contentIndex = 3

    const content = this.child.usesCommunityRecs ? AnemiaContent.getCommunityDietaryRecommendationsContent() : AnemiaContent.getDietaryRecommendationsContent()
    return content[contentIndex]
  }

  get hygieneInfo() {
    return AnemiaContent.getHygieneRecsContent()
  }

  async getAssessmentInterval() {
    if (["b", "f"].includes(this.stage)) return 1

    // By agreement with the Ugandan ministry, we use 6-month
    // follow-up intervals for healthy children under 3 years,
    // rather than Count Me In's default of 3 months.
    const site = await this.child.getSite()
    if (site.country === "UG") {
      if (this.stage === "c" && this.ageInMonths < 3 * 12) return 3
      else return 6
    }

    if (this.ageInYears < 3) return 3
    return 6
  }

  // Expressed as a letter. We persist this, because it depends on the previous
  // assessments stage, and that can get involved to recalculate on the fly, recursively.
  // Note that you need to first call async processAssessment.
  setStage() {
    function getStage(assessment: AnemiaAssessment) {
      if (assessment.hasInfection) return "f"
      if (!assessment.hemoglobinLevel) return null
      if (assessment.previousAssessment === undefined) {
        throw new Error("Can't ask for anemia stage without first calling processAssessment!")
      }
      let prevStage: string
      let prevHb: number
      if (assessment.previousAssessment) {
        prevStage = assessment.previousAssessment.stage
        prevHb = assessment.previousAssessment.hemoglobinLevel
      }
      // A lot of stages feed back to the beginning. Check first for the
      // ones that don't.
      if (prevStage === "b") return assessment.hemoglobinLevel > prevHb ? "c" : "b"
      if (prevStage === "c") {
        if (assessment.isAnemic) return assessment.hemoglobinLevel > prevHb ? "c" : "g"
        return "e"
      }
      // Starting at the beginning of the graph.
      return assessment.isAnemic ? "b" : "a"
    }
    this.stage = getStage(this)
  }

  // Return the appropriate ANEMIA_AGE_BRACKETS index based on a given age.
  getAgeBracketIndex(ageInMonths: number) {
    if (ageInMonths < 24) return "6mo-2yr"
    if (ageInMonths < 60) return "2-5"
    if (ageInMonths < 11 * 12) return "5-11"
    if (ageInMonths < 11 * 12) return "12-14"
    if (this.child.sex === "female") return "f15+"
    return "m15+"
  }

  // If available, cache our "processed" previous assessment.
  // If not available, set it to null (rather than undefined) to
  // indicate that it's known not to exist.
  async setPreviousAssessment() {
    await this.setPreviousAssessments()
    if (this.previousAssessments.length) {
      this.previousAssessment = this.previousAssessments[this.previousAssessments.length - 1] as AnemiaAssessment
    }
    else {
      this.previousAssessment = null
    }
  }

  // Note: previous assessments DOES include the current one
  async setPreviousAssessments() {
    const data = await getAllSavedAnemiaAssessmentsForChild(this.child.id)
    this.previousAssessments = data
      .filter(a => a.dateOfAssessment <= this.dateOfAssessment)
      .map(a => new AnemiaAssessment(this.child, a))
  }

  // Handle the async things we need to do in order to show details
  // recs for assessment.
  async processAssessment() {
    await this.setPreviousAssessment()
    if (!this.stage) {
      this.setStage()
    }
  }

  // ============================
  // = Hemoglobin chart methods =
  // ============================

  // Assumes processAssessment has already been called!
  // Return all hemoglobin test results *up to (& including) this one*
  // for this child in a format useful for our charting. Specifically, as a
  // array of objects with props for "date" (as a string) and "hb" (number).
  // Only include the most recent 6 tests.
  getHbDataForChart() {
    const assessments = this.previousAssessments
      .filter(a => a.hemoglobinLevel)
      .slice(-6)
    return assessments.map(a => {
      return {
        date: dayjs(a.testDate).format("YYYY-MM-DD"),
        hb: a.hemoglobinLevel,
        tooltip: $gettext(
          "Test Date: %{date}; Hb: %{hemoglobin_value} g/dl",
          {
            date: dayjs(a.testDate).format("YYYY-MM-DD"),
            hemoglobin_value: a.hemoglobinLevel.toString(),
          }
        ),
      }
    })
  }

  // Return data for charting threshold lines for the varying degrees of
  // anemia severity. (There are inflection points when the child turns 2, 5,
  // 12, and 14 years old.)

  // Returns an obj mapping the line label (e.g. "Mild") to a array of objects
  // specifying the points making up the line. (Those points are just pairs
  // of "date" (x) and "hb" (y) values.)
  // Note also that we include a line for "No Anemia", but set the hb values
  // as null -- they'll be filled in at the JS level with the maximum vals
  // shown in the chart.
  getAnemiaThresholdLines(hbData: Array<{ date: string, db: number }>) {
    if (!hbData || !hbData.length) {
      return
    }
    let startDate = dayjs(hbData[0]["date"])
    let stopDate = dayjs(hbData[hbData.length - 1]["date"])
    const startAge = this.child.getAgeInMonths(startDate)
    const stopAge = this.child.getAgeInMonths(stopDate)
    const index = this.getAgeBracketIndex(startAge)

    // Push out a month on either side, esp. so single-point charts are meaningful
    startDate = startDate.subtract(1, "month")
    stopDate = stopDate.add(1, "month")

    // Start with the left-most points for each line, and in so doing, flesh
    // out the keys for each line.
    const results = {}
    Array.from(ANEMIA_SEVERITIES_BRIEF.entries()).forEach(([i, severity]) => {
      results[severity] = [{
        "date": startDate.format("YYYY-MM-DD"),
        "hb": i ? ANEMIA_AGE_BRACKETS[index][i - 1] : null,
      }]
    })

    function addPoints(day: dayjs.Dayjs, index: string) {
      Array.from(ANEMIA_SEVERITIES_BRIEF.entries()).forEach(([i, severity]) => {
        results[severity].push({
          "date": day.format("YYYY-MM-DD"),
          "hb": i ? ANEMIA_AGE_BRACKETS[index][i - 1] : null,
        })
      })
    }

    // Base case will have only two points per line. But check if this isn't the
    // base: does the chart include any of the inflection points?
    // (Requiring intermediate points for each line.)
    const dob = dayjs(this.child.dob)
    if (startAge < 24 && 24 < stopAge) {
      const secondBday = dob.add(2, "year")
      addPoints(secondBday, "6mo-2yr")
      addPoints(secondBday, "2-5")
    }
    if (startAge < 60 && 60 < stopAge) {
      const fifthBday = dob.add(5, "year")
      addPoints(fifthBday, "2-5")
      addPoints(fifthBday, "5-11")
    }
    if (startAge < 12 * 12 && 12 * 12 < stopAge) {
      const twelfthBday = dob.add(12, "year")
      addPoints(twelfthBday, "5-11")
      addPoints(twelfthBday, "12-14")
    }
    if (startAge < 15 * 12 && 15 * 12 < stopAge) {
      const fifteenthBday = dob.add(15, "year")
      addPoints(fifteenthBday, "12-14")
      if (this.child.sex === "female") {
        addPoints(fifteenthBday, "f15+")
      }
      else {
        addPoints(fifteenthBday, "m15+")
      }
    }

    // End with the right-most points for each line
    addPoints(stopDate, this.getAgeBracketIndex(stopAge))
    return results
  }

}
